// Modal.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../services/LocalStorageSrv";
import axios from "axios";

function AddPaymentDetailModal({ isOpen, onClose }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const tokendata = getToken("data");
  const nvg = useNavigate();
  const [loading, setLoading] = useState(true);
  const [paymentModeDetails, setPaymentModeDetails] = useState([]);
  useEffect(() => {
    if (isOpen) {
        fetchPaymentModeList();
    }
  }, [isOpen]);
  const fetchPaymentModeList = async () => {
    try {
      setLoading(true);
      
      // Check if apiUrl and tokendata are defined
      if (!apiUrl || !tokendata || !tokendata.data) {
        console.error('API URL or token data is not defined');
        setLoading(false);
        return;
      }
      
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_mode_of_payment_list`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
        }
      );
  
      if (response.data && response.data.data) {
        console.log('Response Data:', response.data.data);
        setPaymentModeDetails(response.data.data.mode_list);
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {isOpen && (
      <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="relative bg-white rounded-lg w-full max-w-[54rem] p-6 md:p-4">
          <div className='absolute w-[842px] h-[24px] top-[20px] left-[9px] gap-0 flex justify-between'>
            <h5 className='w-[171px] h-[19px] font-Inter font-semibold text-base leading-[19.36px] text-[#1E1E1E]'>Enter Payment Details</h5>
            <button
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
              onClick={onClose} // Close button onClick handler
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <br/>
          <br/>
          
          <div class="grid gap-6 mb-6 md:grid-cols-2">
            <div class="flex items-center">
                <label for="amount" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Total Amount</label>
                <input type="number" id="amount" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your total amount" required />
                </div>
                <div class="flex items-center">
                <label for="discount_type" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Mode of Payment</label>
                <select id="discount_type" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" required>
                    <option value="">Select Mode of Payment</option>
                    {paymentModeDetails.map((sts) => (
                        <option value={sts.mode_of_payment} >
                                {`${sts.mode_of_payment}`}
                              </option>
                            ))}
                </select>
                </div>
                <div class="flex items-center">
                <label for="amount" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Paid Amount</label>
                <input type="number" id="amount" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your paid amount" required />
                </div>
                <div class="flex items-center">
                <label for="amount" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">UPI ID</label>
                <input type="text" id="amount" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your UPI ID" required />
                </div>
          </div>
          <br/>
          <div className="flex justify-end space-x-4">
            
            <button type="submit" className="text-white bg-[#FC8B5C] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 absolute w-[120px] h-[32px] mt-[-27px] rounded-[4px]">Submit</button>
          </div>
        </div>
      </div>
    </div>
     
      )}
    </>
  );
}

export default AddPaymentDetailModal;
