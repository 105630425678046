import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import Layout from '../components/Layout';
import AddMedicationsModal from '../Pages/AddMedicationsModal';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; 
import { FaRegCalendarMinus, FaEllipsisV } from "react-icons/fa"
import { getToken, removeToken, storeToken } from "../services/LocalStorageSrv";
import axios from "axios";
import Loader from "../Loader";
const NewPatientEncounter = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const tokendata = getToken("data");
  const nvg = useNavigate();
  const [loading, setLoading] = useState(true);
	const [activeTab, setActiveTab] = useState('tab1');
  const [symptomsDetails, setSymptomsDetails] = useState([]);
  const [diagnosisDetails, setDiagnosisDetails] = useState([]);
	const handleTabClick = (tab) => {
	  setActiveTab(tab);
	};
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
      setIsOpen(true);
    };
  
    const closeModal = () => {
      setIsOpen(false);
    };
    const fetchSymptomsList = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${apiUrl}/healthcare_management_system.web.api.get_symptom_complaint_list`,
          {
            headers: {
              "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
            },
          }
        );
  
  
        if (response.data && response.data.data) {
          // console.log('doctor:', response.data.data);
          setSymptomsDetails(response.data.data.complaint_list);
        } else {
          console.error('Unexpected response structure:', response);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    const fetchDiagnosisList = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${apiUrl}/healthcare_management_system.web.api.get_diagnosis_list`,
          {
            headers: {
              "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
            },
          }
        );
  
  
        if (response.data && response.data.data) {
          // console.log('doctor:', response.data.data);
          setDiagnosisDetails(response.data.data.diagnosis_list);
        } else {
          console.error('Unexpected response structure:', response);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    useEffect(() => {
      fetchSymptomsList();
      fetchDiagnosisList();
    }, []);
    return (
        <Layout>
            {/* md:max-w-6xl */}
          <div className="md:mx-auto px-4 py-4" style={{backgroundColor: '#F1F5F9',height:'100%'}}>
                <div class="flex items-center justify-between mb-4">
                <h2 class="font-small text-[#8F8F8F]">Patient Encounter List &gt; New Patient Encounter</h2>
              </div>

   <div className="bg-[#fff]">
    <div class="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
         <div className='px-[18px] pt-[15px] bg-[#F8F9FC] pb-[18px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-xl font-medium text-gray-800'>Basic Details</h1>

                {/* <button className='bg-[#823455] h-[32px] rounded-[3px] text-white flex items-center justify-center px-[8px]'>+ Add Patient</button> */}
            </div>
            </div>
            <hr/>
            <div className="px-[18px] pt-[15px] bg-[#F8F9FC] pb-[18px]">

       {/* input around round css like rounded-lg      */}
	   <form>
	<div class="grid gap-6 mb-6 md:grid-cols-2">
	<div class="flex items-center">
        <label for="series" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Series</label>
        <input type="text" id="series" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="HLC-PRAC-.YYYY.-" required disabled/>
    </div>
	<div class="flex items-center">
        <label for="company" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Company</label>
        <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Company" required/>
    </div>
    <div class="flex items-center">
        <label for="appointment" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Appointment</label>
        <select id="appointment" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="appointment" required>
			<option value="">Select Appointment</option>
			<option value="HP202">HP202</option>
		</select>
    </div>
    <div class="flex items-center">
        <label for="encounter_date" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Encounter Date</label>
        <input type="date" id="encounter_date" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your encounter date" required />
    </div>
    <div class="flex items-center">
        <label for="healthcare_practitioner" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Healthcare Practitioner</label>
        <select id="healthcare_practitioner" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="healthcare_practitioner" required>
			<option value="">Select Healthcare Practitioner</option>
			<option value="HP202">HP-RE-202</option>
		</select>
    </div>
    <div class="flex items-center">
        <label for="encounter_time" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Encounter Time</label>
        <input type="time" id="encounter_time" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your encounter time" required />
    </div>
    <div class="flex items-center">
        <label for="first_name" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Patient name</label>
        <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your first name" required />
    </div>
	
	<div class="flex items-center">
        <label for="gender" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Gender</label>
        <select id="gender" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="gender" required>
			<option value="">Select Gender</option>
			<option value="Male">Male</option>
			<option value="Female">Female</option>
			<option value="Other">Other</option>
		</select>
    </div>
	<div class="flex items-center">
        <label for="practitioner_name" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Practitioner Name</label>
        <input type="text" id="practitioner_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your practitioner name" required />
    </div>
    <div class="flex items-center">
        <label for="department" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Department</label>
        <input type="text" id="department" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your department" required />
    </div>



    </div>
	



{/* start tabs */}
{/* <div className="max-w-3xl mx-auto"> */}
<div className="max-w-1xl">
      {/* <div className="flex flex-wrap"> */}
      <div className="flex border-b-2 border-gray-300">
    <a
      className={`py-2 px-0 cursor-pointer focus:outline-none ${activeTab === 'tab1' ? 'border-b-2 border-[#EC642A] text-[#222] font-medium' : 'hover:text-gray-700 text-[#8F8F8F]'}`}
      onClick={() => handleTabClick('tab1')}
    >
     Encounter Impression
    </a>
    
  <a
    className={`py-2 px-10 cursor-pointer focus:outline-none ${activeTab === 'tab2' ? 'border-b-2 border-[#EC642A] text-[#222] font-medium' : 'border-b-2 border-transparent text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab2')}
  >
    Medications
  </a>
  
 
  
</div>

      {/* Render content based on active tab */}
      {activeTab === 'tab1' && (
        <div className="mt-4 p-4 border-gray-300">
         <div class="grid gap-6 mb-6 md:grid-cols-2">
	
         <div class="flex items-center">
        <label for="symptoms" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Procedure Template</label>
        <select id="symptoms" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Symptoms">
			<option value="">Select Symptoms</option>
      {symptomsDetails.map((sts) => (
                        <option value={sts.complaints} >
                                {`${sts.complaints}`}
                              </option>
                            ))}
			
		</select>
        
    </div>
    <div class="flex items-center">
        <label for="diagnosis" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Procedure Template</label>
        <select id="diagnosis" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Diagnosis">
			<option value="">Select Diagnosis</option>
      {diagnosisDetails.map((sts) => (
                        <option value={sts.diagnosis} >
                                {`${sts.diagnosis}`}
                              </option>
                            ))}
			
		</select>
        
    </div>
      
   
	</div>
        </div>
      )}
      {activeTab === 'tab2' && (
         <div className="mt-4 p-4 border-gray-300">
         <div class="grid gap-6 mb-6 md:grid-cols-2">
   
       

<div class="flex flex-col md:flex-row items-center">
   <label for="drug_prescription" class="block text-sm font-medium text-gray-900 dark:text-white md:w-1/5 mb-2 md:mb-0">Drug Prescription</label>
   <a href="javascript:void(0);" class="bg-[#E2E5FB] h-[28px] rounded-[1px] text-[#3C50E0] flex items-center justify-center px-[8px] border-solid border-2 border-[#3C50E0] font-small md:mx-5" onClick={openModal}>+ Add Prescription</a>
  
   </div>
   <AddMedicationsModal isOpen={isOpen} onClose={closeModal} /> {/* Pass onClose function */}
</div>

<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-[30px] mt-[25px] pb-[15px]'>
   <div className='h-[100px] rounded-[8px] bg-white flex items-center justify-between px-[30px] cursor-pointer shadow-lg'>
   <div className="w-9 h-9 rounded-full absolute bg-[#D4CEE4] text-[#270B79] font-bold text-center flex justify-center items-center">01</div>

       <div className='ml-10'>
       
       {/* <div className="flex flex-col">
       <div className="ml-3">
  <h2 className='text-[#222] text-[13px] leading-[17px] font-bold text-left'>
    Medication: <span className='inline-block align-middle font-normal text-[#666666]'>Paracetamol</span>
  </h2>
  <h2 className='text-[#222] text-[13px] leading-[17px] font-bold text-left'>
    Dosage: <span className='inline-block align-middle font-normal text-[#666666]'>0-0-1</span>
  </h2>
  <h2 className='text-[#222] text-[13px] leading-[17px] font-bold text-left'>
    Dosage Form: <span className='inline-block align-middle font-normal text-[#666666]'>Capsule</span>
  </h2>
</div>

</div> */}
<div className="flex ml-3">
  <div className="flex flex-col">
    <h2 className="text-[#222] text-[13px] leading-[17px] font-bold text-left">
    Medication :
    </h2>
    <h2 className="text-[#222] text-[13px] leading-[17px] font-bold text-left">
    Dosage :
    </h2>
    <h2 className="text-[#222] text-[13px] leading-[17px] font-bold text-left">
    Dosage Form :
    </h2>
  </div>
  <div className="flex flex-col ml-2">
    <span className="inline-block text-[12px] align-middle font-normal text-[#666666] text-left">Paracetamol</span>
    <span className="inline-block text-[12px] align-middle font-normal text-[#666666] text-left">0-0-1</span>
    <span className="inline-block text-[12px] align-middle font-normal text-[#666666] text-left">Capsule</span>
  </div>
</div>

       </div>
       <div className="flex">
 <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded mr-2">
   <FaEdit className="inline-block" />
 </button>
 <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded">
   <FaTrash className="inline-block" />
 </button>
</div>

   </div>
   
   <div className='h-[100px] rounded-[8px] bg-white flex items-center justify-between px-[30px] cursor-pointer shadow-lg'>
   <div className="w-9 h-9 rounded-full absolute bg-[#D4CEE4] text-[#270B79] font-bold text-center flex justify-center items-center">02</div>

       <div className='ml-10'>
       
       <div className="flex ml-3">
  <div className="flex flex-col">
    <h2 className="text-[#222] text-[13px] leading-[17px] font-bold text-left">
      Schedule :
    </h2>
    <h2 className="text-[#222] text-[13px] leading-[17px] font-bold text-left">
      Service Unit :
    </h2>
  </div>
  <div className="flex flex-col ml-2">
    <span className="inline-block text-[12px] align-middle font-normal text-[#666666] text-left">Weekand</span>
    <span className="inline-block text-[12px] align-middle font-normal text-[#666666] text-left">Test - TCSV</span>
  </div>
</div>


       </div>
       <div className="flex">
 <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded mr-2">
   <FaEdit className="inline-block" />
 </button>
 <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded">
   <FaTrash className="inline-block" />
 </button>
</div>

   </div>



</div>




       </div>
      )}
      
     
     
    </div>

{/* end tabs */}
<br/>
<div class="flex justify-end space-x-4">
    <button type="submit" class="text-black bg-[#E2E8F0] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel</button>
    <button type="submit" class="text-white bg-[#823455] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
</div>
</form>

</div>


</div>
 </div>        



          </div>
         
        </Layout>
    );
};

export default NewPatientEncounter;