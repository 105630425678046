import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Layout from '../components/Layout';
import AddAppointmentModal from '../Pages/AddAppointmentModal';
import AddIncomeAccountModal from '../Pages/AddIncomeAccountModal';

import { FaPencilAlt, FaTrash } from 'react-icons/fa';

import { getToken, removeToken, storeToken } from "../services/LocalStorageSrv";
import axios from "axios";
import Loader from "../Loader";
import Select from "react-select";
const NewDoctor = ({}) => {
   
	const [activeTab, setActiveTab] = useState('tab1');

	const handleTabClick = (tab) => {
	  setActiveTab(tab);
	};

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const [isOpen1, setIsOpen1] = useState(false);

  const openModal1 = () => {
    setIsOpen1(true);
  };

  const closeModal1 = () => {
    setIsOpen1(false);
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const tokendata = getToken("data");
  // console.log('print key:',tokendata.data.api_key);
  const nvg = useNavigate();
  const [loading, setLoading] = useState(true);
  const [success, setsuccess] = useState(false);
  const [doctorDetails, setDoctorDetails] = useState([]);
  const [totalDoctors, setTotalDoctor] = useState(0);
  const [series, setseries] = useState("");
  const [medicalDepartmentDetails, setMedicalDepartmentDetails] = useState([]);
  const fetchDoctorData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiUrl}/healthcare_management_system.web.api.get_doctor_list`,
        // Data payload
        {
          "start":0,
  "page_length":50
        },

        // Headers object
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
        }
      );
      console.log('list:', response.data.data.doctor_list);
      console.log('total list:', response.data.data.total_doctor);
      setDoctorDetails(response.data.data.doctor_list);
      setTotalDoctor(response.data.data.total_doctor);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchMedicalDepartmentList = async () => {
    try {
      setLoading(true);
      
      // Check if apiUrl and tokendata are defined
      if (!apiUrl || !tokendata || !tokendata.data) {
        console.error('API URL or token data is not defined');
        setLoading(false);
        return;
      }
      
      const response = await axios.post(
        `${apiUrl}/healthcare_management_system.web.api.get_medical_department_list`,
        {}, // Empty body
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
        }
      );
  
      if (response.data && response.data.data) {
        // console.log('Response Data:', response.data.data);
        setMedicalDepartmentDetails(response.data.data.department_list);
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDoctorData();
    fetchMedicalDepartmentList();
  }, []);
  const medicalDepartmentList = medicalDepartmentDetails.map((mdlist) => ({
    value: mdlist.department,
    label: `${mdlist.department}`,
  }));
  const handleChange = (selectedOption) => {
    setseries(selectedOption);
  };

  const doctorOptions = doctorDetails.map((doctor) => ({
    value: doctor.id,
    label: `${doctor.id} - ${doctor.name}`,
  }));

    return (
        <Layout>
          {loading ? <Loader /> : null}
            {/* md:max-w-6xl */}
          <div className="md:mx-auto px-4 py-4" style={{backgroundColor: '#F1F5F9',height:'100%'}}>
                <div class="flex items-center justify-between mb-4">
                <h2 class="font-small text-[#8F8F8F]"><a  href="javascript:void(0);"
                      onClick={() => {
                        nvg("/doctors");
                      }} className="no-underline hover:underline">Doctors List</a> &gt; New Doctor</h2>
              </div>

   <div className="bg-[#fff]">
    <div class="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
         <div className='px-[18px] pt-[15px] bg-[#F8F9FC] pb-[18px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-xl font-medium text-gray-800'>Basic Details</h1>

                {/* <button className='bg-[#823455] h-[32px] rounded-[3px] text-white flex items-center justify-center px-[8px]'>+ Add Patient</button> */}
            </div>
            </div>
            <hr/>
            <div className="px-[18px] pt-[15px] bg-[#F8F9FC] pb-[18px]">

       {/* input around round css like rounded-lg      */}
<form>
	<div class="grid gap-6 mb-6 md:grid-cols-2">
	<div class="flex items-center">
        {/* <label for="series" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Series</label>
         <Select
      value={series}
      onChange={handleChange}
      options={doctorOptions}
      placeholder="Select doctor"
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4"
    /> */}
          <label for="series" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Series</label>
          <input type="text" id="series" class="bg-gray-50 border border-gray-300 text-[#64748B] text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="HLC-PRAC-.YYYY.-" disabled/>       
        {/* <input type="text" id="series" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="HLC-PRAC-.YYYY.-" required disabled/> */}

    </div>
	<div class="flex items-center">
        <label for="status" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Status</label>
        <select id="status" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="status" required>
			{/* <option value="">Select Status</option> */}
			<option value="Active">Active</option>
			<option value="Deactive">Deactive</option>
		</select>
    </div>
    <div class="flex items-center">
        <label for="first_name" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">First name</label>
        <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your first name" required />
    </div>

    <div class="flex items-center">
        <label for="last_name" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Last name</label>
        <input type="text" id="last_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your last name" required />
    </div>
	<div class="flex items-center">
        <label for="mobile" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Mobile</label>
        <input type="number" id="mobile" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your mobile" required />
    </div>
	<div class="flex items-center">
        <label for="gender" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Gender</label>
        <select id="gender" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="gender" required>
			{/* <option value="">Select Gender</option> */}
			<option value="Male">Male</option>
			<option value="Female">Female</option>
			<option value="Other">Other</option>
		</select>
    </div>





    </div>
	



{/* start tabs */}
{/* <div className="max-w-3xl mx-auto"> */}
<div className="max-w-1xl">
      {/* <div className="flex flex-wrap"> */}
      <div className="flex flex-wrap space-x-4 border-b-2 border-transparent border-[#e3e3e3]">
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab1' ? 'border-b-2 border-[#EC642A] text-[#222] font-medium' : 'border-b-2 border-transparent text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab1')}
  >
    Employee and User Details
  </a>
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab2' ? 'border-b-2 border-[#EC642A] text-[#222] font-medium' : 'border-b-2 border-transparent text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab2')}
  >
    Appointments
  </a>
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab3' ? 'border-b-2 border-[#EC642A] text-[#222] font-medium' : 'border-b-2 border-transparent text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab3')}
  >
    Charges
  </a>
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab4' ? 'border-b-2 border-[#EC642A] text-[#222] font-medium' : 'border-b-2 border-transparent text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab4')}
  >
    Account Details
  </a>
  
</div>

      {/* Render content based on active tab */}
      {activeTab === 'tab1' && (
        <div className="mt-4 p-4 border-gray-300">
         <div class="grid gap-6 mb-6 md:grid-cols-2">
	
    
    <div class="flex items-center">
        <label for="practitioner_type" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Practitioner Type</label>
		<select id="practitioner_type" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Practitioner Type" required>
			<option value="">Select Practitioner Type</option>
			<option value="Internal">Internal</option>
      <option value="External">External</option>
		</select>
    </div>
    <div class="flex items-center">
        <label for="user" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">User</label>
        <input type="text" id="user" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your User" required />
    </div>
	{/* <div class="flex items-center">
        <label for="employee" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Employee</label>
        <input type="text" id="employee" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your employee"  />
    </div> */}
	
    <div class="flex items-center">
        <label for="medical_department" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Medical Department</label>
        <Select
      options={medicalDepartmentList}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4"
      required
    />
    </div>
    <div class="flex items-center">
        <label for="hospital" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Hospital</label>
        <input type="text" id="hospital" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your hospital" required />
    </div>
	</div>
        </div>
      )}
      {activeTab === 'tab2' && (
        <div className="mt-4 p-4 border-gray-300">
          <div class="grid gap-6 mb-6 md:grid-cols-2">
	
        

<div class="flex flex-col md:flex-row items-center gap-[10px]">
    <label for="practitioner_schedules" class="font-left text-sm font-medium text-gray-900 dark:text-white">Practitioner Schedules</label>
    {/* <a href="javascript:void(0);" class="bg-[#E2E5FB] h-[28px] font-Mulish text-[#3C50E0] flex items-center justify-center px-[8px] border-solid border-2 border-[#3C50E0] font-small md:mx-5 rounded-[4px]" onClick={openModal}>+ Add Appointment</a> */}
    <a href="javascript:void(0);" class="w-[170px] h-[35px] rounded-[4px] border-[1px] pt-[4px] pb-[6px] px-[10px] bg-[#3C50E026] text-[#3C50E0] font-Mulish border-solid border-[#3C50E0] font-semibold" onClick={openModal}>+ Add Appointment</a>
    </div>
    <AddAppointmentModal isOpen={isOpen} onClose={closeModal} /> {/* Pass onClose function */}
</div>


<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-[30px] mt-[25px] pb-[15px]'>
    <div className='h-[100px] rounded-[8px] bg-white flex items-center justify-between px-[30px] cursor-pointer shadow-lg'>
    <div className="w-9 h-9 rounded-full absolute bg-[#D4CEE4] text-[#270B79] font-bold text-center flex justify-center items-center">01</div>

        <div className='ml-10'>
        
        <div className="flex flex-col">
        <h2 className='text-[#333A48] text-[13px] leading-[17px] font-mulish font-bold text-12px p-1'>
  Schedule : <span className='inline-block align-middle text-[#666666] font-mulish font-semibold text-12px'>Weekly</span>
</h2>
<h2 className='text-[#333A48] text-[13px] leading-[17px] font-mulish font-bold text-12px ml-9 '>
  Service Unit : <span className='inline-block align-middle font-mulish font-semibold text-12px text-[#666666]'>Test - STPL</span>
</h2>

</div>

        </div>
        <div className="flex space-x-1">
      <button className="text-[#EC642A] font-bold rounded p-2">
        <FaPencilAlt className="inline-block" />
      </button>
      <button className="text-[#EC642A] font-bold rounded p-2">
        <FaTrash className="inline-block" />
      </button>
    </div>

    </div>
    
    <div className='h-[100px] rounded-[8px] bg-white flex items-center justify-between px-[30px] cursor-pointer shadow-lg'>
    <div className="w-9 h-9 rounded-full absolute bg-[#D4CEE4] text-[#270B79] font-bold text-center flex justify-center items-center">02</div>

        <div className='ml-10'>
        
        <div className="flex flex-col">
        <h2 className='text-[#333A48] text-[13px] leading-[17px] font-mulish font-bold text-12px p-1'>
  Schedule : <span className='inline-block align-middle text-[#666666] font-mulish font-semibold text-12px'>Weekly</span>
</h2>
<h2 className='text-[#333A48] text-[13px] leading-[17px] font-mulish font-bold text-12px ml-9 '>
  Service Unit : <span className='inline-block align-middle font-mulish font-semibold text-12px text-[#666666]'>Test - STPL</span>
</h2>

</div>

        </div>
        <div className="flex space-x-1">
      <button className="text-[#EC642A] font-bold rounded p-2">
        <FaPencilAlt className="inline-block" />
      </button>
      <button className="text-[#EC642A] font-bold rounded p-2">
        <FaTrash className="inline-block" />
      </button>
    </div>

    </div>



</div>




        </div>
      )}
      {activeTab === 'tab3' && (
        <div className="mt-4 p-4 border-gray-300">
          <div class="grid gap-6 mb-6 md:grid-cols-2">
          <div class="flex items-center">
      <label for="out_potient_c" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Out Patient Consulting Charge Item</label>
      <input type="text" id="out_potient_c" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4 w-[280px]" placeholder="Enter out patient consulting charge item" required />
  </div>
  <div class="flex items-center">
  <label for="inpatient_visit_c" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Inpatient Visit Charge Item</label>
      <input type="text" id="inpatient_visit_c" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block  w-[280px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter inpatient visit charge item" required />
  </div>
  <div class="flex items-center">
  <label for="out_potient" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Out Patient Consulting Charge</label>
      <input type="text" id="out_potient" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block  w-[280px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter out patient consulting charge" required />
  </div>
  <div class="flex items-center">
  <label for="inpatient_visit" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Inpatient Visit Charge</label>
      <input type="text" id="inpatient_visit" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block  w-[280px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter inpatient visit charge" required />
  </div>
  
	</div>
        </div>
      )}
      {activeTab === 'tab4' && (
        <div className="mt-4 p-4 border-gray-300">
          <div class="grid gap-6 mb-6 md:grid-cols-2">
          <div class="flex flex-col md:flex-row items-center gap-[10px]">
    <label for="practitioner_schedules" class="block text-sm font-medium text-gray-900 dark:text-white md:w-1/5 mb-2 md:mb-0">Income Account</label>
    <a href="javascript:void(0);" class="w-[130px] h-[30px] rounded-[4px] border-[1px] pt-[2px] pb-[6px] px-[10px] bg-[#3C50E026] text-[#3C50E0] font-Mulish border-solid border-[#3C50E0] font-semibold" onClick={openModal1}>+ Add Details</a>

   
    </div>
    <AddIncomeAccountModal isOpen={isOpen1} onClose={closeModal1} /> {/* Pass onClose function */}

    </div>

    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-[30px] mt-[25px] pb-[15px]'>
    <div className='h-[100px] rounded-[8px] bg-white flex items-center justify-between px-[30px] cursor-pointer shadow-lg'>
    <div className="w-9 h-9 rounded-full absolute bg-[#D4CEE4] text-[#270B79] font-bold text-center flex justify-center items-center">01</div>

        <div className='ml-10'>
        
       
<div className="flex flex-col">
        <h2 className='text-[#333A48] text-[13px] leading-[17px] font-mulish font-bold text-12px p-1'>
        Company : <span className='inline-block align-middle text-[#666666] font-mulish font-semibold text-12px'>TCS</span>
</h2>
<h2 className='text-[#333A48] text-[13px] leading-[17px] font-mulish font-bold text-12px ml-9 '>
Account : <span className='inline-block align-middle font-mulish font-semibold text-12px text-[#666666]'>Sales - TCS</span>
</h2>

</div>
        </div>
        <div className="flex space-x-1">
      <button className="text-[#EC642A] font-bold rounded p-2">
        <FaPencilAlt className="inline-block" />
      </button>
      <button className="text-[#EC642A] font-bold rounded p-2">
        <FaTrash className="inline-block" />
      </button>
    </div>

    </div>
    
    <div className='h-[100px] rounded-[8px] bg-white flex items-center justify-between px-[30px] cursor-pointer shadow-lg'>
    <div className="w-9 h-9 rounded-full absolute bg-[#D4CEE4] text-[#270B79] font-bold text-center flex justify-center items-center">02</div>

        <div className='ml-10'>
        
       
<div className="flex flex-col">
        <h2 className='text-[#333A48] text-[13px] leading-[17px] font-mulish font-bold text-12px p-1 ml-5'>
        Company : <span className='inline-block align-middle text-[#666666] font-mulish font-semibold text-12px'>Stackerbee Technologies</span>
</h2>
<h2 className='text-[#333A48] text-[13px] leading-[17px] font-mulish font-bold text-12px mr-14 '>
Account : <span className='inline-block align-middle font-mulish font-semibold text-12px text-[#666666]'>Sales - TCSV</span>
</h2>

</div>
        </div>
        <div className="flex space-x-1">
      <button className="text-[#EC642A] font-bold rounded p-2">
        <FaPencilAlt className="inline-block" />
      </button>
      <button className="text-[#EC642A] font-bold rounded p-2">
        <FaTrash className="inline-block" />
      </button>
    </div>

    </div>



</div>
   
	</div>
      )}
     
    </div>

{/* end tabs */}
<br/>
    <div class="flex justify-end space-x-4">
        <button type="submit" class="text-black bg-[#E2E8F0] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel</button>
        <button type="submit" class="text-white bg-[#823455] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
    </div>
</form>

</div>


</div>
 </div>        



          </div>
         
        </Layout>
    );
};

export default NewDoctor;