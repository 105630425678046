// Modal.js

import React from 'react';

function AddMedicationsModal({ isOpen, onClose }) {
  return (
    <>
      {isOpen && (
      <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="relative bg-white rounded-lg w-full max-w-3xl p-6 md:p-10">
          <div className='flex items-center justify-between'>
            <h5 className='text-xl font-medium text-gray-800'>Add Medications</h5>
            <button
              className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
              onClick={onClose} // Close button onClick handler
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <br/>
          <div className='flex items-center justify-between'>
            <div className="flex items-center w-full md:w-1/2">
              <label htmlFor="schedule" className="block text-sm font-medium text-gray-900 dark:text-white w-1/3">Schedule</label>
              <input type="text" id="schedule" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your schedule" required />
            </div>
            <div className="flex items-center w-full md:w-1/2">
              <label htmlFor="service_unit" className="block text-sm font-medium text-gray-900 dark:text-white w-1/3">Service Unit</label>
              <input type="text" id="service_unit" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your service unit" required />
            </div>
          </div>
          <br/>
          <div className="flex justify-end space-x-4">
            <button onClick={onClose} className="text-black bg-[#E2E8F0] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel</button>
            <button type="submit" className="text-white bg-[#FC8B5C] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
          </div>
        </div>
      </div>
    </div>
    
      )}
    </>
  );
}

export default AddMedicationsModal;
