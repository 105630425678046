import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import { getToken, removeToken, storeToken } from "../services/LocalStorageSrv";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { PencilIcon } from "@heroicons/react/outline";
import Loader from "../Loader";
// import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext'; // Make sure to import InputText
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import 'primereact/resources/themes/saga-blue/theme.css';  // Theme
import 'primereact/resources/primereact.min.css';          // Core CSS
import 'primeicons/primeicons.css';
import '../Paginator.css';  // Custom CSS for styling
import '../TableStyles.css'; // Import your custom CSS
import { FaCaretDown } from "react-icons/fa";
const Appointment = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const tokendata = getToken("data");
  // console.log('print key:',tokendata.data.api_key);
  const nvg = useNavigate();
  const [loading, setLoading] = useState(true);
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [totalAppointments, setTotalAppointment] = useState(0);
  const [currentpageno, setcurrentpageno] = useState(1);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [appointments, setAppointments] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  
  const fetchAppointmentData = async (start = 0, pageLength = 10) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiUrl}/healthcare_management_system.web.api.get_appointment_list`,
        {
          "start": start,
          "page_length": pageLength
        },
        // Headers object
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
        }
      );
      // console.log('list:', response.data.data.appointment_list);
      // console.log('total list:', response.data.data.total_appointment);
      setAppointmentDetails(response.data.data.appointment_list);
      setTotalAppointment(response.data.data.total_appointment);
      setAppointments(response.data.data.appointment_list);
      setTotalRecords(response.data.data.total_appointment);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
 
  useEffect(() => {
    fetchAppointmentData();
  }, []);

  const handlePageChange = (e) => {
    setcurrentpageno(e);
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };


  

  

  const [filters, setFilters] = useState({
    sn: { value: null, matchMode: 'contains' },
    patient_details: { value: null, matchMode: 'contains' },
    id: { value: null, matchMode: 'contains' },
    status: { value: null, matchMode: 'contains' },
    date: { value: null, matchMode: 'contains' },
    billing_type: { value: null, matchMode: 'contains' },
    practitioner_name: { value: null, matchMode: 'contains' }
  });

  const onFilter = (e) => {
    setFilters(e.filters);
  };

  
      
  const statusBodyTemplate = (rowData) => {
    return (
      <button
  className={`px-3 py-1 rounded font-mulish font-bold text-[10px] leading-[12.55px] w-auto h-[13px] ${
    rowData.status === 'Closed' ? 'text-[#0F766E]' : 
    rowData.status === 'Open' ? 'text-[#0F766E]' : 
    rowData.status === 'Scheduled' ? 'text-[#C2410C]' : 
    'text-black'
  }`}
>
  {rowData.status}
</button>
    );
}


  // const header = (
  //   <div className="table-header">
  //     <span className="p-input-icon-left">
  //       <i className="pi pi-search" />
  //       <input 
  //         type="search" 
  //         placeholder="Global Search" 
  //         onInput={(e) => setFilters({ ...filters, global: { value: e.target.value, matchMode: 'contains' } })} 
  //       />
  //     </span>
  //   </div>
  // );
 
  const paginator = (
    <div className="datatable-paginator">
      <div className="paginator-left">
        <span>Showing {first + 1} to {Math.min(first + rows, totalRecords)} of {totalRecords} entries</span>
      </div>
      
    </div>
  );
  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    fetchAppointmentData(event.first, event.rows);
  };

  const onPage = (start) => {
    setFirst(start);
  };

  const totalPages = Math.ceil(totalRecords / rows);
  const visiblePages = 5;
  const currentPage = first / rows;
  const startPage = Math.max(0, Math.min(currentPage - Math.floor(visiblePages / 2), totalPages - visiblePages));
  const endPage = Math.min(totalPages, startPage + visiblePages);

  // const paginatorTemplate = (
  //   <div className="pagination">
  //     <Button icon="pi pi-angle-double-left" onClick={() => onPage(0)} disabled={first === 0} />
  //     <Button icon="pi pi-angle-left" onClick={() => onPage(first - rows)} disabled={first === 0} />
  //     {Array.from({ length: endPage - startPage }, (_, i) => startPage + i).map((pageNumber) => (
  //       <Button key={pageNumber} label={String(pageNumber + 1)} onClick={() => onPage(pageNumber * rows)} className={currentPage === pageNumber ? 'p-highlight' : ''} />
  //     ))}
  //     <Button icon="pi pi-angle-right" onClick={() => onPage(first + rows)} disabled={first >= totalRecords - rows} />
  //     <Button icon="pi pi-angle-double-right" onClick={() => onPage((totalPages - 1) * rows)} disabled={first >= totalRecords - rows} />
  //   </div>
  // );
  const serialNumberBodyTemplate = (rowData, column) => {
    return column.rowIndex + 1;
  };
  // const footer = `In total there are ${appointments ? appointments.length : 0} data.`;
  return (
    <Layout>
       {loading ? <Loader /> : null}
      {/* md:max-w-6xl */}
      <div className="md:mx-auto px-4 py-4" style={{ backgroundColor: '#F1F5F9' }}>
        {/* <div class="flex items-center justify-between mb-4">
                <h2 class="text-xl font-bold text-gray-800">Appointments</h2>
              
              
              </div> */}

        <div className="bg-[#fff]">
          <div
            class="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
          >
            <div className='px-[10px] pt-[9px] bg-[#FFFFFF] pb-[6px]'>
              <div className='flex items-center justify-between'>
                <h1 className='w-[55px] h-[23px] font-mulish font-medium text-[18px] leading-[22.59px] tracking-[0.01em] text-[#1C2434]'>Billing</h1>

                <div className='flex items-center space-x-2'>
                  <div className="bg-white rounded">
                    <button
                      id="dropdownDefaultButton"
                      data-dropdown-toggle="dropdown"
                      className="w-[142px] h-[31px] rounded-[4px] justify-between bg-[#F1F1F1] px-[10px] py-[8px] focus:outline-none font-medium  text-sm text-center inline-flex items-center "
                      type="button"
                      onClick={toggleDropdown}
                    >
                      <span className="w-[96px] h-[15px] font-mulish font-semibold text-[12px] leading-[15.06px] text-[#4B5563]">Last Updated On</span>
                      
                      {/* <svg
                        className="w-[15px] h-[15px] ml-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="black"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg> */}
                      <FaCaretDown color="#4B5563"/>
                    </button>

                    {dropdownOpen && (
                      <div
                        id="dropdown"
                        className="z-10 absolute mt-0  bg-white divide-y divide-gray-100 rounded-lg shadow w-[165px] dark:bg-gray-700"
                      >
                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                          <li>
                            <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                              Dashboard
                            </a>
                          </li>
                          <li>
                            <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                              Settings
                            </a>
                          </li>
                          <li>
                            <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                              Earnings
                            </a>
                          </li>
                          <li>
                            <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                              Sign out
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>

                  <div className="bg-white rounded">
                    <a
                      href="javascript:void(0);"
                      onClick={() => {
                        nvg("/new-appointment");
                      }}
                      className=' bg-[#823455]  flex items-center justify-center w-[95px] h-[31px] rounded-[4px] p-[8px] px-[10px] gap-[10px]'
                    >
                     <span class="w-[75px] h-[15px] text-white font-mulish font-semibold text-[12px] leading-[15.06px]">
  + New Billing
</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            
            <DataTable
            // footer={footer}
              value={appointments}
              
              // paginator
             
             
              rows={10}
              totalRecords={totalRecords}
              // lazy
              first={first}
        onPage={onPageChange}
        rowsPerPageOptions={[10, 20, 50]}
              filters={filters}
              onFilter={onFilter}
              filterDisplay="row"
              globalFilterFields={['sn','patient_details', 'id', 'status', 'date', 'billing_type', 'practitioner_name']}
              // header={header}
              className="datatable-custom" // Apply custom class
              emptyMessage="No data found."
              
            >
              <Column field="sn" header="Sl No." sortable filterPlaceholder="Sl No." body={serialNumberBodyTemplate} style={{ minWidth: '6rem' }}></Column>
              <Column
  field="id"
  showFilterMenu={false}
  header="Id"
  sortable
  filter
  filterPlaceholder="Appointment Id"
  style={{ minWidth: '11rem' }}
  body={(rowData) => (
    <a href="javascript:void(0);"  onClick={() => nvg(`/edit-appointment/${rowData.id}`)}className="text-[#1C2434] font-medium hover:underline">
      {rowData.id}
    </a>
  )}
></Column>
      <Column field="patient_details" showFilterMenu={false} header="Patient" sortable filter filterPlaceholder="Patient"  style={{ minWidth: '14rem' }}></Column>
      <Column field="date" showFilterMenu={false} header="Date & Time" sortable filter filterPlaceholder="Date & Time"   style={{ minWidth: '13rem' }}></Column>
      <Column field="practitioner_name" showFilterMenu={false} header="Consultant Name" sortable filter filterPlaceholder="Consultant Name"   style={{ minWidth: '13rem' }}></Column>
      <Column field="billing_type" showFilterMenu={false} header="Billing Type" sortable filter filterPlaceholder="Billing Type"   style={{ minWidth: '10rem' }}></Column>
      <Column field="status" showFilterMenu={false} header="Status" body={statusBodyTemplate} sortable filter filterPlaceholder="Status"   style={{ minWidth: '9rem' }}></Column>
            </DataTable>
            {/* {paginator} */}
            <div className="datatable-footer">
        <span className="paginator-left text-[#270B79]">Showing {first + 1} to {Math.min(first + rows, totalRecords)} of {totalRecords} entries</span>
        <div className="paginator-right">
         {/* {paginatorTemplate} */}
        
         <Paginator
  first={first}
  rows={rows}
  totalRecords={totalRecords}
  onPageChange={onPageChange}
  className="paginator-custom"
  alwaysShow={false} // Optional: hides the paginator when there is only one page
  template={`FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink`}
/>

        </div>
      </div>
            {/* start old datatable */}
            {/* end old datatable */}
          </div>

        </div>
      </div>
    </Layout>
  );
};

export default Appointment;