import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import Layout from '../components/Layout';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; 
import { getToken, removeToken, storeToken } from "../services/LocalStorageSrv";
import axios from "axios";
import { useParams } from 'react-router-dom';
// import Select from "react-select";
import Select, { components } from 'react-select';
import Loader from "../Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddSlotsModal from '../Pages/AddSlotsModal';
import AddPaymentDetailModal from '../Pages/AddPaymentDetailModal';
import { FiPrinter } from "react-icons/fi";
const EditAppointment = () => {
  const location = useLocation();
  const result = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
  const lastId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const apiUrl = process.env.REACT_APP_API_URL;
  const tokendata = getToken("data");
  const nvg = useNavigate();
  const [loading, setLoading] = useState(true);
	const [activeTab, setActiveTab] = useState('tab1');
  
  const { id } = useParams(); 
	const handleTabClick = (tab) => {
	  setActiveTab(tab);
	};
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [isOpen1, setIsOpen1] = useState(false);

  const openModal1 = () => {
    setIsOpen1(true);
  };

  const closeModal1 = () => {
    setIsOpen1(false);
  };
  const [doctorDetails, setDoctorDetails] = useState([]);
  const [patientDetails, setPatientDetails] = useState([]);
  const [appointmentType, setAppointmentType] = useState([]);
  const [clinicalProcedureDetails, setClinicalProcedureDetails] = useState([]);
  const [therapyPlanDetails, setTherapyPlanDetails] = useState([]);
  const [doctor, setdoctor] = useState("");
  const [patient, setpatient] = useState("");
  const [appointment, setappointment] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [selectedDoctorDetail, setSelectedDoctorDetail] = useState({ name: '', department: '' }); 
  const [selectedPatient, setSelectedPatient] = useState('');
  const [selectedPatientDetail, setSelectedPatientDetail] = useState({ sex: '', age: '',patient_id: '',mobile:'',email:'',name:'' }); 
  const [selectedAppointment, setSelectedAppointment] = useState('');
  const [selectedAppointmentDetail, setSelectedAppointmentDetail] = useState('0'); 
  const [patiendIds,setPatiendIds] = useState('');
  const [paymentModeDetails, setPaymentModeDetails] = useState([]);
  const [medicalDepartmentDetails, setMedicalDepartmentDetails] = useState([]);

  const [selectedValue, setSelectedValue] = useState('');
  const [selectedValue1, setSelectedValue1] = useState('');
  const [selectedValue2, setSelectedValue2] = useState('');
  const [selectedValue3, setSelectedValue3] = useState('');
  const [selectedValue4, setSelectedValue4] = useState('');
  const [selectedValue5, setSelectedValue5] = useState('');
  const [selectedValue6, setSelectedValue6] = useState('');

  const [selectedValue7, setSelectedValue7] = useState('');
  const [selectedValue8, setSelectedValue8] = useState('');
  const [selectedValue9, setSelectedValue9] = useState('');
  // const handleChangeDoctor = (selectedOption) => {
  //   // const doctorId = selectedOption.target.value;
  //   console.log('d id:',selectedOption.target.value);
  //   setdoctor(selectedOption);
  // };
// for slote modal

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [departmentModalValue, setDepartmentModalValue] = useState('');
  const [doctorModalValue, setDoctorModalValue] = useState('');
  const handleOpenPayNow = (e) => {
    setIsModalOpen(true); // Open modal when a value is selected
  };

  const closeModalPop = () => {
    setIsModalOpen(false);
  };
 
  const [selectedTime, setSelectedTime] = useState(null);
  const [dsetDatewiseTimeSlotPractitionerName, setDatewiseTimeSlotPractitionerName] = useState('');
  const [dsetDatewiseTimeSlotScheduleName, setDatewiseTimeSlotScheduleName] = useState('');
  const [datewiseTimeSlotDetails, setDatewiseTimeSlotDetails] = useState([]);
  const [datewiseTimeSlotstatusDetails, setDatewiseTimeSlotstatusDetails] = useState([]);
  const [appointmentDate, setAppointmentDate] = useState('');
  const [modeofPayment, setModeofPayment] = useState('');
  
  
  const handleChangeWithTimeSlote = (app_date) => {
    // alert(app_date);
    fetchDatewiseTimeSlotData(app_date);

  };
  const fetchDatewiseTimeSlotData = async (appointmentDate) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.practitioner_schedule`,
        {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            practitioner_id: doctorModalValue ? doctorModalValue : '',
            slot_date:appointmentDate ? appointmentDate : ''
          }
        }
      );


      if (response.data && response.data.data) {
        // console.log('all disable time slote:', response.data.data.slot_details[0].appointments);
        setDatewiseTimeSlotPractitionerName(response.data.data.practitioner_name);
        setDatewiseTimeSlotScheduleName(response.data.data.schedules[0].schedule_name);
        setDatewiseTimeSlotDetails(response.data.data.schedules[0].time_slots);
        setDatewiseTimeSlotstatusDetails(response.data.data.slot_details[0].appointments);
        
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  //const times = ['09:00 AM', '09:30 AM', '10:00 AM'];
  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <span className="text-sm font-mulish">{props.data.label}</span>
      </components.Option>
    );
  };
  
  const NewDepartmentButton = () => (
    <button
      className="text-sm text-blue-500 hover:underline mt-2 p-2 w-full text-left"
      onClick={() => alert('Add new department logic here')}
    >
      + New Department
    </button>
  );
  
  const CustomMenuList = (props) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        <NewDepartmentButton />
      </components.MenuList>
    );
  };
// for end slote modal
  const handleChangeDoctor = (selectedOption) => {
    const doctorId = selectedOption.value;
    // console.log('Selected Doctor ID:', doctorId);
    setdoctor(selectedOption);
    setSelectedDoctor(doctorId);
    // fetchSelectedDoctorDetails(doctorId);
    fetchDifferChargesDoctor(selectedOption);

  };
  
  const doctorOptions = doctorDetails.map((doctor) => ({
    value: doctor.id,
    label: `${doctor.id} - ${doctor.name}`,
  }));
  const medicalDepartmentList = medicalDepartmentDetails.map((mdlist) => ({
    value: mdlist.department,
    label: `${mdlist.department}`,
  }));
  // const handleChangePatient = (selectedOption) => {
  //   setpatient(selectedOption);
  // };
  const handleChangePatient = (selectedOption) => {
    const patientId = selectedOption.value;
    setpatient(selectedOption);
    setSelectedPatient(patientId);
    // fetchSelectedPatientDetails(patientId);

  };
  const patientOptions = patientDetails.map((patient) => ({
    value: patient.patient_id,
    label: `${patient.patient_id ? patient.patient_id : '' } - ${patient.name ? patient.name : ''}`,
  }));
  // -${patient.mobile ? patient.mobile : ''}
  const handleChangeDuration = (selectedOption) => {
    const appointmentId = selectedOption.target.value;
    if(appointmentId === ''){
      setappointment('');
      setSelectedAppointment('');
      fetchSelectedAppointmentDetails('');
    }else{
    setappointment(selectedOption);
    setSelectedAppointment(appointmentId);
    // console.log('apoint id:',appointmentId);  
    fetchSelectedAppointmentDetails(appointmentId);
    }

  };

  const [billingtypeValue, setbillingtypeValue] = useState("Appointment");
  const [selectedBillingType, setSelectedBillingType] = useState("");
  const handleChangeBillingType = (selectedOption) => {
    const billingId = selectedOption.target.value;
    if(billingId !== '')
    {
      // console.log('biling typ:',billingId);
      setbillingtypeValue(billingId);
    }else{
      setbillingtypeValue('');
    }

  };
  
  
  // const fetchAppointmentDoctor = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(
  //       `${apiUrl}/healthcare_management_system.web.api.get_doctor_list_on_appointment`,
  //       {
  //         headers: {
  //           "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
  //         },
  //         params: {
  //           Practitioner_id: '', // If needed, use params instead of a second object
  //         }
  //       }
  //     );


  //     if (response.data && response.data.data) {
  //       // console.log('doctor:', response.data.data);
  //       setDoctorDetails(response.data.data);
  //     } else {
  //       console.error('Unexpected response structure:', response);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setLoading(false);
  //   }
  // };
  // const fetchPatientData = async () => {
  //   try {
  //     setLoading(true);

  //     const response = await axios.get(
  //       `${apiUrl}/healthcare_management_system.web.api.get_patient_list_Appontment`,
  //       {
  //         headers: {
  //           "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
  //         },
  //         params: {
  //           patient_id: '', // If needed, use params instead of a second object
  //         }
  //       }
  //     );

  //     if (response.data && response.data.data) {
  //       // console.log('patient:',response.data.data);
  //       setPatientDetails(response.data.data);
  //     } else {
  //       console.error('Unexpected response structure:', response);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setLoading(false);
  //   }
  // };
  const fetchAppointmentType = async () => {
    try {
      setLoading(true);
  
      const response = await axios.get(`${apiUrl}/healthcare_management_system.web.api.get_appointment_type_list`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${tokendata.data.api_key.trim()}:${tokendata.data.api_secret.trim()}`,
          },
          params: {
            appointment_id: '', // If needed, use params instead of a second object
          }
        }

      );
  
      // console.log('API response:', response);
  
      if (response.data && response.data.data) {
        // console.log('Appointment type data:', response.data.data.app_type_list);
        setAppointmentType(response.data.data.app_type_list);
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error("Error fetching data:", error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchSelectedDoctorDetails = async (doctorId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_doctor_list_on_appointment`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            Practitioner_id: doctorId,
          }
        }
      );
  
  
      if (response.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
        const doctorDetails = response.data.data[0];
        // console.log('Doctor Details:', doctorDetails);
        setSelectedDoctorDetail({
          name: doctorDetails.name,
          department: doctorDetails.department,
        });
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // const fetchSelectedPatientDetails = async (patientId) => {
  //   try {
  //     const response = await axios.get(
  //       `${apiUrl}/healthcare_management_system.web.api.get_patient_list_Appontment`,
  //       {
  //         headers: {
  //           "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
  //         },
  //         params: {
  //           patient_id: patientId,
  //         }
  //       }
  //     );
  
  
  //     if (response.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
  //       const patientDetails = response.data.data[0];
  //       // console.log('Patient Details:', patientDetails);
  //       setSelectedPatientDetail({
  //         sex: patientDetails.sex,
  //         age: patientDetails.age,
  //         patient_id:patientDetails.patient_id,
  //         mobile:patientDetails.mobile,
  //         email:patientDetails.email,
  //         name:patientDetails.name,
  //       });
  //       setPatiendIds(patientDetails.patient_id);
        
  //       fetchTherapyPlan(patientDetails.patient_id);
  //     } else {
  //       console.error('Unexpected response structure:', response);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const fetchSelectedAppointmentDetails = async (appointmentId) => {
    // console.log('appointId:', appointmentId);
    try {
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_appointment_type_list`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            appointment_id: appointmentId,
          }
        }
      );
  
  
      if (
        response.data &&
        response.data.data &&
        response.data.data.app_type_list &&
        response.data.data.app_type_list.length > 0
      ) {
        // console.log('duration n:', response.data.data);
        const appointmentDetails = response.data.data.app_type_list[0]; // Access the first item in the array
        const defaultDuration = appointmentDetails.default_duration; // Extract default_duration
        // console.log('duration:', defaultDuration);
        setSelectedAppointmentDetail(defaultDuration);
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchPaymentModeData = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_mode_of_payment_list`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
        }
      );

      if (response.data && response.data.data) {
        // console.log('payment mode:',response.data.data.mode_list);
        setPaymentModeDetails(response.data.data.mode_list);
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  
  // const fetchClinicalProcedure = async () => {
  //   // console.log('text:',selectedPatientDetail.patient_id);
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(
  //       `${apiUrl}/healthcare_management_system.web.api.get_clinical_procedure_temp_list`,
  //       {
  //         headers: {
  //           "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
  //         },
  //         params: {
  //           patient_id: patiendIds
  //           , // If needed, use params instead of a second object
  //         }
  //       }
  //     );


  //     if (response.data && response.data.data) {
  //       // console.log('Clinical Procedure:', response.data.data.procedure_template_list);
  //       // console.log('Therapy Plan:', response.data.data.patient);
  //       setClinicalProcedureDetails(response.data.data.procedure_template_list);
  //       setTherapyPlanDetails(response.data.data.therapy_plan);
  //     } else {
  //       console.error('Unexpected response structure:', response);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setLoading(false);
  //   }
  // };

  // const fetchTherapyPlan = async (patiendIds) => {
  //   // console.log('text:',selectedPatientDetail.patient_id);
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(
  //       `${apiUrl}/healthcare_management_system.web.api.get_clinical_procedure_temp_list`,
  //       {
  //         headers: {
  //           "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
  //         },
  //         params: {
  //           patient_id: patiendIds
  //           , // If needed, use params instead of a second object
  //         }
  //       }
  //     );


  //     if (response.data && response.data.data) {
  //       console.log('Therapy Plan:', response.data.data.patient);
  //       setTherapyPlanDetails(response.data.data.therapy_plan);
  //     } else {
  //       console.error('Unexpected response structure:', response);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setLoading(false);
  //   }
  // };
  const fetchMedicalDepartmentList = async () => {
    try {
      setLoading(true);
      
      // Check if apiUrl and tokendata are defined
      if (!apiUrl || !tokendata || !tokendata.data) {
        console.error('API URL or token data is not defined');
        setLoading(false);
        return;
      }
      
      const response = await axios.post(
        `${apiUrl}/healthcare_management_system.web.api.get_medical_department_list`,
        {}, // Empty body
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
        }
      );
  
      if (response.data && response.data.data) {
        // console.log('Response Data:', response.data.data);
        setMedicalDepartmentDetails(response.data.data.department_list);
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }




    
  };



const [patientinfo,setpatientinfo] = useState([]);
const [patientaddressinfo,setpatientAddressinfo] = useState([]);
// console.log("ddddd",patientinfo)
  const fetchProduct = async () => {
    try {
      const response = await axios.post(
      `${apiUrl}/healthcare_management_system.web.api.edit_appointment`,
      {"appointment_id":id}, // Empty body
      {
        headers: {
          "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
        },
      }
    );
    if (response.data.message = "Appointment list fetched successfully") {
    // console.log("ajay api is here",response.data.data.Patient_address[0])
    // console.log("patient add:",response.data.data.patient_address);
    setpatientinfo(response.data.data.appointment_list)
    setpatientAddressinfo(response.data.data.patient_address)

    setSelectedValue1(response.data.data.appointment_list?.[0]?.department);
    handleChangeDoctorByDepartment(response.data.data.appointment_list?.[0]?.department)
    setSelectedValue2(response.data.data.appointment_list?.[0]?.practitioner)
    handleChangeDoctor(response.data.data.appointment_list?.[0]?.practitioner)
      // setProduct(response.data); // Store the product data in state
    }
      setLoading(false); // Set loading to false once data is fetched
    } catch (err) {
      // setError(err.message); // Handle any errors
      setLoading(false);
    }
  };
 
    
  useEffect(() => {
    // fetchAppointmentDoctor();
    // fetchPatientData();
    fetchAppointmentType();
    // fetchSelectedDoctorDetails();
    // fetchSelectedPatientDetails();
    // fetchSelectedAppointmentDetails();
    // fetchClinicalProcedure();
    // fetchTherapyPlan(patiendIds);
    fetchPaymentModeData();
    fetchMedicalDepartmentList();
    fetchProduct();
  }, [id]);
  const customStylesD = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      borderRadius: '4px',
      borderColor: '#656565',
      padding: '2.5px',
      width: '100%',
      fontSize: '0.875rem', // text-sm
      textAlign:'left'
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.data.color ? state.data.color : state.isSelected ? '#2B2F32' : '#2B2F32',
      backgroundColor: state.isSelected ? '#F0F0F0' : '#FFFFFF',
      textAlign: 'left', // Align text to the left
      '&:hover': {
        backgroundColor: '#f0f0f0', // Light gray hover color
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      textAlign: 'left', // Align placeholder to the left
    }),
  };
  
  const options = [
    ...medicalDepartmentDetails.map((sts) => ({
      value: sts.department,
      label: sts.department,
    })),
    { value: 'create-new-department', label: '+ Create Department', color: '#EC642A' },
  ];
  const [doctorDetailsByDepartment, setDoctorDetailsByDepartment] = useState([]);
  const [differChargesDetails, setDifferChargesDetails] = useState([]);
  // const [errordoctorname, seterrordoctorname] = useState("");
  const handleChangeDoctorByDepartment = (e) => {
    // alert(selectedValue1);
    // console.log('d:ccc',e);
    // setDepartmentValueForDoctor(selectedValue1);
    if(e != "")
    {
      // console.log("step 1",e)
      setSelectedValue2("");
      // console.log("step 1",e)
      fetchAppointmentDoctorByDepartment(e);
    }

  };
  const fetchAppointmentDoctorByDepartment = async (selectedValue1) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_doctor_list_on_appointment`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            Practitioner_id: '', // If needed, use params instead of a second object
            department: selectedValue1 ? selectedValue1 : ''
          }
        }
      );


      if (response.data && response.data.data) {
        // console.log('doctor by department:', response.data.data);
        setDoctorDetailsByDepartment(response.data.data);
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const fetchDifferChargesDoctor = async (selectedValue2) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_differ_charges_list`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            parent: selectedValue2 ? selectedValue2 : '',
            practitioner_time:''
          }
        }
      );


      if (response.data && response.data.data) {
        // console.log('Differ Charges:', response.data.data.differ_charge);
        setDifferChargesDetails(response.data.data.differ_charge);
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const [differChargesValuePrice, setdifferChargesValuePrice] = useState('');
  const [differChargesPrice, setDifferChargesPrice] = useState('');
  const handleDifferCharges = (selectv) => {
    // console.log('Selected Differ Charges:', selectv);
    if(selectv === '')
    {
      setdifferChargesValuePrice('');
      fetchDifferChargesDoctorPrice('');
    }else{
      setdifferChargesValuePrice(selectv);
      fetchDifferChargesDoctorPrice(selectv);
    }
  };
  const fetchDifferChargesDoctorPrice = async (differChargesValuePrice) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_differ_charges_list`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            parent: selectedValue2 ? selectedValue2 : '',
            practitioner_time: differChargesValuePrice ? differChargesValuePrice : ''
          }
        }
      );


      if (response.data && response.data.data) {
        // console.log('Differ Charges price:', response.data.data.differ_charge[0].charges);
        setDifferChargesPrice(response.data.data.differ_charge[0].charges);


      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const customStyles1 = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      borderRadius: '4px',
      borderColor: '#656565',
      height: '43px',
      fontSize: '0.875rem', // text-sm
      textAlign: 'left',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.data.color ? state.data.color : state.isSelected ? '#2B2F32' : '#2B2F32',
      backgroundColor: state.isSelected ? '#F0F0F0' : '#FFFFFF',
      textAlign: 'left', // Align text to the left
      '&:hover': {
        backgroundColor: '#f0f0f0', // Light gray hover color
      },
    }),
  };

  const options1 = [
    ...doctorDetailsByDepartment.map((sts) => ({
      value: sts.id,
      label: `${sts.id}    ${sts.name}`,
    })),
    { value: 'create-new-doctor', label: '+ Create New Doctor', color: '#EC642A' },
  ];


// start pay now payment
const [payNowMsg, setPayNowMsg] = useState('');
const [paymentmodevalue, setpaymentmodevalue] = useState('');
const [paymentamount, setpaymentamount] = useState('');
const [paymentdate, setpaymentdate] = useState('');
const [errorpaymentmodevalue, seterrorpaymentmodevalue] = useState('');
const [errorpaymentamount, seterrorpaymentamount] = useState('');
const [errorpaymentdate, seterrorpaymentdate] = useState('');
const handlePayNowSave = async () => {
  // alert(lastId) 
  // alert(paymentmodevalue)
  // alert(paymentamount)
  // alert(paymentdate)
  if (
    // bookdate !== "" &&
    paymentmodevalue !== "" &&
    paymentamount !== "" &&
    paymentdate !== ""

  ) {
    try {
      setLoading(true);
      
      const formdata = {
        appointment_id: lastId,
        mode_of_payment: paymentmodevalue,
        amount: paymentamount,
      
      };

      const response = await axios.post(
        `${apiUrl}/healthcare_management_system.web.api.payment_update`,
        formdata,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
        }
      );

      if (response.data.message === "Payment updated successfully for Patient Appointment") {
        setPayNowMsg(response.data.data);
        toast.success("Your Payment is added Successfully!", {
          position: "top-right",
          autoClose: 3000,
        });
      
        setTimeout(() => {
          window.location.reload(); // Refresh the page after success
        }, 2000);
      
      } else {
        alert('Something is wrong, please try again.');
        setTimeout(() => {
          nvg(`/edit-appointment/${lastId}`); // Navigate after error
        }, 2000);
      }
      

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  } else {

    if (paymentmodevalue == "") {
      seterrorpaymentmodevalue("Payment Mode is Required");
    } else {
      seterrorpaymentmodevalue("");
    }
    if (paymentamount == "") {
      seterrorpaymentamount("Amount is Required");
    } else {
      seterrorpaymentamount("");
    }
    if (paymentdate == "") {
      seterrorpaymentdate("Payment Date is Required");
    } else {
      seterrorpaymentdate("");
    }
    
   

  }
};

// end pay now payment

// start save button code
// const booknowsubmit = async () => {
//   // alert(referredByValue);
//   if (
//     // bookdate !== "" &&
//     selectedValue6 !== "" &&
//     selectedValue2 !== "" 

//   ) {
//     try {
//       setLoading(true);
//       const formdata = {
//         appointment_id:'',
//           first_name: pname,
//           sex: selectedGender,
//           dob: dob,
//           blood_group: selectedBloodGroup,
//           mobile: mobilenumber,
//           address_line1: street,
//           address_line2: '',
//           city: city,
//           county: country,
//           state: state,
//           pincode: pincode,
//           mobile: mobilenumber,
//           appointment_type:selectedValue6,
//           company: 'Stackerbee',
//           appointment_date: '',
//           appointment_for:"Practitioner",
//           practitioner:selectedValue2,
//           patient:'',
//           duration: selectedAppointmentDetail ? selectedAppointmentDetail : 0,
//           status:"Open",
//           custom_discount_type:selectedValue5,
//           custom_discount_amount: selectedValue5 === "Fixed" ? (discountPriceEnter ? discountPriceEnter : '0') : '0',
// custom_discount_percentage: selectedValue5 !== "Fixed" ? (discountPriceEnter ? discountPriceEnter : '0') : '0',
//           mode_of_payment: "Cash",
//           paid_amount: amountPaidValue ? amountPaidValue : '0',
//           custom_amount_pending:totalPendingAmountAuto ? totalPendingAmountAuto : '0',
//           invoiced: 0,
//           custom_reffered:referredByValue,
//           notes:'',
//           custom_total_amount:totalAmountAuto,
//           custom_actual_amount:differChargesPrice,
//           custom_differ_charges:selectedValue3,  //Evening
//           appointment_time:"",
//           service_unit:'',
//           book_now:1
        
//       };
//       const response = await axios.post(
//         `${apiUrl}/healthcare_management_system.web.api.create_patient_appointment`,formdata,
//         {
//           headers: {
//             "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
//           },
//         }
//       );
  
//       if (response.data.message = "Patient Appointment created successfully") {
//         toast.success("Patient Appointment details updated Successfully!", {
//           position: "top-right",
//           autoClose: 3000,
//         });
      
//         setTimeout(() => {
//           window.location.reload(); // Refresh the page after success
//         }, 2000);
//       }
//       setLoading(false);
     
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//     }
//   } else {

//     if (selectedValue6 == "") {
//       seterrorappointmenttype("Appointment Type is Required");
//     } else {
//       seterrorappointmenttype("");
//     }
//     if (selectedValue2 == "") {
//       seterrordoctorname(" Consultant Name is Required");
//     } else {
//       seterrordoctorname("");
//     }
//     if (bookdate == "") {
//       seterrorbookdate("Date is Required");
//     } else {
//       seterrorbookdate("");
//     }
//     if (pname == "") {
//       seterrorpname("Patient is Required");
//     } else {
//       seterrorpname("");
//     }
//     if (dob == "") {
//       seterrordob("dob is Required");
//     } else {
//       seterrordob("");
//     }
//     if (selectedGender == "") {
//       seterrorgender("Gender is Required");
//     } else {
//       seterrorgender("");
//     }
   

//   }

// };
// end save button
    return (
        <Layout>
           {loading ? <Loader /> : null}
           <ToastContainer />
            {/* md:max-w-6xl */}
  <div className="md:mx-auto px-4 py-4" style={{backgroundColor: '#F1F5F9',height:'100%'}} >
    <div className="flex items-center justify-between mb-7">
                    

<div className="absolute w-[205px] h-[20px] text-[#8F8F8F] font-inter font-normal text-[12px] leading-[14.52px] ml-[-8px]">
  {lastId} &gt; Edit Billing
</div>
    </div>

<div className="border-[1px] border-[#E2E8F0] bg-[#FFFFFF]">

<div className="w-[977px] h-[44px] mt-[20px] ml-[20px] flex gap-[10px]">
  <div className="w-[319px] h-[44px] rounded-[12px] py-[17px] px-[30px] gap-[10px] bg-[#075985] text-white flex items-center justify-center">
      <span className="text-[20px] text-center flex-shrink-0"><FiPrinter /></span>
      <button className="w-[119px] h-[24px] font-inter font-medium text-[20px] leading-[24.2px] text-center text-white">
        Print Invoice
      </button>
  </div>


  <div className="w-[319px] h-[44px] rounded-[12px] py-[17px] px-[30px] gap-[10px] bg-[#075985] text-white flex items-center justify-center">
    <span className="text-[20px] text-center flex-shrink-0"><FiPrinter /></span>
    <button className="w-[212px] h-[24px] font-inter font-medium text-[20px] leading-[24.2px] text-center text-white">
      Print Payment Receipt
    </button>
  </div>


  <div className="w-[319px] h-[44px] rounded-[12px] py-[17px] px-[30px] gap-[10px] bg-[#075985] text-white flex items-center justify-center">
    <span className="text-[20px] text-center flex-shrink-0"><FiPrinter /></span>
    <button className="w-[215px] h-[24px] font-inter font-medium text-[20px] leading-[24.2px] text-center text-white">
      Print Appointment Slip
    </button>
  </div>
</div>


 <div className="px-[18px] pt-[15px] bg-[#FFFFFF] pb-[1px] border-[1px] border-[#FFFFFF] rounded-[2px] shadow-[0px_8px_13px_-3px_rgba(0,0,0,0.07)]">
	 
	<div className="grid gap-2 mb-6 md:grid-cols-3">
    {/* <div>
          <label for="series" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Date</label>
          <input type="date" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Date" defaultValue={patientinfo?.[0]?.date} disabled/>
    </div> */}
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Patient Name</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Patient Name" value={`${patientinfo?.[0]?.patient} - ${patientinfo?.[0]?.patient_name}`} disabled />
    </div>
    <div>
        <label for="appointment_type" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Patient UHID</label>
        <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Patient UHID" defaultValue={patientinfo?.[0]?.uid} disabled />
    </div>

    <div>
          <label for="series" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Date of Birth</label>
          <input type="Date" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Date of Birth" defaultValue={patientinfo?.[0]?.dob}  disabled />
    </div>
    <div>
        <label for="appointment_type" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Age</label>
        <input type="number" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Age" defaultValue={patientinfo?.[0]?.age} disabled />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Gender</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Gender" defaultValue={patientinfo?.[0]?.sex}  disabled/>
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Blood Group</label>
          
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Blood Group" defaultValue={patientinfo?.[0]?.blood_group}  disabled/>
    </div>
    <div>
          <label for="series" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Street</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Street"  defaultValue={patientaddressinfo?.[0]?.address_line1}  />
    </div>
    <div>
        <label for="appointment_type" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">City</label>
        <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="City"   defaultValue={patientaddressinfo?.[0]?.city} />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">State</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="State"   defaultValue={patientaddressinfo?.[0]?.state} />
    </div>

    <div>
          <label for="series" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Pin code</label>
          <input type="number" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Pincode"     defaultValue={patientaddressinfo?.[0]?.pincode} />
    </div>
    <div>
        <label for="appointment_type" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Country</label>
        <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Country"   defaultValue={patientaddressinfo?.[0]?.country}  />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Phone number</label>
          <input type="number" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Phone number" defaultValue={patientinfo?.[0]?.mobile} />
    </div>

    <div>
          <label for="series" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Aadhar Card</label>
          <input type="number" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Aadhar Card"  />
    </div>
    <div>
        <label for="appointment_type" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Care Of</label>
        <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Care Of"  />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Relation</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Relation"  />
    </div>

    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Billing Type</label>
          <select
      value={selectedValue}
      onChange={(e) => {
        const value = e.target.value;
        setSelectedValue(value);
        handleChangeBillingType(e); // Pass the event object if needed
      }}
      
      className={`bg-[#FFFFFF] rounded-[4px] border border-[#656565] text-sm block w-full p-2.5 
        ${selectedValue ? 'text-black border-[#656565]' : 'text-[#656565] border-[#656565]'} `}
    >
            {/* <option value="">Select Billing Type</option> */}
            <option value="Appointment" className="text-[#2B2F32]">Appointment</option>
            <option value="Procedure" className="text-[#2B2F32]">Procedure</option>
            <option value="Laboratory" className="text-[#2B2F32]">Laboratory</option>
            <option value="Radiology" className="text-[#2B2F32]">Radiology</option>
           

          </select>
    </div>



   
    
    
   

    </div>
</div>

<hr/>
{billingtypeValue === 'Appointment' ? (
  
<div className="px-[18px] pt-[8px] bg-[#FFFFFF] pb-[2px]">
  <div className="grid gap-2 mb-6 md:grid-cols-3">
  <div>
          <label for="series" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Appointment Id</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Appointment Id" value={patientinfo?.[0]?.id}  disabled />
    </div>
    <div>
        <label for="appointment_type" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Appointment Date</label>
        <input type="date" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Appointment Date" value={patientinfo?.[0]?.Appointment_Date}
                   />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Appointment Time</label>
          <input type="text" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Appointment Time"  value={patientinfo?.[0]?.Appointment_Time}
                  />
    </div>
      <div>
      <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">
          Department
        </label>
     {/* {console.log('optins:',selectedValue1)} */}
        <Select
    //  defaultValue={selectedValue1 == patientinfo?.[0]?.department ? [{value: patientinfo?.[0]?.department, label: patientinfo?.[0]?.department}] : selectedValue1}
    // defaultValue={selectedValue1}
     value={options.find(option => option.value === selectedValue1)}
     onChange={(selectedOption) => {
      // console.log('chng:',selectedOption);
       const selectedValue = selectedOption.value;
       setSelectedValue1(selectedValue);
       handleChangeDoctorByDepartment(selectedValue);
       if (selectedValue === "create-new-department") {
         nvg("/patient");
       }
     }}
     options={options}
     placeholder="Select Department"
     styles={customStylesD}
     isSearchable
   />
        {/* <select
      value={selectedValue1}
      onChange={(e) => {
        setSelectedValue1(e.target.value);
        if (e.target.value === "create-new-department") {
          nvg("/patient");
        }
      }}
      className={`bg-[#FFFFFF] rounded-[4px] border border-[#656565] text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
        ${selectedValue1 ? 'text-black' : 'text-[#ABAFB1]'} 
        dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
    > */}
       
  {/* <option value="">Select Department</option> */}
  {/* {medicalDepartmentDetails.map((sts) => (  patientinfo?.[0]?.department == sts.department ? <option  className="text-[#2B2F32]" value={sts.department} selected key={sts.department}>
      {sts.department}
    </option> : ''
  ))}
  {medicalDepartmentDetails.map((sts) => (  patientinfo?.[0]?.department == sts.department ? '' : <option  className="text-[#2B2F32]" value={sts.department} key={sts.department}>
      {sts.department}
    </option>
  ))}
  <option
    value="create-new-department"
    className="text-[#EC642A] text-[12px] p-[20px] g-[10px] font-inter text-xs font-normal leading-extra-tight"
   
  >
    + Create Department 
  </option>
</select> */}


    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Consultant Name</label>
         {/* {console.log('c optin:',options1)} */}
          <Select
  value={options1.find(option => option.value === selectedValue2)}
  onChange={(selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : '';  // Check if option exists
    setSelectedValue2(selectedValue);
    // {console.log('c name:',selectedValue)}
    handleChangeDoctor(selectedValue);

    // Clear validation error if value is selected
    // if (selectedValue !== '') {
    //   seterrordoctorname("");
    // }

    // Navigate if the "create-new-doctor" is selected
    if (selectedValue === "create-new-doctor") {
      nvg("/new-doctor");
    }
  }}
  options={options1}
  placeholder="Select First Department"
  styles={customStyles1}
  isSearchable
  className={`
    ${selectedValue2 ? 'text-black' : 'text-[#ABAFB1]'} 
    w-full
  `}
 
  // style={{
  //   border: errordoctorname ? '1px solid red' : '1px solid #656565',  // Apply red border if error
  // }} 
/>
          
    </div>
   
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Differ Charges</label>
          <select
          // value={options1.find(option => option.value === selectedValue2)}
      // value={patientinfo?.[0]?.custom_differ_charges}
      onChange={(e) => {setSelectedValue3(e.target.value);
        handleDifferCharges(e.target.value);
      }}
      // ${selectedValue3 ? 'text-black' : 'text-[#ABAFB1]'} 
      className={`bg-[#FFFFFF] rounded-[4px] border border-[#656565] text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
        
        dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
    >
            {/* <option value="">Select Differ Charges</option> */}
            {/* {console.log('difers:',patientinfo?.[0]?.custom_differ_charges)} */}
            
            {differChargesDetails.map((sts) => (
  <option 
    key={sts.practitioner_time} 
    value={sts.practitioner_time} 
    selected={patientinfo?.[0]?.custom_differ_charges == sts.practitioner_time} 
    className={patientinfo?.[0]?.custom_differ_charges == sts.practitioner_time ? "text-black" : "text-[#2B2F32]"}
  >
    {sts.practitioner_time}
  </option>
))} 

          </select>
    </div>

    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Appointment Type</label>
          <select
  name="appointmentType"
  value={patientinfo?.[0]?.appointment_type || ''} // Bind the value of the select to appointment_type
  onChange={(e) => setAppointmentType(e.target.value)} // Handle change event
  className={`bg-[#FFFFFF] rounded-[4px] border border-[#656565] text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
        
    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
>
  {appointmentType.map((sts) => (
    <option 
      key={sts.appointment_type} 
      value={sts.appointment_type} 
      className={patientinfo?.[0]?.appointment_type === sts.appointment_type ? "text-black" : "text-[#2B2F32]"}
    >
      {sts.appointment_type}
    </option>
  ))}
</select>

    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Duration (In Minutes)</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Duration (In Minutes)" value={selectedAppointmentDetail ? selectedAppointmentDetail : ''} disabled />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Referred by</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Referred by" value={patientinfo?.[0]?.custom_reffered ? patientinfo?.[0]?.custom_reffered : ''}  />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Actual Amount</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="₹00.00" value={patientinfo?.[0]?.custom_actual_amount ? patientinfo?.[0]?.custom_actual_amount : '0.00'} />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Discount Type</label>
          <select
      value={selectedValue5}
      onChange={(e) => setSelectedValue5(e.target.value)}
      className={`bg-[#FFFFFF] rounded-[4px] border border-[#656565] text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
    
        dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
    >
           
  <option
    value="Fixed"
    className={
      patientinfo?.[0]?.custom_discount_type === "Fixed" ? "text-black" : "text-[#656565]"
    }
  >
    Fixed
  </option>
  <option
    value="Percentage"
    className={
      patientinfo?.[0]?.custom_discount_type === "Percentage" ? "text-black" : "text-[#656565]"
    }
  >
    Percentage
  </option>


          </select>
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Discount Amount</label>
          <input 
  type="text" 
  className="bg-[#F2F4F5] border border-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" 
  placeholder="₹00.00" 
  value={
    patientinfo?.[0]?.custom_discount_type === "Fixed" 
      ? patientinfo?.[0]?.custom_discount_amount || '0.00' 
      : patientinfo?.[0]?.custom_discount_type === "Percentage" 
      ? patientinfo?.[0]?.custom_discount_percentage || '0.00' 
      : '0.00'
  }
/>
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Total Amount</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="₹00.00" value={patientinfo?.[0]?.custom_total_amount ? patientinfo?.[0]?.custom_total_amount : '0.00'}  />
    </div>

    
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Amount Paid</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="₹00.00"  value={patientinfo?.[0]?.paid_amount ? patientinfo?.[0]?.paid_amount : '0.00'} />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Amount Pending</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="₹00.00"    value={patientinfo?.[0]?.custom_amount_pending ? patientinfo?.[0]?.custom_amount_pending : '0.00'} />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Amount Refund</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="₹00.00"   value={patientinfo?.[0]?.custom_amount_refund ? patientinfo?.[0]?.custom_amount_refund : '0.00'}  />
    </div>

    




  </div>
</div>
):''}
{billingtypeValue === 'Laboratory' ? (
  <>
<div className="px-[18px] pt-[8px] bg-[#FFFFFF] pb-[0px]">
  <div className="grid gap-2 mb-4 md:grid-cols-3">
     
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Prescribing Doctor</label>
         
     <select
      value={selectedValue7}
      onChange={(e) => {
        setSelectedValue7(e.target.value);
       
      }}
      className={`bg-[#FFFFFF] border border-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
        ${selectedValue7 ? 'text-black' : 'text-[#ABAFB1]'} 
        dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
    >
            <option value="">Select Consultant</option>
			{doctorDetails.map((sts) => (
                       <option  className="text-[#656565]" value={sts.id} key={sts.id}>
                       {sts.id} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {sts.name}
                     </option>
                    ))}
<option
    value="create-new-doctor"
    className="text-[#EC642A] text-[12px] p-[20px] g-[10px] font-inter text-xs font-normal leading-extra-tight"
   
  >
    + Create New Doctor 
  </option>
          </select>
          
    </div>
    <div>
      <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">
      Collection Center
        </label>
        <select
      value={selectedValue8}
      onChange={(e) => {
        setSelectedValue8(e.target.value);
       
      }}
      className={`bg-[#FFFFFF] border border-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
        ${selectedValue8 ? 'text-black' : 'text-[#ABAFB1]'} 
        dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
    >
       
  <option value="">Select Procedure Room</option>
 
    <option  className="text-[#656565]" value="101">
    101
    </option>
    <option  className="text-[#656565]" value="102">
    102
    </option>
 
</select>


    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Pathology Doctor</label>
         
     <select
      value={selectedValue9}
      onChange={(e) => {
        setSelectedValue9(e.target.value);
        if (e.target.value === "create-new-doctor") {
          nvg("/new-doctor");
        }
      }}
      className={`bg-[#FFFFFF] border border-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
        ${selectedValue9 ? 'text-black' : 'text-[#ABAFB1]'} 
        dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
    >
            <option value="">Select Procedure</option>
			{doctorDetails.map((sts) => (
                       <option  className="text-[#656565]" value={sts.id} key={sts.id}>
                       {sts.id} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {sts.name}
                     </option>
                    ))}
<option
    value="create-new-doctor"
    className="text-[#EC642A] text-[12px] p-[20px] g-[10px] font-inter text-xs font-normal leading-extra-tight"
   
  >
    + Create New Doctor 
  </option>
          </select>
          
    </div>
   </div>
</div>
<div className="px-[18px] pt-[0px] bg-[#FFFFFF] pb-[0px]"> 
 <div className="grid gap-2 mb-4 md:grid-cols-1">
  <div className="overflow-x-auto">
      <table className="min-w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-[#F2F4F5]">
            <th className="w-[250px] h-[40px] border border-[#CFD3D4] px-[16px] py-[10px] gap-[10px]"><div className="w-[73px] h-[17px] font-inter font-semibold text-[14px] leading-[16.94px] text-[#2E2E2E]">Test Name</div></th>
            <th className="w-[145px] h-[40px] border border-[#CFD3D4] px-[16px] py-[10px] gap-[10px]"><div className="w-[73px] h-[17px] font-inter font-semibold text-[14px] leading-[16.94px] text-[#2E2E2E]">Sale Price</div></th>
            <th className="w-[160px] h-[40px] border border-[#CFD3D4] px-[16px] py-[10px] gap-[10px]"><div className="w-[140px] h-[17px] font-inter font-semibold text-[14px] leading-[16.94px] text-[#2E2E2E]">Discount Type</div></th>
            <th className="w-[145px] h-[40px] border border-[#CFD3D4] px-[16px] py-[10px] gap-[10px]"><div className="w-[140px] h-[17px] font-inter font-semibold text-[14px] leading-[16.94px] text-[#2E2E2E]">Discount Amount</div></th>
            <th className="w-[145px] h-[40px] border border-[#CFD3D4] px-[16px] py-[10px] gap-[10px]"><div className="w-[140px] h-[17px] font-inter font-semibold text-[14px] leading-[16.94px] text-[#2E2E2E]">Total Amount</div></th>
            <th className="w-[200px] h-[40px] border border-[#CFD3D4] px-[16px] py-[10px] gap-[10px]"><div className="w-[140px] h-[17px] font-inter font-semibold text-[14px] leading-[16.94px] text-[#2E2E2E]">Special Instruction</div></th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-[#FFFFFF]">
            <td className="border border-gray-300 px-4 py-2 text-gray-700">Row 1</td>
            <td className="border border-gray-300 px-4 py-2 text-gray-700">₹00.00</td>
            <td className="border border-gray-300 px-4 py-2 text-gray-700">Row 1</td>
            <td className="border border-gray-300 px-4 py-2 text-gray-700">₹00.00</td>
            <td className="border border-gray-300 px-4 py-2 text-gray-700">₹00.00</td>
            <td className="border border-gray-300 px-4 py-2 text-gray-700">Test</td>
          </tr>
          
        </tbody>
      </table>
  </div>
  </div>
</div>

<div className="px-[18px] pt-[0px] bg-[#FFFFFF] pb-[0px]"> 
    <div className="grid gap-2 mb-4 md:grid-cols-3">
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Total Amount</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="₹00.00"  />
    </div>

    
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Amount Paid</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="₹00.00"  />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Amount Pending</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="₹00.00"  />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Amount Refund</label>
          <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="₹00.00"  />
    </div>






  </div>
</div>

</> 
):''}


<div className='px-[18px] pt-[12px] bg-[#F8F9FC] pb-[12px]'>
  <div className='flex items-center justify-between'>
      <h1 className='text-[#1C2434] font-Inter font-semibold text-16px'></h1>
      <div className="flex space-x-2 h-[44px] w-[370px]">
      <button
  className="w-[180px] h-[44px] text-white bg-[#64748B] text-sm rounded-[12px] flex items-center justify-center"
>
  <span className="text-[#FFFFFF] font-inter font-normal text-base leading-[19.36px] text-center">
  Save
  </span>
</button>
        
          <button
  className="w-[180px] h-[44px] text-white bg-[#823455] text-sm rounded-[12px] flex items-center justify-center"
  // onClick={openModal}
  onClick={handleOpenPayNow}
>
  <span className="text-[#FFFFFF] font-inter font-normal text-base leading-[19.36px] text-center">
    Pay Now
  </span>
</button>
          {/* <AddSlotsModal isOpen={isOpen} onClose={closeModal} />  */}
      </div>
  </div>
</div>


</div>
</div>
{/* modal start         */}
{isModalOpen  && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div className="relative bg-white rounded-lg w-full max-w-4xl p-2 md:p-4">
              <div className='flex items-center justify-between'>
                <h5 className='text-[16px] text-[#1E1E1E] font-inter font-semibold text-base leading-19.36'>Register Payment</h5>
                <button
                  className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                  onClick={closeModalPop} // Close button onClick handler
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
          
  <div className="px-[1px] pt-[4px] bg-[#F8F9FC] pb-[0px]">
   <div className="grid gap-2 mb-6 md:grid-cols-3">
  
   <div>
          <label for="company" className="text-left block text-[#5E6366] font-Inter w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Mode of Payment</label>
          <select
  value={paymentmodevalue}
  onChange={(e) => {
    const value = e.target.value;
    setpaymentmodevalue(value);
    if (e.target.value) {
      seterrorpaymentmodevalue(''); // Clear the error when a date is selected
    }
  }}
  className={`bg-[#FFFFFF] rounded-[4px] border border-[#656565] text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
    ${paymentmodevalue ? 'text-black' : 'text-[#ABAFB1]'} 
    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
    style={{border:errorpaymentmodevalue !== '' ? '1px solid red' : ''}}
>
         
			<option value="">Select Mode of Payment</option>
      {/* <option value="Cash">Cash</option> */}
      {/* <option value="Bank">Bank</option> */}
			{paymentModeDetails.map((sts) => (
                        <option  className="text-[#2B2F32]" value={sts.mode_of_payment} >
                                {`${sts.mode_of_payment}`}
                              </option>
                            ))}

          </select>
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Inter w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Amount</label>
          <input type="number" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Amount" value={paymentamount}
                  onChange={(e) => {
                    setpaymentamount(e.target.value);
                    if (e.target.value) {
                      seterrorpaymentamount(''); // Clear the error when a date is selected
                    }
                  }} 
                  style={{
                    border: errorpaymentamount ? '1px solid red' : '',  // Apply red border if error
                  }}  />
    </div>
   
    <div>
      <label for="company" className="text-left block text-[#5E6366] font-Inter w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px] font-[14px]">
      Payment Date  
        </label>
       
        <input 
  type="date" 
  className={`bg-gray-50 border border-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
    ${paymentdate ? 'text-black' : 'text-[#ABAFB1]'} 
    dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px] h-[42px] 
    ${errorpaymentdate !== '' ? 'border-red-500' : ''}`} // Conditionally apply red border
  value={paymentdate}
  onChange={(e) => {
    setpaymentdate(e.target.value);
    // Optionally, clear the error once a valid date is entered
    if (e.target.value) {
      seterrorpaymentdate(''); // Clear the error when a date is selected
    }
  }}
  style={{ border: errorpaymentdate !== '' ? '1px solid red' : '' }} // Conditional inline style (optional)
  min={new Date().toISOString().split("T")[0]}  // Disable past dates
/>


    </div>
  </div>




 </div>

  

 <div className="flex justify-between items-center space-x-4">
  <p className="font-inter font-semibold text-[14px] leading-[16.94px] text-[#1C2434]">
    Payment Difference: <span className="text-[#FC8B5C]">₹0.00</span>
  </p>

  <button
    type="submit"
    onClick={handlePayNowSave}
    className="text-white bg-[#FC8B5C] font-medium text-sm px-8 w-[180px] h-[44px] rounded-[12px] font-inter"
  >
    Pay Now
  </button>
</div>



            </div>
          </div>
        </div>
      )}
        </Layout>
    );
};

export default EditAppointment;