import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from '../components/Layout';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; 
import { getToken } from "../services/LocalStorageSrv";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Loader from "../Loader";
const Patient = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const tokendata = getToken("data");
  const nvg = useNavigate();
  const [loading, setLoading] = useState(true);
  const [patientDetails, setPatientDetails] = useState([]);
  const [displayedPatients, setDisplayedPatients] = useState([]);
  const [totalPatients, setTotalPatients] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const batchSize = 10000; // Fetch 60 records from the API
  const patientsPerPage = 10; // Display 10 records per page

  const fetchPatientData = async (page) => {
    try {
      setLoading(true);

      const response = await axios.post(
        `${apiUrl}/healthcare_management_system.web.api.get_patient_list`,
        {
          start: 0,
          page_length: batchSize,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${tokendata.data.api_key.trim()}:${tokendata.data.api_secret.trim()}`,
          },
        }
      );

      if (response.data && response.data.data) {
        setPatientDetails(response.data.data.patient_list);
        setTotalPatients(response.data.data.total_patient);
        paginateData(response.data.data.patient_list, 0);
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const paginateData = (data, selectedPage) => {
    const offset = selectedPage * patientsPerPage;
    const paginatedData = data.slice(offset, offset + patientsPerPage);
    setDisplayedPatients(paginatedData);
  };

  useEffect(() => {
    fetchPatientData();
  }, []);

  useEffect(() => {
    paginateData(patientDetails, currentPage);
  }, [currentPage, patientDetails]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <Layout>
       {loading ? <Loader /> : null}
      <div className="md:mx-auto px-4 py-4" style={{backgroundColor: '#F1F5F9', height:'100%'}}>
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-sm text-[#8F8F8F]">Patients List</h2>
        </div>

        <div className="bg-[#fff]">
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className='px-[13px] pt-[15px] bg-[#F8F9FC] pb-[15px]'>
              <div className='flex items-center justify-between'>
                <h1 className='font-Mulish font-bold text-18px tracking-[0.01em] text-[#1C2434]'>Patients List</h1>
                <a href="javascript:void(0);" onClick={() => nvg("/new-patient")} className='bg-[#823455] h-[32px] rounded-[3px] text-white flex items-center justify-center px-[8px] font-Mulish'>+ Add Patient</a>
              </div>
            </div>

            <div className="overflow-x-auto   border-t">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="font-medium font-Mulish text-[#64748B] p-2">Sl No.</th>
                    <th scope="col" className="font-medium font-Mulish text-[#64748B] p-2">Patient Id</th>
                    <th scope="col" className="font-medium font-Mulish text-[#64748B] p-2">Name</th>
                    <th scope="col" className="font-medium font-Mulish text-[#64748B] p-2">Age</th>
                    <th scope="col" className="font-medium font-Mulish text-[#64748B] p-2">Mobile No.</th>
                    <th scope="col" className="font-medium font-Mulish text-[#64748B] p-2">Email</th>
                    <th scope="col" className="font-medium font-Mulish text-[#64748B]">Status</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {displayedPatients.length > 0 ? (
                    displayedPatients.map((list, index) => (
                      <tr key={list.patient_id} className="hover:bg-gray-50">
                        <td className="text-sm font-Mulish text-[#1C2434] dark:text-white">{(currentPage * patientsPerPage) + index + 1}</td>
                        <td className="text-sm font-Mulish text-[#1C2434] dark:text-white"><a href="javascript:void(0);" onClick={() => nvg(`/edit-patient/${list.patient_id}`)} className="font-medium">{list.patient_id}</a></td>
                        <td className="text-sm font-Mulish text-[#1C2434] dark:text-white">{list.name}</td>
                        <td className="text-sm font-Mulish text-[#1C2434] dark:text-white">{list.age ? list.age : '-'}</td>
                        <td className="text-sm font-Mulish text-[#1C2434] dark:text-white">{list.mobile ? list.mobile : '-'}</td>
                        <td className="text-sm font-Mulish text-[#1C2434] dark:text-white">{list.email ? list.email : '-'}</td>
                       
                        <td className="px-3 py-3 whitespace-nowrap text-sm" >
                          <span className={list.status === 'Active' ? 'text-sm px-6 bg-[#80E4C3] text-[#fff] font-Mulish rounded-sm' : 'text-sm px-6 bg-[#FF707E] text-[#fff] font-Mulish rounded-sm'}>
                            {list.status}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-sm font-Mulish text-[#1C2434] dark:text-white ml-4 text-center">
                        Patient Data Not Available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {displayedPatients.length > 0 && (
              <div className="grid grid-cols-12 items-center px-4 py-6 md:px-6 xl:px-7.5">
                <div className="col-span-4 text-left">
                  <h6 className="text-sm font-small text-[#64748B] dark:text-white">Showing {currentPage * patientsPerPage + 1} to {Math.min((currentPage + 1) * patientsPerPage, totalPatients)} of {totalPatients} entries</h6>
                </div>
                <div className="col-span-8 text-right">
                  <ReactPaginate
                    pageCount={Math.ceil(totalPatients / patientsPerPage)}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName="pagination-container"
                    pageClassName="pagination-item"
                    pageLinkClassName="pagination-link"
                    activeClassName="pagination-active"
                    previousClassName="pagination-prev"
                    nextClassName="pagination-next"
                    previousLinkClassName="pagination-link"
                    nextLinkClassName="pagination-link"
                    previousLabel={<img src="/images/Expand_left_double.png" alt="Previous" />}
                    nextLabel={<img src="/images/Expand_right_double.png" alt="Next" />}
                    breakLabel="..."
                  />
                </div>
              </div>
            )}
          </div>
        </div>        
      </div>
    </Layout>
  );
};

export default Patient;
