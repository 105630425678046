import React, { useState } from 'react';

const SideMenu = () => {
  const [sidemenu, setSidemenu] = useState(false);

  const toggleSidemenu = () => {
    setSidemenu(!sidemenu);
  };

  return (
    
    <div className="md:hidden">
        
      <div onClick={toggleSidemenu} className={`fixed inset-0 z-30 bg-gray-600 opacity-0 pointer-events-none transition-opacity ease-linear duration-300 ${sidemenu ? 'opacity-75 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}></div>

      {/* Small Screen Menu */}
      <div className={`fixed inset-y-0 left-0 flex flex-col z-40 max-w-xs w-full bg-white transform ease-in-out duration-300 ${sidemenu ? 'translate-x-0' : '-translate-x-full'}`}>
        {/* Brand Logo / Name */}
        <div className="flex items-center px-6 py-3 h-16">
          <div className="text-2xl font-bold tracking-tight text-gray-800">Dashing Admin.</div>
        </div>
        {/* @end Brand Logo / Name */}

        <div className="px-4 py-2 flex-1 h-0 overflow-y-auto">
          <ul>
            <li>
              <a href="#" className="mb-1 px-2 py-2 rounded-lg flex items-center font-medium text-gray-700 hover:text-blue-600 hover:bg-gray-200">
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-4 opacity-50" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                  <rect x="4" y="4" width="6" height="6" rx="1"></rect>
                  <rect x="14" y="4" width="6" height="6" rx="1"></rect>
                  <rect x="4" y="14" width="6" height="6" rx="1"></rect>
                  <rect x="14" y="14" width="6" height="6" rx="1"></rect>
                </svg>
                Dashboard
              </a>
            </li>

            {/* Add other list items similarly */}
          </ul>
        </div>
      </div>
      {/* @end Small Screen Menu */}
      
    </div>
    
  );
};

export default SideMenu;
