import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Layout from '../components/Layout';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; 
const ViewPatient = () => {
   
	const [activeTab, setActiveTab] = useState('tab1');

	const handleTabClick = (tab) => {
	  setActiveTab(tab);
	};
    return (
        <Layout>
            {/* md:max-w-6xl */}
          <div className="md:mx-auto px-4 py-4" style={{backgroundColor: '#F1F5F9',height:'100%'}}>
                <div class="flex items-center justify-between mb-4">
                <h2 class="font-small text-[#8F8F8F]">Patients List &gt; View Patient</h2>
              </div>

   <div className="bg-[#fff]">
    <div class="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
         <div className='px-[18px] pt-[15px] bg-[#F8F9FC] pb-[18px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-xl font-medium text-gray-800'>Personal Information</h1>

                {/* <button className='bg-[#823455] h-[32px] rounded-[3px] text-white flex items-center justify-center px-[8px]'>+ Add Patient</button> */}
            </div>
            </div>
            <hr/>
            <div className="px-[18px] pt-[15px] bg-[#F8F9FC] pb-[18px]">

       {/* input around round css like rounded-lg      */}
<form>
	<div class="grid gap-6 mb-6 md:grid-cols-2">
	
    <div class="flex items-center">
        <label for="first_name" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">First name</label>
        <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your first name" required />
    </div>

    <div class="flex items-center">
        <label for="last_name" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Last name</label>
        <input type="text" id="last_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your last name" required />
    </div>

	<div class="flex items-center">
        <label for="gender" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Gender</label>
        <select id="gender" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="gender" required>
			<option value="">Select Gender</option>
			<option value="Male">Male</option>
			<option value="Female">Female</option>
			<option value="Other">Other</option>
		</select>
    </div>

    <div class="flex items-center">
        <label for="dob" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">DOB</label>
        <input type="date" id="dob" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="dob" required />
    </div>

	<div class="flex items-center">
        <label for="identification" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Identification No. (UID)</label>
        <input type="text" id="identification" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Identification No. (UID)" required />
    </div>

	<div class="flex items-center">
        <label for="age" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Age</label>
        <input type="number" id="age" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your Age" required />
    </div>

	<div class="flex items-center">
        <label for="report" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Report Preference</label>
		<select id="report" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Report Preference" required>
			<option value="">Select Report Preference</option>
			<option value="Male">1</option>
			<option value="Female">2</option>
			<option value="Other">3</option>
		</select>
    </div>
	<div class="flex items-center">
        <label for="blood_group" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Blood Group</label>
		<select id="report" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Blood Group" required>
			<option value="">Select Blood Group</option>
			<option value="Male">A</option>
			<option value="Female">B</option>
			<option value="Other">C</option>
		</select>
    </div>




    </div>
	



{/* start tabs */}
{/* <div className="max-w-3xl mx-auto"> */}
<div className="max-w-1xl">
<div className="flex flex-wrap space-x-4 border-b-2 border-transparent border-[#e3e3e3]">
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab1' ? 'border-[#EC642A] border-b-2 text-[#222] font-medium' : 'text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab1')}
  >
    General Information
  </a>
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab2' ? 'border-[#EC642A] border-b-2 text-[#222] font-medium' : 'text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab2')}
  >
    Hospital Info
  </a>
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab3' ? 'border-[#EC642A] border-b-2 text-[#222] font-medium' : 'text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab3')}
  >
    Clinical Assessment
  </a>
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab4' ? 'border-[#EC642A] border-b-2 text-[#222] font-medium' : 'text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab4')}
  >
    Diseases
  </a>
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab5' ? 'border-[#EC642A] border-b-2 text-[#222] font-medium' : 'text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab5')}
  >
    Family
  </a>
</div>

      
      {/* Render content based on active tab */}
      {activeTab === 'tab1' && (
        <div className="mt-4 p-4 border-gray-300">
         <div class="grid gap-6 mb-6 md:grid-cols-2">
	
    <div class="flex items-center">
        <label for="address" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Address</label>
        <input type="text" id="address" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your address" required />
    </div>

    <div class="flex items-center">
        <label for="city" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">City</label>
        <input type="text" id="city" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your city" required />
    </div>
    <div class="flex items-center">
        <label for="state" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">State</label>
		<select id="state" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="state" required>
			<option value="">Select State</option>
			<option value="Male">A</option>
			<option value="Female">B</option>
			<option value="Other">C</option>
		</select>
    </div>
    <div class="flex items-center">
        <label for="pincode" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Pincode</label>
        <input type="number" id="pincode" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your Pincode" required />
    </div>
    <div class="flex items-center">
        <label for="country" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Country</label>
		<select id="country" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Country" required>
			<option value="">Select Country</option>
			<option value="Male">India</option>
			<option value="Female">USA</option>
			<option value="Other">UK</option>
      <option value="Other">UAE</option>
		</select>
    </div>
    <div class="flex items-center">
        <label for="email" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Email</label>
        <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your Email" required />
    </div>
    <div class="flex items-center">
        <label for="mobile" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Mobile No.</label>
        <input type="number" id="mobile" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your Mobile No." required />
    </div>
    <div class="flex items-center">
        <label for="marital_status" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Marital Status</label>
		<select id="marital_status" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Marital Status" required>
			<option value="">Select Marital Status</option>
			<option value="Married">Married</option>
			<option value="Unmerried">Unmerried</option>
		</select>
    </div>
    <div class="flex items-center">
        <label for="aadhar" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Aadhar No.</label>
        <input type="number" id="aadhar" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your Aadhar No." required />
    </div>
    <div class="flex items-center">
        <label for="pan" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Pan No.</label>
        <input type="text" id="pan" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your Pan No." required />
    </div>
	</div>
        </div>
      )}
      {activeTab === 'tab2' && (
        <div className="mt-4 p-4 border-gray-300">
          <div class="grid gap-6 mb-6 md:grid-cols-2">
	
 
  <div class="flex items-center">
      <label for="referring_doctors" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Referring Doctors</label>
  <select id="referring_doctors" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="referring doctors" required>
    <option value="">Select Referring Doctors</option>
    <option value="Male">A</option>
    <option value="Female">B</option>
    <option value="Other">C</option>
  </select>
  </div>
  <div class="flex items-center">
      <label for="registration_service" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Registration Service</label>
  <select id="registration_service" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Registration Service" required>
    <option value="">Select Registration Service</option>
    <option value="Male">A</option>
    <option value="Female">B</option>
    <option value="Other">C</option>
  </select>
  </div>
 
  <div class="flex items-center">
      <label for="death_register" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Death Register</label>
  <select id="death_register" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Death Register" required>
    <option value="">Select Death Register</option>
    <option value="Male">1</option>
    <option value="Female">2</option>
    <option value="Other">3</option>
  </select>
  </div>
  <div class="flex items-center">
        <label for="date_of_death" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Date of Death</label>
        <input type="date" id="date_of_death" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Date of Death" required />
    </div>
  
</div>
        </div>
      )}
      {activeTab === 'tab3' && (
        <div className="mt-4 p-4 border-gray-300">
          <div class="grid gap-6 mb-6 md:grid-cols-2">
          <div class="flex items-center">
      <label for="height" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Height</label>
      <input type="number" id="height" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="0.00" required />
  </div>
  <div class="flex items-center">
      <label for="respiratory_rate" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Respiratory Rate</label>
      <input type="number" id="respiratory_rate" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="0" required />
  </div>
  <div class="flex items-center">
      <label for="weight" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Weight</label>
      <input type="number" id="weight" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="0.00" required />
  </div>
  <div class="flex items-center">
      <label for="body_temperature" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Body Temperature</label>
      <input type="number" id="body_temperature" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="0.00" required />
  </div>
  <div class="flex items-center">
      <label for="blood_pressure_systolic" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Blood Pressure (systolic)</label>
      <input type="number" id="blood_pressure_systolic" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="0.00" required />
  </div>
  <div class="flex items-center">
      <label for="blood_pressure_diastolic" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Blood Pressure (diastolic)</label>
      <input type="number" id="blood_pressure_diastolic" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="0.00" required />
  </div>
  <div class="flex items-center">
      <label for="heart_rate_pulse" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Heart Rate / Pulse</label>
      <input type="number" id="heart_rate_pulse" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="0" required />
  </div>
  <div class="flex items-center">
      <label for="bmi" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">BMI</label>
      <input type="number" id="bmi" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="0" required />
  </div>
	</div>
        </div>
      )}
      {activeTab === 'tab4' && (
        <div className="mt-4 p-4 border-gray-300">
          <div class="grid gap-6 mb-6 md:grid-cols-2">
	
    <div class="flex items-center">
        <label for="allergies" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Allergies</label>
        <textarea id="allergies" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Write allergies" required></textarea>
    </div>

    <div class="flex items-center">
        <label for="medical_history" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Medical History</label>
        <textarea id="medical_history" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Write medical history" required></textarea>
    </div>
    <div class="flex items-center">
        <label for="medication" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Medication</label>
        <textarea id="medication" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Write medication" required></textarea>
    </div>
    <div class="flex items-center">
        <label for="surgical_history" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Surgical History</label>
        <textarea id="surgical_history" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Write surgical_history" required></textarea>
    </div>
	</div>
        </div>
      )}
      {activeTab === 'tab5' && (
        <div className="mt-4 p-4 border-gray-300">
          <div class="grid gap-6 mb-6 md:grid-cols-2">
	
    <div class="flex items-center">
        <label for="family_member" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Family Member</label>
        <input type="text" id="family_member" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your family member" required />
    </div>

    <div class="flex items-center">
      <label for="relation" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Relation</label>
  <select id="relation" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Relation" required>
    <option value="">Select Relation</option>
    <option value="Father">Father</option>
    <option value="Son">Son</option>
    <option value="Other">Other</option>
  </select>
  </div>
	</div>
        </div>
      )}
    </div>

{/* end tabs */}
<br/>
<div class="flex justify-end space-x-4">
    <button type="submit" class="text-black bg-[#E2E8F0] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel</button>
    <button type="submit" class="text-white bg-[#823455] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
</div>
</form>

</div>


</div>
 </div>        



          </div>
         
        </Layout>
    );
};

export default ViewPatient;