import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Layout from '../components/Layout';
import AddAppointmentModal from '../Pages/AddAppointmentModal';
import AddIncomeAccountModal from '../Pages/AddIncomeAccountModal';
import AddAddressModal from '../Pages/AddAddressModal';
import AddContactModal from '../Pages/AddContactModal';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; 
import { FaRegCalendarMinus, FaEllipsisV } from "react-icons/fa"
const EditDoctor = ({}) => {
   
	const [activeTab, setActiveTab] = useState('tab1');

	const handleTabClick = (tab) => {
	  setActiveTab(tab);
	};

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const [isOpen1, setIsOpen1] = useState(false);

  const openModal1 = () => {
    setIsOpen1(true);
  };

  const closeModal1 = () => {
    setIsOpen1(false);
  };

  const [isOpen2, setIsOpen2] = useState(false);

  const openModal2 = () => {
    setIsOpen2(true);
  };

  const closeModal2 = () => {
    setIsOpen2(false);
  };

  const [isOpen3, setIsOpen3] = useState(false);

  const openModal3 = () => {
    setIsOpen3(true);
  };

  const closeModal3 = () => {
    setIsOpen3(false);
  };

    return (
        <Layout>
            {/* md:max-w-6xl */}
          <div className="md:mx-auto px-4 py-4" style={{backgroundColor: '#F1F5F9',height:'100%'}}>
                <div class="flex items-center justify-between mb-4">
                <h2 class="font-small text-[#8F8F8F]">Doctors List &gt; Edit Doctor</h2>
              </div>

   <div className="bg-[#fff]">
    <div class="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
         <div className='px-[18px] pt-[15px] bg-[#F8F9FC] pb-[18px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-xl font-medium text-gray-800'>Basic Details</h1>

                {/* <button className='bg-[#823455] h-[32px] rounded-[3px] text-white flex items-center justify-center px-[8px]'>+ Add Patient</button> */}
            </div>
            </div>
            <hr/>
            <div className="px-[18px] pt-[15px] bg-[#F8F9FC] pb-[18px]">

       {/* input around round css like rounded-lg      */}
<form>
	<div class="grid gap-6 mb-6 md:grid-cols-2">
	<div class="flex items-center">
        <label for="series" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Series</label>
        <input type="text" id="series" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="HLC-PRAC-.YYYY.-" required disabled/>
    </div>
	<div class="flex items-center">
        <label for="status" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Status</label>
        <select id="status" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="status" required>
			<option value="">Select Status</option>
			<option value="Active">Active</option>
			<option value="Deactive">Deactive</option>
		</select>
    </div>
    <div class="flex items-center">
        <label for="first_name" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">First name</label>
        <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your first name" required />
    </div>

    <div class="flex items-center">
        <label for="last_name" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Last name</label>
        <input type="text" id="last_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your last name" required />
    </div>
	<div class="flex items-center">
        <label for="mobile" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Mobile</label>
        <input type="number" id="mobile" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your mobile" required />
    </div>
	<div class="flex items-center">
        <label for="gender" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Gender</label>
        <select id="gender" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="gender" required>
			<option value="">Select Gender</option>
			<option value="Male">Male</option>
			<option value="Female">Female</option>
			<option value="Other">Other</option>
		</select>
    </div>





    </div>
	



{/* start tabs */}
{/* <div className="max-w-3xl mx-auto"> */}
<div className="max-w-1xl">
      {/* <div className="flex flex-wrap"> */}
      <div className="flex flex-wrap space-x-4 border-b-2 border-transparent border-[#e3e3e3]">
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab1' ? 'border-b-2 border-[#EC642A] text-[#222] font-medium' : 'border-b-2 border-transparent text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab1')}
  >
    Employee and User Details
  </a>
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab2' ? 'border-b-2 border-[#EC642A] text-[#222] font-medium' : 'border-b-2 border-transparent text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab2')}
  >
    Appointments
  </a>
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab3' ? 'border-b-2 border-[#EC642A] text-[#222] font-medium' : 'border-b-2 border-transparent text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab3')}
  >
    Charges
  </a>
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab4' ? 'border-b-2 border-[#EC642A] text-[#222] font-medium' : 'border-b-2 border-transparent text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab4')}
  >
    Account Details
  </a>
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab5' ? 'border-b-2 border-[#EC642A] text-[#222] font-medium' : 'border-b-2 border-transparent text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab5')}
  >
    Address and Contacts
  </a>
</div>

      {/* Render content based on active tab */}
      {activeTab === 'tab1' && (
        <div className="mt-4 p-4 border-gray-300">
         <div class="grid gap-6 mb-6 md:grid-cols-2">
	
    
    <div class="flex items-center">
        <label for="practitioner_type" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Practitioner Type</label>
		<select id="practitioner_type" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Practitioner Type" required>
			<option value="">Select Practitioner Type</option>
			<option value="Internal">Internal</option>
		</select>
    </div>
    <div class="flex items-center">
        <label for="user" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">User</label>
        <input type="text" id="user" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your User" required />
    </div>
	<div class="flex items-center">
        <label for="employee" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Employee</label>
        <input type="text" id="employee" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your employee" required />
    </div>
	<div class="flex items-center">
        <label for="hospital" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Hospital</label>
        <input type="text" id="hospital" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your hospital" required />
    </div>
    <div class="flex items-center">
        <label for="medical_department" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Medical Department</label>
		<select id="medical_department" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Medical Department" required>
			<option value="">Select Department</option>
			<option value="A">A</option>
			<option value="B">B</option>
			<option value="C">C</option>
      <option value="D">D</option>
		</select>
    </div>
    
	</div>
        </div>
      )}
      {activeTab === 'tab2' && (
        <div className="mt-4 p-4 border-gray-300">
          <div class="grid gap-6 mb-6 md:grid-cols-2">
	
        

<div class="flex flex-col md:flex-row items-center">
    <label for="practitioner_schedules" class="block text-sm font-medium text-gray-900 dark:text-white md:w-1/5 mb-2 md:mb-0">Practitioner Schedules</label>
    <a href="javascript:void(0);" class="bg-[#E2E5FB] h-[28px] rounded-[1px] text-[#3C50E0] flex items-center justify-center px-[8px] border-solid border-2 border-[#3C50E0] font-small md:mx-5" onClick={openModal}>+ Add Appointment</a>
   
    </div>
    <AddAppointmentModal isOpen={isOpen} onClose={closeModal} /> {/* Pass onClose function */}
</div>

<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-[30px] mt-[25px] pb-[15px]'>
    <div className='h-[100px] rounded-[8px] bg-white flex items-center justify-between px-[30px] cursor-pointer shadow-lg'>
    <div className="w-9 h-9 rounded-full absolute bg-[#D4CEE4] text-[#270B79] font-bold text-center flex justify-center items-center">01</div>

        <div className='ml-10'>
        
        <div className="flex flex-col">
        <h2 className='text-[#222] text-[13px] leading-[17px] font-bold p-1'>
  Schedule : <span className='inline-block align-middle font-normal text-[#666666]'>Weekly</span>
</h2>
<h2 className='text-[#222] text-[13px] leading-[17px] font-bold ml-9'>
  Service Unit : <span className='inline-block align-middle font-normal text-[#666666]'>Test - STPL</span>
</h2>

</div>

        </div>
        <div className="flex">
  <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded mr-2">
    <FaEdit className="inline-block" />
  </button>
  <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded">
    <FaTrash className="inline-block" />
  </button>
</div>

    </div>
    
    <div className='h-[100px] rounded-[8px] bg-white flex items-center justify-between px-[30px] cursor-pointer shadow-lg'>
    <div className="w-9 h-9 rounded-full absolute bg-[#D4CEE4] text-[#270B79] font-bold text-center flex justify-center items-center">02</div>

        <div className='ml-10'>
        
        <div className="flex flex-col">
        <h2 className='text-[#222] text-[13px] leading-[17px] font-bold p-1'>
  Schedule : <span className='inline-block align-middle font-normal text-[#666666]'>Weekand</span>
</h2>
<h2 className='text-[#222] text-[13px] leading-[17px] font-bold ml-9'>
  Service Unit : <span className='inline-block align-middle font-normal text-[#666666]'>Test - TCSV</span>
</h2>

</div>

        </div>
        <div className="flex">
  <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded mr-2">
    <FaEdit className="inline-block" />
  </button>
  <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded">
    <FaTrash className="inline-block" />
  </button>
</div>

    </div>



</div>




        </div>
      )}
      {activeTab === 'tab3' && (
        <div className="mt-4 p-4 border-gray-300">
          <div class="grid gap-6 mb-6 md:grid-cols-2">
          <div class="flex items-center">
      <label for="out_potient_c" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Out Patient Consulting Charge Item</label>
      <input type="text" id="out_potient_c" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter out patient consulting charge item" required />
  </div>
  <div class="flex items-center">
  <label for="inpatient_visit_c" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Inpatient Visit Charge Item</label>
      <input type="text" id="inpatient_visit_c" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter inpatient visit charge item" required />
  </div>
  <div class="flex items-center">
  <label for="out_potient" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Out Patient Consulting Charge</label>
      <input type="text" id="out_potient" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter out patient consulting charge" required />
  </div>
  <div class="flex items-center">
  <label for="inpatient_visit" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Inpatient Visit Charge</label>
      <input type="text" id="inpatient_visit" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter inpatient visit charge" required />
  </div>
  
	</div>
        </div>
      )}
      {activeTab === 'tab4' && (
        <div className="mt-4 p-4 border-gray-300">
          <div class="grid gap-6 mb-6 md:grid-cols-2">
          <div class="flex flex-col md:flex-row items-center">
    <label for="practitioner_schedules" class="block text-sm font-medium text-gray-900 dark:text-white md:w-1/5 mb-2 md:mb-0">Income Account</label>
    <a href="javascript:void(0);" class="bg-[#E2E5FB] h-[28px] rounded-[1px] text-[#3C50E0] flex items-center justify-center px-[8px] border-solid border-2 border-[#3C50E0] font-small md:mx-5" onClick={openModal1}>+ Add Details</a>
   
    </div>
    <AddIncomeAccountModal isOpen={isOpen1} onClose={closeModal1} /> {/* Pass onClose function */}

    </div>

    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-[30px] mt-[25px] pb-[15px]'>
    <div className='h-[100px] rounded-[8px] bg-white flex items-center justify-between px-[30px] cursor-pointer shadow-lg'>
    <div className="w-9 h-9 rounded-full absolute bg-[#D4CEE4] text-[#270B79] font-bold text-center flex justify-center items-center">01</div>

        <div className='ml-10'>
        
        <div className="flex flex-col">
        <h2 className='text-[#222] text-[13px] leading-[17px] font-bold p-1'>
  Company : <span className='inline-block align-middle font-normal text-[#666666]'>TCS</span>
</h2>
<h2 className='text-[#222] text-[13px] leading-[17px] font-bold ml-9'>
  Account : <span className='inline-block align-middle font-normal text-[#666666]'>Sales - TCS</span>
</h2>

</div>

        </div>
        <div className="flex">
  <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded mr-2">
    <FaEdit className="inline-block" />
  </button>
  <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded">
    <FaTrash className="inline-block" />
  </button>
</div>

    </div>
    
    <div className='h-[100px] rounded-[8px] bg-white flex items-center justify-between px-[30px] cursor-pointer shadow-lg'>
    <div className="w-9 h-9 rounded-full absolute bg-[#D4CEE4] text-[#270B79] font-bold text-center flex justify-center items-center">02</div>

        <div className='ml-10'>
        
        <div className="flex flex-col">
        <h2 className='text-[#222] text-[13px] leading-[17px] font-bold p-1 ml-5'>
        Company : <span className='inline-block align-middle font-normal text-[#666666]'>Stackerbee Technologies</span>
</h2>
<h2 className='text-[#222] text-[13px] leading-[17px] font-bold  mr-14'>
  Account : <span className='inline-block align-middle font-normal text-[#666666]'>Sales - TCSV</span>
</h2>

</div>

        </div>
        <div className="flex">
  <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded mr-2">
    <FaEdit className="inline-block" />
  </button>
  <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded">
    <FaTrash className="inline-block" />
  </button>
</div>

    </div>



</div>
   
	</div>
      )}
     {activeTab === 'tab5' && (
        <div className="mt-4 p-4 border-gray-300">
        
        <div class="grid gap-6 mb-6 md:grid-cols-2">
  <div>
  <div class="flex flex-col md:flex-row items-center">
    <label for="practitioner_schedules" class="block text-sm font-medium text-gray-900 dark:text-white md:w-1/5 mb-2 md:mb-0">No address added yet.</label>
    <a href="javascript:void(0);" class="bg-[#E2E5FB] h-[28px] rounded-[1px] text-[#3C50E0] flex items-center justify-center px-[8px] border-solid border-2 border-[#3C50E0] font-small md:mx-5" onClick={openModal2}>+ Add New Address</a>
   
    </div>
    
    <AddAddressModal isOpen={isOpen2} onClose={closeModal2} /> {/* Pass onClose function */}

    <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-[30px] mt-[25px] pb-[15px]'>
    <div className='h-[100px] rounded-[8px] bg-white flex items-center justify-between px-[30px] cursor-pointer shadow-lg'>
    <div className="w-9 h-9 rounded-full absolute bg-[#D4CEE4] text-[#270B79] font-bold text-center flex justify-center items-center">01</div>

        <div className='ml-10'>
        
        <div className="flex flex-col">
        <h2 className='text-[#222] text-[13px] text-left leading-[17px] font-bold p-1'>
        A-10,  Shashi Garden
</h2>
<span className='inline-block align-middle font-normal text-[13px] text-[#666666] ml-1'>Mayur Vihar Phase I New Delhi - 110091 India</span>

</div>

        </div>
        <div className="flex">
  <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded mr-2">
    <FaEdit className="inline-block" />
  </button>
  <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded">
    <FaTrash className="inline-block" />
  </button>
</div>

    </div>
    
    <div className='h-[100px] rounded-[8px] bg-white flex items-center justify-between px-[30px] cursor-pointer shadow-lg'>
    <div className="w-9 h-9 rounded-full absolute bg-[#D4CEE4] text-[#270B79] font-bold text-center flex justify-center items-center">02</div>

        <div className='ml-10'>
        
        <div className="flex flex-col">
        <h2 className='text-[#222] text-[13px] text-left leading-[17px] font-bold p-1'>
        XYZ
</h2>
<span className='inline-block align-middle font-normal text-[13px] text-[#666666] ml-1'>Pink City New Delhi - 110091 India</span>


</div>

        </div>
        <div className="flex">
  <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded mr-2">
    <FaEdit className="inline-block" />
  </button>
  <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded">
    <FaTrash className="inline-block" />
  </button>
</div>

    </div>



    </div>

  </div>
  
  <div>
  <div class="flex flex-col md:flex-row items-center">
    <label for="practitioner_schedules" class="block text-sm font-medium text-gray-900 dark:text-white md:w-1/5 mb-2 md:mb-0">No contact added yet.</label>
    <a href="javascript:void(0);" class="bg-[#E2E5FB] h-[28px] rounded-[1px] text-[#3C50E0] flex items-center justify-center px-[8px] border-solid border-2 border-[#3C50E0] font-small md:mx-5" onClick={openModal3}>+ Add New Contact</a>
   
    </div>
    <AddContactModal isOpen={isOpen3} onClose={closeModal3} /> {/* Pass onClose function */}
    <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-[30px] mt-[25px] pb-[15px]'>
    <div className='h-[100px] rounded-[8px] bg-white flex items-center justify-between px-[30px] cursor-pointer shadow-lg'>
    <div className="w-9 h-9 rounded-full absolute bg-[#D4CEE4] text-[#270B79] font-bold text-center flex justify-center items-center">01</div>

        <div className='ml-10'>
        
        <div className="flex flex-col">
        <h2 className='text-[#222] text-[13px] text-left leading-[17px] font-bold p-1'>
        email123.@gmail.com
</h2>
<span className='inline-block align-middle font-normal text-[13px] text-[#666666] ml-1'>9000090000 - www.stackerbee.com</span>

</div>

        </div>
        <div className="flex">
  <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded mr-2">
    <FaEdit className="inline-block" />
  </button>
  <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded">
    <FaTrash className="inline-block" />
  </button>
</div>

    </div>
    
    <div className='h-[100px] rounded-[8px] bg-white flex items-center justify-between px-[30px] cursor-pointer shadow-lg'>
    <div className="w-9 h-9 rounded-full absolute bg-[#D4CEE4] text-[#270B79] font-bold text-center flex justify-center items-center">02</div>

        <div className='ml-10'>
        
        <div className="flex flex-col">
        <h2 className='text-[#222] text-[13px] text-left leading-[17px] font-bold p-1'>
        Arpit123.@gmail.com
</h2>
<span className='inline-block align-middle font-normal text-[13px] text-[#666666] ml-1'>9555595555 - www.arpit.com</span>

</div>

        </div>
        <div className="flex">
  <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded mr-2">
    <FaEdit className="inline-block" />
  </button>
  <button className="text-[#DC3545] hover:bg-red-200 font-bold rounded">
    <FaTrash className="inline-block" />
  </button>
</div>

    </div>



    </div>
      
 
 
  </div>
</div>
	      
        
        </div>
      )}
    </div>

{/* end tabs */}
<br/>
<div class="flex justify-end space-x-4">
    <button type="submit" class="text-black bg-[#E2E8F0] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel</button>
    <button type="submit" class="text-white bg-[#823455] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
</div>
</form>

</div>


</div>
 </div>        



          </div>
         
        </Layout>
    );
};

export default EditDoctor;