import React, { useState, useEffect }  from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { getToken, removeToken, storeToken } from "../services/LocalStorageSrv";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdErrorOutline } from "react-icons/md";
import Loader from "../Loader";
const Home = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const tokendata = getToken("data");
  const nvg = useNavigate();
  const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [usr, setEmail] = useState("");
    const [pwd, setPassword] = useState("");
    const [error, setError] = useState("");
    const [erroremail, seterroremail] = useState("");
    const [errorpassword, seterrorpassword] = useState("");
    const [botherror, setBothError] = useState("");
    const [login, setlogin] = useState(false);
  const [data, setUserData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const handleLogin = async (e) => {
    e.preventDefault();
    if (usr !== "" && pwd !== "") {
      try {
        setLoading(true);
        const headers = {
          "Content-Type": "application/json",
          // "Authorization": "Bearer " + token,
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Origin": "*",
        };
        const response = await axios.post(
          `${apiUrl}/healthcare_management_system.web.api.login`,
          {
            usr,
            pwd,
          },
          headers // Include headers in the request
        );
        
        if (response.data.message === "Authentication Success") {
          // console.log('login:', response.data.message);
          const user = response?.data;
          // console.log('login d:',user);
        storeToken(user);
        setlogin(true);
        setUserData(user);
          nvg("/appointment");
          window.location.reload();
        // Clear any previous errors
        setErrorMessage("");
        } else {
          console.error("Error fetching data1:", response.data.message);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setErrorMessage(error.response.data.message);
          console.error("Error fetching data:", error.response.data.message);
        } else {
          setErrorMessage(error.message);
          console.error("Error fetching data:", error.message);
        }
        setLoading(false);
      }
    }
    if (usr === "") {
      seterroremail("Email is Required");
    } else {
      seterroremail("");
    }
    if (pwd === "") {
      seterrorpassword("Password is Required");
    } else {
      seterrorpassword("");
    }
    if (usr === "" || pwd === "") {
      setBothError("Both is Required");
    } else {
      setBothError("");
    }
  };
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    return (
      
    
      <>
      {loading && <Loader />}
    <section className="border-red-500 bg-gray-200 min-h-screen flex items-center justify-center" style={{backgroundColor:'#F1F5F9'}}>
        {/* max-w-3xl */}
        <div className="bg-gray-100 flex flex-col md:flex-row shadow-lg">
  <div className="md:w-1/2 flex justify-center items-center">
  {/* className="max-w-md" for login page image */  }
    <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/login_img.png`}  alt="logo" />
  </div>
  <div className="md:w-1/2 px-5 p-10 md:p-20">
    <h2 className="text-2xl font-bold text-[#000] text-left">Login</h2>
    <p className="text-sm mt-1 text-[#8F8F8F] text-left">Enter your username and password to log in.</p><br/>
    {errorMessage && (
        <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
      )}
    <form className="mt-6" onSubmit={handleLogin}>
       {botherror == "" ? (
                  ""
                ) : (
                  <div className="form-group form-row d-flex justify-content-center">
                  <div className="col-10">
                  <div className="alert alert-danger mx-0" style={{width:'100%',textAlign:'center'}} role="alert">
                    Validation Error.
                  </div>
                  </div>
                  </div>
                )} 
      <div>
        <label className="block text-[#243465] text-left">Username</label>
        <input type="text" name="email" placeholder="Enter Username" className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none" 
        value={usr}
        onChange={(e) => {
          setEmail(e.target.value)
        }}
        autofocus autoComplete required />
        {erroremail !== '' ? <span style={{position: 'absolute',right: '12px',top: '10px'}}><MdErrorOutline color="red" /></span> : ''}
                
      </div>
      <div className="mt-4">
        <label className="block text-[#243465] text-left">Password</label>
        <div className="relative">
          <input
            type={showPassword ? 'text' : 'password'}
            name="password"
            value={pwd}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            placeholder="Enter Password"
            minLength="6"
            className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
            required
          />
          <FontAwesomeIcon
            icon={showPassword ? faEye : faEyeSlash}
            onClick={togglePasswordVisibility}
            className="absolute top-0 right-0 mt-6 mr-4 text-gray-400 cursor-pointer"
          />
        </div>
         {errorpassword !== '' ? <span style={{position: 'absolute',right: '12px',top: '10px'}}><MdErrorOutline color="red" /></span> : ''}
                
      </div>
      <div className="text-right mt-2">
        <a href="#" className="text-sm font-semibold text-[#EC642A] hover:text-blue-700 focus:text-blue-700">Forgot Password?</a>
      </div>
      <button type="submit" className="w-full block bg-[#270B79] hover:bg-blue-400 focus:bg-blue-400 text-white font-semibold rounded-lg px-4 py-3 mt-6">Login</button>
      {/* <a href="javascript:void(0);" onClick={() => {
        nvg("/dashboard");
      }} className="w-full block bg-[#270B79] hover:bg-blue-400 focus:bg-blue-400 text-white font-semibold rounded-lg px-4 py-3 mt-6">Login</a> */}
    </form>
  </div>
</div>

  </section>
  </>
    )
};

export default Home;