import React, { useState } from 'react';
import Layout from '../components/Layout';

const Payment = () => {
    return (
        <Layout>
            {/* md:max-w-6xl */}
          <div className="md:mx-auto px-4 py-4" style={{backgroundColor: '#F1F5F9'}}>
                <div class="flex items-center justify-between mb-4">
                <h2 class="text-xl font-bold text-gray-800">Payment</h2>
              
              
              </div>
          </div>
        </Layout>
    );
};

export default Payment;