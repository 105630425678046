import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import Layout from '../components/Layout';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; 
import { getToken, removeToken, storeToken } from "../services/LocalStorageSrv";
import axios from "axios";
// import Select from "react-select";
import Select, { components } from 'react-select';
import Loader from "../Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddSlotsModal from '../Pages/AddSlotsModal';
import AddPaymentDetailModal from '../Pages/AddPaymentDetailModal';
const NewAppointment = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const tokendata = getToken("data");
  const nvg = useNavigate();
  const [loading, setLoading] = useState(true);
	const [activeTab, setActiveTab] = useState('tab1');
  

	const handleTabClick = (tab) => {
	  setActiveTab(tab);
	};
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [isOpen1, setIsOpen1] = useState(false);

  const openModal1 = () => {
    setIsOpen1(true);
  };

  const closeModal1 = () => {
    setIsOpen1(false);
  };
  const [doctorDetails, setDoctorDetails] = useState([]);
  const [patientDetails, setPatientDetails] = useState([]);
  const [appointmentType, setAppointmentType] = useState([]);
  const [clinicalProcedureDetails, setClinicalProcedureDetails] = useState([]);
  const [therapyPlanDetails, setTherapyPlanDetails] = useState([]);
  const [doctor, setdoctor] = useState("");
  const [patient, setpatient] = useState("");
  const [appointment, setappointment] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [selectedDoctorDetail, setSelectedDoctorDetail] = useState({ name: '', department: '' }); 
  const [selectedPatient, setSelectedPatient] = useState('');
  const [selectedPatientDetail, setSelectedPatientDetail] = useState({ sex: '', age: '',patient_id: '',mobile:'',email:'',name:'' }); 
  const [selectedAppointment, setSelectedAppointment] = useState('');
  const [selectedAppointmentDetail, setSelectedAppointmentDetail] = useState('0'); 
  const [patiendIds,setPatiendIds] = useState('');
  const [paymentModeDetails, setPaymentModeDetails] = useState([]);
  const [medicalDepartmentDetails, setMedicalDepartmentDetails] = useState([]);

  const [selectedValue, setSelectedValue] = useState('');
  const [selectedValue1, setSelectedValue1] = useState('');
  const [selectedValue2, setSelectedValue2] = useState('');
  const [selectedValue3, setSelectedValue3] = useState('');
  const [selectedValue4, setSelectedValue4] = useState('');
  const [selectedValue5, setSelectedValue5] = useState('');
  const [selectedValue6, setSelectedValue6] = useState('');
// console.log('ty:',selectedValue6);
  const [selectedValue7, setSelectedValue7] = useState('');
  const [selectedValue8, setSelectedValue8] = useState('');
  const [selectedValue9, setSelectedValue9] = useState('');
  // const handleChangeDoctor = (selectedOption) => {
  //   // const doctorId = selectedOption.target.value;
  //   console.log('d id:',selectedOption.target.value);
  //   setdoctor(selectedOption);
  // };
// for slote modal

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [departmentModalValue, setDepartmentModalValue] = useState('');
  const [doctorModalValue, setDoctorModalValue] = useState('');
  const handleOpenAvailability = (e) => {
    // alert(selectedValue2);
    setDepartmentModalValue(selectedValue1);
    setDoctorModalValue(selectedValue2);
    setIsModalOpen(true); // Open modal when a value is selected
  };

  const closeModalPop = () => {
    setIsModalOpen(false);
  };
  
  
  // Handle the change event
  const handleSexChange = (e) => {
    setSelectedSex(e.target.value);
    // Additional logic for handling sex change
  };
  const [selectedTime, setSelectedTime] = useState(null);
  const [dsetDatewiseTimeSlotPractitionerName, setDatewiseTimeSlotPractitionerName] = useState('');
  const [dsetDatewiseTimeSlotScheduleName, setDatewiseTimeSlotScheduleName] = useState('');
  const [datewiseTimeSlotDetails, setDatewiseTimeSlotDetails] = useState([]);
  const [datewiseTimeSlotstatusDetails, setDatewiseTimeSlotstatusDetails] = useState([]);
  const [datewiseServiceUnit, setDatewiseServiceUnit] = useState('');
  const [appointmentDate, setAppointmentDate] = useState('');
  const [differChargesValuePrice, setdifferChargesValuePrice] = useState('');
  const [differChargesPrice, setDifferChargesPrice] = useState('');
  const [departmentValueForDoctor, setDepartmentValueForDoctor] = useState('');
  const [patientWiseDetails, setPatientWiseDetails] = useState(''); 
  const [selectedSex, setSelectedSex] = useState(patientWiseDetails.sex || '');
  const handleChangeWithTimeSlote = (app_date) => {
    // alert(app_date);
    fetchDatewiseTimeSlotData(app_date);

  };
  const fetchDatewiseTimeSlotData = async (appointmentDate) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.practitioner_schedule`,
        {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            practitioner_id: doctorModalValue ? doctorModalValue : '',
            slot_date:appointmentDate ? appointmentDate : ''
          }
        }
      );


      if (response.data && response.data.data) {
        // console.log('service unit:', response.data.data.slot_details[0].service_unit);
        setDatewiseTimeSlotPractitionerName(response.data.data.practitioner_name);
        setDatewiseTimeSlotScheduleName(response.data.data.schedules[0].schedule_name);
        setDatewiseTimeSlotDetails(response.data.data.schedules[0].time_slots);
        setDatewiseTimeSlotstatusDetails(response.data.data.slot_details[0].appointments);  
        setDatewiseServiceUnit(response.data.data.slot_details[0].service_unit);
        
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const handlePatientWiseData = (patient_id) => {
    // alert(patient_id);
    fetchPatientWiseData(patient_id);

  };
  const fetchPatientWiseData = async (patient_id) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_patient_details_edit`,
        {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            patient_id: patient_id ? patient_id : ''
          }
        }
      );


      if (response.data && response.data.data) {
        // console.log('patient details all:', response.data.data.patient_list[0]);
        setPatientWiseDetails(response.data.data.patient_list[0]);
        
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  //const times = ['09:00 AM', '09:30 AM', '10:00 AM'];
  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <span className="text-sm font-mulish">{props.data.label}</span>
      </components.Option>
    );
  };
  
  const NewDepartmentButton = () => (
    <button
      className="text-sm text-blue-500 hover:underline mt-2 p-2 w-full text-left"
      onClick={() => alert('Add new department logic here')}
    >
      + New Department
    </button>
  );
  
  const CustomMenuList = (props) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        <NewDepartmentButton />
      </components.MenuList>
    );
  };
// for end slote modal
  const handleChangeDoctor = (selectedOption) => {
    // const doctorId = selectedOption.value;
    // console.log('Selected Doctor ID:', selectedOption);
    setdoctor(selectedOption);
    setSelectedDoctor(selectedOption);
    // fetchSelectedDoctorDetails(selectedOption);
    fetchDifferChargesDoctor(selectedOption);

  };
  const handleChangeDoctorByDepartment = (e) => {
    // alert(selectedValue1);
    // console.log('d:',e);
    // setDepartmentValueForDoctor(selectedValue1);
    if(selectedValue1 != "")
    {
      // setSelectedValue2("");
      fetchAppointmentDoctorByDepartment(e);
    }else{
      setSelectedValue2("");
    }

  };
  const handleDifferCharges = (selectv) => {
    // console.log('Selected Differ Charges:', selectv);
    if(selectv === '')
    {
      setdifferChargesValuePrice('');
      fetchDifferChargesDoctorPrice('');
    }else{
      setdifferChargesValuePrice(selectv);
      fetchDifferChargesDoctorPrice(selectv);
    }
    

  };

  const doctorOptions = doctorDetails.map((doctor) => ({
    value: doctor.id,
    label: `${doctor.id} - ${doctor.name}`,
  }));
  const medicalDepartmentList = medicalDepartmentDetails.map((mdlist) => ({
    value: mdlist.department,
    label: `${mdlist.department}`,
  }));
  // const handleChangePatient = (selectedOption) => {
  //   setpatient(selectedOption);
  // };
  const handleChangePatient = (selectedOption) => {
    const patientId = selectedOption.value;
    setpatient(selectedOption);
    setSelectedPatient(patientId);
    fetchSelectedPatientDetails(patientId);

  };
  const patientOptions = patientDetails.map((patient) => ({
    value: patient.patient_id,
    label: `${patient.patient_id ? patient.patient_id : '' } - ${patient.name ? patient.name : ''}`,
  }));
  // -${patient.mobile ? patient.mobile : ''}
  const handleChangeDuration = (selectedOption) => {
    const appointmentId = selectedOption.target.value;
    if(appointmentId === ''){
      setappointment('');
      setSelectedAppointment('');
      fetchSelectedAppointmentDetails('');
    }else{
    setappointment(selectedOption);
    setSelectedAppointment(appointmentId);
    // console.log('apoint id:',appointmentId);  
    fetchSelectedAppointmentDetails(appointmentId);
    }

  };
  const [discountPriceEnter, setDiscountPriceEnter] = useState('');
  const [totalPendingAmountAuto, setTotalPendingAmountAuto] = useState('');
  const [totalAmountAuto, setTotalAmountAuto] = useState('');
  const [dob, setdob] = useState("");
  const [birthdate, setBirthdate] = useState('');
  const [age, setAge] = useState('');

  const handleBirthdateChange = (event) => {
    setBirthdate(event.target.value);
  };

  const handleAgeCalculation = (event) => {
    const selectedDate = event.target.value;
    const calculatedAge = calculateAge(selectedDate);
    setAge(calculatedAge);
  };
  const calculateAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
  
    // Adjust age if the current date hasn't reached the birthdate this year yet
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age;
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // months are zero-indexed
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const handleDiscountAmount = (amountValue) => {
    // alert(differChargesPrice);
    // Check if the input is empty
    if (amountValue === '') {
      alert('Discount amount cannot be empty.');
      return;
    }
  
    // Check if the discount type is selected
    if (selectedValue5 === '') {
      alert('Please select Discount Type first.');
      setDiscountPriceEnter('');
    } else {
      setDiscountPriceEnter(amountValue);
      // alert(`Discount Amount: ${amountValue}`);
      // alert(`Discount Type: ${selectedValue5}`);
      // alert(`Actual amount: ${differChargesPrice}`);
      let totalA;
  
      if (selectedValue5 === "Fixed") {
        // Calculate total amount for Fixed discount type
        totalA = differChargesPrice - amountValue;
      } else if (selectedValue5 === "Percentage") {
        // Calculate total amount for Percentage discount type
        totalA = differChargesPrice - (differChargesPrice * (amountValue / 100));
      }
  
      // Set the calculated total amount
      setTotalAmountAuto(totalA);
    }
  };
  
  const handleTotalPendingAmount = (amountValue) => {
      //  alert(totalAmountAuto);
       let totalP;
        totalP = totalAmountAuto - amountValue;
      
        setTotalPendingAmountAuto(totalP);
  };

  const [billingtypeValue, setbillingtypeValue] = useState("Appointment");
  const [selectedBillingType, setSelectedBillingType] = useState("");
  const [differChargesDetails, setDifferChargesDetails] = useState([]);
  const [doctorDetailsByDepartment, setDoctorDetailsByDepartment] = useState([]);
  const handleChangeBillingType = (selectedOption) => {
    const billingId = selectedOption.target.value;
    if(billingId !== '')
    {
      // console.log('biling typ:',billingId);
      setbillingtypeValue(billingId);
    }else{
      setbillingtypeValue('');
    }

  };
  
  const fetchDifferChargesDoctor = async (selectedValue2) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_differ_charges_list`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            parent: selectedValue2 ? selectedValue2 : '',
            practitioner_time:''
          }
        }
      );


      if (response.data && response.data.data) {
        // console.log('Differ Charges:', response.data.data.differ_charge);
        setDifferChargesDetails(response.data.data.differ_charge);
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const fetchDifferChargesDoctorPrice = async (differChargesValuePrice) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_differ_charges_list`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            parent: selectedValue2 ? selectedValue2 : '',
            practitioner_time: differChargesValuePrice ? differChargesValuePrice : ''
          }
        }
      );


      if (response.data && response.data.data) {
        // console.log('Differ Charges price:', response.data.data.differ_charge[0].charges);
        setDifferChargesPrice(response.data.data.differ_charge[0].charges);
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const fetchAppointmentDoctor = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_doctor_list_on_appointment`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            Practitioner_id: '', // If needed, use params instead of a second object
          }
        }
      );


      if (response.data && response.data.data) {
        // console.log('doctor:', response.data.data);
        setDoctorDetails(response.data.data);
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const fetchAppointmentDoctorByDepartment = async (selectedValue1) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_doctor_list_on_appointment`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            Practitioner_id: '', // If needed, use params instead of a second object
            department: selectedValue1 ? selectedValue1 : ''
          }
        }
      );


      if (response.data && response.data.data) {
        // console.log('doctor by department:', response.data.data);
        setDoctorDetailsByDepartment(response.data.data);
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const fetchPatientData = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_patient_list_Appontment`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            patient_id: '', // If needed, use params instead of a second object
          }
        }
      );

      if (response.data && response.data.data) {
        // console.log('patient:',response.data.data);
        setPatientDetails(response.data.data);
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const fetchAppointmentType = async () => {
    try {
      setLoading(true);
  
      const response = await axios.get(`${apiUrl}/healthcare_management_system.web.api.get_appointment_type_list`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${tokendata.data.api_key.trim()}:${tokendata.data.api_secret.trim()}`,
          },
          params: {
            appointment_id: '', // If needed, use params instead of a second object
          }
        }

      );
  
      // console.log('API response:', response);
  
      if (response.data && response.data.data) {
        // console.log('Appointment type data:', response.data.data.app_type_list);
        setAppointmentType(response.data.data.app_type_list);
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error("Error fetching data:", error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchSelectedDoctorDetails = async (doctorId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_doctor_list_on_appointment`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            Practitioner_id: doctorId,
          }
        }
      );
  
  
      if (response.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
        const doctorDetails = response.data.data[0];
        // console.log('Doctor Details:', doctorDetails);
        setSelectedDoctorDetail({
          name: doctorDetails.name,
          department: doctorDetails.department,
        });
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchSelectedPatientDetails = async (patientId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_patient_list_Appontment`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            patient_id: patientId,
          }
        }
      );
  
  
      if (response.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
        const patientDetails = response.data.data[0];
        // console.log('Patient Details:', patientDetails);
        setSelectedPatientDetail({
          sex: patientDetails.sex,
          age: patientDetails.age,
          patient_id:patientDetails.patient_id,
          mobile:patientDetails.mobile,
          email:patientDetails.email,
          name:patientDetails.name,
        });
        setPatiendIds(patientDetails.patient_id);
        
        fetchTherapyPlan(patientDetails.patient_id);
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSelectedAppointmentDetails = async (appointmentId) => {
    // console.log('appointId:', appointmentId);
    try {
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_appointment_type_list`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            appointment_id: appointmentId,
          }
        }
      );
  
  
      if (
        response.data &&
        response.data.data &&
        response.data.data.app_type_list &&
        response.data.data.app_type_list.length > 0
      ) {
        // console.log('duration n:', response.data.data);
        const appointmentDetails = response.data.data.app_type_list[0]; // Access the first item in the array
        const defaultDuration = appointmentDetails.default_duration; // Extract default_duration
        // console.log('duration:', defaultDuration);
        setSelectedAppointmentDetail(defaultDuration);
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchPaymentModeData = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_mode_of_payment_list`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
        }
      );

      if (response.data && response.data.data) {
        // console.log('payment mode:',response.data.data.mode_list);
        setPaymentModeDetails(response.data.data.mode_list);
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  
  const fetchClinicalProcedure = async () => {
    // console.log('text:',selectedPatientDetail.patient_id);
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_clinical_procedure_temp_list`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            patient_id: patiendIds
            , // If needed, use params instead of a second object
          }
        }
      );


      if (response.data && response.data.data) {
        // console.log('Clinical Procedure:', response.data.data.procedure_template_list);
        // console.log('Therapy Plan:', response.data.data.patient);
        setClinicalProcedureDetails(response.data.data.procedure_template_list);
        setTherapyPlanDetails(response.data.data.therapy_plan);
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchTherapyPlan = async (patiendIds) => {
    // console.log('text:',selectedPatientDetail.patient_id);
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_clinical_procedure_temp_list`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            patient_id: patiendIds
            , // If needed, use params instead of a second object
          }
        }
      );


      if (response.data && response.data.data) {
        // console.log('Therapy Plan:', response.data.data.patient);
        setTherapyPlanDetails(response.data.data.therapy_plan);
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const fetchMedicalDepartmentList = async () => {
    try {
      setLoading(true);
      
      // Check if apiUrl and tokendata are defined
      if (!apiUrl || !tokendata || !tokendata.data) {
        // console.error('API URL or token data is not defined');
        setLoading(false);
        return;
      }
      
      const response = await axios.post(
        `${apiUrl}/healthcare_management_system.web.api.get_medical_department_list`,
        {}, // Empty body
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
        }
      );
  
      if (response.data && response.data.data) {
        // console.log('Response Data:', response.data.data);
        setMedicalDepartmentDetails(response.data.data.department_list);
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }




    
  };
 
    
  useEffect(() => {
    fetchAppointmentDoctor();
    fetchPatientData();
    fetchAppointmentType();
    // fetchSelectedDoctorDetails();
    // fetchSelectedPatientDetails();
    // fetchSelectedAppointmentDetails();
    fetchClinicalProcedure();
    // fetchTherapyPlan(patiendIds);
    fetchPaymentModeData();
    fetchMedicalDepartmentList();
    fetchDifferChargesDoctor();
  }, []);
  const [rows, setRows] = useState([
    { testName: '', salePrice: '₹00.00', discountType: '', discountAmount: '₹00.00', totalAmount: '₹00.00', specialInstruction: 'Test' },
  ]);

  const addRow = () => {
    setRows([
      ...rows,
      { testName: '', salePrice: '₹00.00', discountType: '', discountAmount: '₹00.00', totalAmount: '₹00.00', specialInstruction: 'Test' },
    ]);
  };

  const removeRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };


  // Book now button code
  const [bookingDetail, setBookingDetail] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedBloodGroup, setSelectedBloodGroup] = useState("");
  const [bookdate, setbookdate] = useState("");
  const [pname, setpname] = useState("");
  const [street, setstreet] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [pincode, setpincode] = useState("");
  const [country, setcountry] = useState("");
  const [mobilenumber, setmobilenumber] = useState("");
  const [aadharcard, setaadharcard] = useState("");
  const [careof, setcareof] = useState("");
  const [relation, setrelation] = useState("");

  const [errorpname, seterrorpname] = useState("");
  const [errorgender, seterrorgender] = useState("");
  const [errorbloodgroup, seterrorbloodgroup] = useState("");
  const [errordob, seterrordob] = useState("");
  const [errorstreet, seterrorstreet] = useState("");
  const [errorcity, seterrorcity] = useState("");
  const [errorstate, seterrorstate] = useState("");
  const [errorpincode, seterrorpincode] = useState("");
  const [errorcountry, seterrorcountry] = useState("");
  const [errormobilenumber, seterrormobilenumber] = useState("");
  const [erroraadharcard, seterroraadharcard] = useState("");
  const [errorcareof, seterrorcareof] = useState("");
  const [errorrelation, seterrorrelation] = useState("");

  const [errorbookdate, seterrorbookdate] = useState("");
  const [errorappointmenttype, seterrorappointmenttype] = useState("");
 
  const [errordoctorname, seterrordoctorname] = useState("");

  const [referredByValue, setReferredByValue] = useState("");
  const [amountPaidValue, setAmountPaidValue] = useState("");
  const [discount_amount_value, setDiscount_amount_value] = useState("0");
  const [discount_percentage_value, setDiscount_percentage_value] = useState("0");
  const booknowsubmit = async () => {
    // alert(referredByValue);
    
// if(selectedValue5 === "Fixed")
// {
//   // alert(selectedValue5);
//   // alert(discountPriceEnter);
//   setDiscount_amount_value(discountPriceEnter);
// }else if(selectedValue5 === "Percentage")
// {
//   // alert(selectedValue5);
//   // alert(discountPriceEnter);
//   setDiscount_percentage_value(discountPriceEnter);
// }
  // alert(discount_amount_value);
  // alert(discount_percentage_value);
    if (
      // bookdate !== "" &&
      selectedValue6 !== "" &&
      pname !== "" &&
      dob !== "" &&
      selectedGender !== "" &&
      selectedValue2 !== "" 

    ) {
      try {
        setLoading(true);
        const formdata = {
          appointment_id:'',
            first_name: pname,
            sex: selectedGender,
            dob: dob,
            blood_group: selectedBloodGroup,
            mobile: mobilenumber,
            address_line1: street,
            address_line2: '',
            city: city,
            county: country,
            state: state,
            pincode: pincode,
            mobile: mobilenumber,
            appointment_type:selectedValue6,
            company: 'Stackerbee',
            appointment_date: '',
            appointment_for:"Practitioner",
            practitioner:selectedValue2,
            patient:'',
            duration: selectedAppointmentDetail ? selectedAppointmentDetail : 0,
            status:"Open",
            custom_discount_type:selectedValue5,
            custom_discount_amount: selectedValue5 === "Fixed" ? (discountPriceEnter ? discountPriceEnter : '0') : '0',
  custom_discount_percentage: selectedValue5 !== "Fixed" ? (discountPriceEnter ? discountPriceEnter : '0') : '0',
            mode_of_payment: "Cash",
            paid_amount: amountPaidValue ? amountPaidValue : '0',
            custom_amount_pending:totalPendingAmountAuto ? totalPendingAmountAuto : '0',
            invoiced: 0,
            custom_reffered:referredByValue,
            notes:'',
            custom_total_amount:totalAmountAuto,
            custom_actual_amount:differChargesPrice,
            custom_differ_charges:selectedValue3,  //Evening
            appointment_time:"",
            service_unit:'',
            book_now:1
          
        };
        const response = await axios.post(
          `${apiUrl}/healthcare_management_system.web.api.create_patient_appointment`,formdata,
          {
            headers: {
              "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
            },
          }
        );
    
        if (response.data.message = "Patient Appointment created successfully") {
          //alert('ok');
          // console.log('booking d:',response.data.data);
          setIsBookingModalOpen(true);
          setBookingDetail(response.data.data);
         
        }
        setLoading(false);
       
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    } else {

      if (selectedValue6 == "") {
        seterrorappointmenttype("Appointment Type is Required");
      } else {
        seterrorappointmenttype("");
      }
      if (selectedValue2 == "") {
        seterrordoctorname(" Consultant Name is Required");
      } else {
        seterrordoctorname("");
      }
      if (bookdate == "") {
        seterrorbookdate("Date is Required");
      } else {
        seterrorbookdate("");
      }
      if (pname == "") {
        seterrorpname("Patient is Required");
      } else {
        seterrorpname("");
      }
      if (dob == "") {
        seterrordob("dob is Required");
      } else {
        seterrordob("");
      }
      if (selectedGender == "") {
        seterrorgender("Gender is Required");
      } else {
        seterrorgender("");
      }
     

    }

  };
  // End Book now button code

  const [timeSlotError, setTimeSlotError] = useState(''); // For error message
  const handleTimeSlotSelection = (time) => {
    setSelectedTime(time);
    setTimeSlotError(''); // Clear error when a slot is selected
  };
  // start solte time save booking
  const handleSloteBookingSave = async () => {
    // alert(street)
    // alert(city)
    // alert(state)
    // alert(pincode)
    // alert(country)
    // alert('ok') 
    // console.log('Selected Time:', selectedTime);
    // if(selectedValue5 === "Fixed")
    //   {
    //     setDiscount_amount_value(discountPriceEnter);
    //   }else if(selectedValue5 === "Percentage")
    //   {
    //     setDiscount_percentage_value(discountPriceEnter);
    //   }
      if (!selectedTime) {
        setTimeSlotError('Please select a time slot');
        return;
      }
    // Check if all required fields are filled
  //  alert(appointmentDate)
      try {
        setLoading(true);
        
        const formdata = {
          appointment_id: '',
          first_name: pname,
          sex: selectedGender,
          dob: dob,
          blood_group: selectedBloodGroup,
          mobile: mobilenumber,
          address_line1: street,
          address_line2: '',
          city: city,
          county: country,
          state: state,
          pincode: pincode,
          mobile: mobilenumber,
          appointment_type: selectedValue6,
          company: 'Stackerbee',
          appointment_date: appointmentDate,
          appointment_for: "Practitioner",
          practitioner:selectedValue2,
          patient: pname,
          duration: selectedAppointmentDetail ? selectedAppointmentDetail : 0,
          status:"Open",
          custom_discount_type:selectedValue5,
          custom_discount_amount: selectedValue5 === "Fixed" ? (discountPriceEnter ? discountPriceEnter : '0') : '0',
  custom_discount_percentage: selectedValue5 !== "Fixed" ? (discountPriceEnter ? discountPriceEnter : '0') : '0',
          mode_of_payment: "Cash",
          paid_amount: amountPaidValue,
          custom_amount_pending:totalPendingAmountAuto ? totalPendingAmountAuto : '0',
          invoiced: 0,
          custom_reffered:referredByValue,
          notes:"",
          custom_total_amount:totalAmountAuto,
          custom_actual_amount:differChargesPrice,
          custom_differ_charges:selectedValue3,  //Evening
          appointment_time: selectedTime || "",  // Use selectedTime if available
          service_unit: datewiseServiceUnit ? datewiseServiceUnit : '',
          book_now: 0
        };
  
        const response = await axios.post(
          `${apiUrl}/healthcare_management_system.web.api.create_patient_appointment`,
          formdata,
          {
            headers: {
              "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
            },
          }
        );
  
        if (response.data.message === "Patient Appointment created successfully") {
          // console.log('booking d:', response.data.data);
          setIsBookingModalOpen(true);
          setBookingDetail(response.data.data);
        }
  
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    
  };
  

  // end solte time save booking

  // text-[#EC642A] text-[12px] p-[20px] g-[10px] font-inter text-xs font-normal leading-extra-tight
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.value === "create-new-patient" ? "#EC642A" : state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected
        ? state.data.value === "create-new-patient"
          ? "#212529"
          : "#2684FF"
        : state.isFocused
        ? "#F0F0F0"
        : null,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.value === "create-new-patient" ? "#EC642A" : "#000",
    }),
  };
  
  const noOptionsMessage = () => (
    <div className="text-center">
      No options found. <br />
      <span className="text-[#EC642A] cursor-pointer" onClick={() => nvg("/new-patient")}>Create New Patient</span>
    </div>
  );

  const customStylesD = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      borderRadius: '4px',
      borderColor: '#656565',
      padding: '2.5px',
      width: '100%',
      fontSize: '0.875rem', // text-sm
      textAlign:'left'
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.data.color ? state.data.color : state.isSelected ? '#2B2F32' : '#2B2F32',
      backgroundColor: state.isSelected ? '#F0F0F0' : '#FFFFFF',
      textAlign: 'left', // Align text to the left
      '&:hover': {
        backgroundColor: '#f0f0f0', // Light gray hover color
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      textAlign: 'left', // Align placeholder to the left
    }),
  };
  
  const options = [
    ...medicalDepartmentDetails.map((sts) => ({
      value: sts.department,
      label: sts.department,
    })),
    { value: 'create-new-department', label: '+ Create Department', color: '#EC642A' },
  ];
  
  const customStyles1 = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      borderRadius: '4px',
      borderColor: '#656565',
      height: '43px',
      fontSize: '0.875rem', // text-sm
      textAlign: 'left',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.data.color ? state.data.color : state.isSelected ? '#2B2F32' : '#2B2F32',
      backgroundColor: state.isSelected ? '#F0F0F0' : '#FFFFFF',
      textAlign: 'left', // Align text to the left
      '&:hover': {
        backgroundColor: '#f0f0f0', // Light gray hover color
      },
    }),
  };

  const options1 = [
    ...doctorDetailsByDepartment.map((sts) => ({
      value: sts.id,
      label: `${sts.id}    ${sts.name}`,
    })),
    { value: 'create-new-doctor', label: '+ Create New Doctor', color: '#EC642A' },
  ];


 

    return (
        <Layout>
           {loading ? <Loader /> : null}
            {/* md:max-w-6xl */}
  <div className="md:mx-auto px-4 py-4" style={{backgroundColor: '#F1F5F9',height:'100%'}} >
    <div className="flex items-center justify-between mb-4">
                    <h2 className="font-small text-[#8F8F8F]"><a  href="javascript:void(0);"
                      onClick={() => {
                        nvg("/appointment");
                      }} className="no-underline hover:underline">Billing List</a> &gt; New Billing</h2>

 
    </div>

<div className="border-[1px] border-[#E2E8F0] bg-[#FFFFFF]">
   
 <div className="px-[18px] pt-[15px] bg-[#FFFFFF] pb-[1px] border-[1px] border-[#FFFFFF] rounded-[2px] shadow-[0px_8px_13px_-3px_rgba(0,0,0,0.07)]">
	 
	<div className="grid gap-2 mb-6 md:grid-cols-3">
    {/* <div>
          <label for="series" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Date</label>
          <input type="date" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Date" 
          style={{border:errorbookdate !== '' ? '1px solid red' : ''}}
          value={bookdate}
                  onChange={(e) => {
                    setbookdate(e.target.value);
                  }}
          />
    </div> */}
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Patient Name</label>
          {/* <Select
   value={patientOptions.find(option => option.value === pname) || null}
  onChange={(selectedOption) => {
    
    const selectedValue = selectedOption.value;
    handlePatientWiseData(selectedValue);
    setpname(selectedValue);

    if (selectedValue === "create-new-patient") {
      nvg("/new-patient");
    }
  }}
  options={patientOptions}
  placeholder="Select Patient"
  className={`bg-[#FFFFFF] rounded-[4px] border text-left h-[43px] border-[#656565] text-sm block w-full p-2.5 
    ${errorpname !== '' ? 'border-red-500' : 'focus:border-black'}
    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-black`}
  styles={customStyles}
  noOptionsMessage={noOptionsMessage}
  required
/> */}


          <input type="text" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Patient Name"   value={pname}
                  onChange={(e) => {
                    setpname(e.target.value);
                  }} 
                  style={{
                    border: errorpname ? '1px solid red' : '',  // Apply red border if error
                  }} 
                  />
    </div>
    <div>
        <label for="appointment_type" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Patient UHID</label>
        <input type="text" className="bg-[#F2F4F5] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Patient UHID" value="STA-PAT-2024-09-06-8989890018" disabled/>
    </div>

    <div>
          <label for="series" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Date of Birth</label>
          <input type="Date" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Date of Birth" value={dob}

                  onChange={(e) => {
                    setdob(e.target.value);
                    handleBirthdateChange(e);
                    handleAgeCalculation(e);
                  }}
                  max={getCurrentDate()}
                  style={{border:errordob !== '' ? '1px solid red' : ''}}/>
    </div>
    <div>
        <label for="appointment_type" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Age</label>
        <input type="number" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Age"  value={age}
                  onChange={(e) => {
                    setAge(e.target.value);
                  }} readOnly/>
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Gender</label>
          
          <select
          value={selectedGender}
      onChange={(e) => {
        const value = e.target.value;
        setSelectedGender(value);
        if (value !== '') {
          seterrorgender("");
        }
      }}
      
      className={`bg-[#FFFFFF] rounded-[4px] border focus:outline-none border-[#656565] text-sm focus:ring-blue-500 focus:border-black block w-full p-2.5 
        ${selectedGender ? 'text-black' : 'text-[#ABAFB1]'} 
        dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-black`}
        style={{border:errorgender !== '' ? '1px solid red' : ''}}
        
    >
            <option value="">Select Gender</option>
            <option value="Male" className="text-[#2B2F32]">Male</option>
            <option value="Female" className="text-[#2B2F32]">Female</option>
            <option value="Other" className="text-[#2B2F32]">Other</option>
           

          </select>
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Blood Group</label>
          
          <select
      onChange={(e) => {
        const value = e.target.value;
        setSelectedBloodGroup(value);
      }}
      
      className={`bg-[#FFFFFF] rounded-[4px] focus:outline-none border border-[#656565] text-sm w-full p-2.5 
        ${selectedBloodGroup ? 'text-black border-[#656565]' : 'text-[#656565] border-[#656565]'} `}
        style={{
          border: errorbloodgroup ? '1px solid red' : '1px solid #656565',  // Apply red border if error
        }} 
    >
            <option value="">Select Blood Group</option>
            <option value="A Positive" className="text-[#2B2F32]">A Positive</option>
            <option value="A Negative" className="text-[#2B2F32]">A Negative</option>
            <option value="AB Positive" className="text-[#2B2F32]">AB Positive</option>
            <option value="AB Negative" className="text-[#2B2F32]">AB Negative</option>
            <option value="B Positive" className="text-[#2B2F32]">B Positive</option>
            <option value="B Negative" className="text-[#2B2F32]">B Negative</option>
            <option value="O Positive" className="text-[#2B2F32]">O Positive</option>
            <option value="O Negative" className="text-[#2B2F32]">O Negative</option>
           

          </select>
    </div>
    <div>
          <label for="series" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Street</label>
          <input type="text" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Street"   value={street}
                  onChange={(e) => {
                    setstreet(e.target.value);
                  }} style={{
                    border: errorstreet ? '1px solid red' : '',  // Apply red border if error
                  }}  />
    </div>
    <div>
        <label for="appointment_type" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">City</label>
        <input type="text" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="City"    value={city}
                  onChange={(e) => {
                    setcity(e.target.value);
                  }}   />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">State</label>
          <input type="text" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="State"    value={state}
                  onChange={(e) => {
                    setstate(e.target.value);
                  }}   />
    </div>

    <div>
          <label for="series" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Pin code</label>
          <input type="number" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Pin code"    value={pincode}
                  onChange={(e) => {
                    setpincode(e.target.value);
                  }}    />
    </div>
    <div>
        <label for="appointment_type" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Country</label>
        <input type="text" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Country"    value={country}
                  onChange={(e) => {
                    setcountry(e.target.value);
                  }}  />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Phone number</label>
          <input type="number" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Phone number"    value={mobilenumber}
                  onChange={(e) => {
                    setmobilenumber(e.target.value);
                  }}   />
    </div>

    <div>
          <label for="series" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Aadhar Card</label>
          <input type="number" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Aadhar Card"  value={aadharcard}
                  onChange={(e) => {
                    setaadharcard(e.target.value);
                  }}   />
    </div>
    <div>
        <label for="appointment_type" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Care Of</label>
        <input type="text" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Care Of"  value={careof}
                  onChange={(e) => {
                    setcareof(e.target.value);
                  }}   />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Relation</label>
          <input type="text" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Relation"  value={relation}
                  onChange={(e) => {
                    setrelation(e.target.value);
                  }}    />
    </div>

    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Billing Type</label>
          <select
      value={selectedValue}
      onChange={(e) => {
        const value = e.target.value;
        setSelectedValue(value);
        handleChangeBillingType(e); // Pass the event object if needed
      }}
      
      className={`bg-[#FFFFFF] rounded-[4px] focus:outline-none border border-[#656565] text-sm w-full p-2.5 
        ${selectedValue ? 'text-black border-[#656565]' : 'text-[#656565] border-[#656565]'} `}
    >
            {/* <option value="">Select Billing Type</option> */}
            <option value="Appointment" className="text-[#2B2F32]">Appointment</option>
            <option value="Procedure" className="text-[#2B2F32]">Procedure</option>
            <option value="Laboratory" className="text-[#2B2F32]">Laboratory</option>
            <option value="Radiology" className="text-[#2B2F32]">Radiology</option>
           

          </select>
    </div>



   
    
    
   

    </div>
</div>

<hr/>
{billingtypeValue === 'Appointment' ? (
  
<div className="px-[18px] pt-[8px] bg-[#FFFFFF] pb-[2px]">
  <div className="grid gap-2 mb-6 md:grid-cols-3">
  <div>
      <label
        htmlFor="company"
        className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]"
      >
        Department
      </label>
      <Select
     
        value={options.find(option => option.value === selectedValue1)}
        onChange={(selectedOption) => {
          const selectedValue = selectedOption.value;
          setSelectedValue1(selectedValue);
          handleChangeDoctorByDepartment(selectedValue);
          if (selectedValue === "create-new-department") {
            nvg("/patient");
          }
        }}
        options={options}
        placeholder="Select Department"
        styles={customStylesD}
        isSearchable
      />
    </div>



    <div>
      <label
        htmlFor="company"
        className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]"
      >
        Consultant Name
      </label>
      <Select
  value={options1.find(option => option.value === selectedValue2)}
  onChange={(selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : '';  // Check if option exists
    setSelectedValue2(selectedValue);
    handleChangeDoctor(selectedValue);

    // Clear validation error if value is selected
    if (selectedValue !== '') {
      seterrordoctorname("");
    }

    // Navigate if the "create-new-doctor" is selected
    if (selectedValue === "create-new-doctor") {
      nvg("/new-doctor");
    }
  }}
  options={options1}
  placeholder="Select First Department"
  styles={customStyles1}
  isSearchable
  className={`
    ${selectedValue2 ? 'text-black' : 'text-[#ABAFB1]'} 
    w-full
  `}
 
  style={{
    border: errordoctorname ? '1px solid red' : '1px solid #656565',  // Apply red border if error
  }} 
/>
{errordoctorname && (
  <p className="text-red-500 text-sm mt-1">{errordoctorname}</p> // Display error message if validation fails
)}



    </div>
   
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Differ Charges</label>
          <select
      value={selectedValue3}
      onChange={(e) => {setSelectedValue3(e.target.value);
        handleDifferCharges(e.target.value);
      }}
      className={`bg-[#FFFFFF] rounded-[4px] border focus:outline-none border-[#656565] text-sm focus:ring-blue-500 focus:border-black block w-full p-2.5 
        ${selectedValue3 ? 'text-black' : 'text-[#ABAFB1]'} 
        dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-black`}
    >
            <option value="">Select Differ Charges</option>
            {differChargesDetails.map((sts) => (
                        <option  className="text-[#2B2F32]" value={sts.practitioner_time} >
                                {`${sts.practitioner_time}`}
                              </option>
                            ))}
          

          </select>
    </div>

    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Appointment Type</label>
          <select
  value={selectedValue6}
  onChange={(e) => {
    const value = e.target.value;
    setSelectedValue6(value);
    handleChangeDuration(e); // Pass the event object if needed
    if (value !== '') {
      seterrorappointmenttype("");
    }
  }}
  className={`bg-[#FFFFFF] rounded-[4px] border focus:outline-none border-[#656565] text-sm focus:ring-blue-500 focus:border-black block w-full p-2.5 
    ${selectedValue6 ? 'text-black' : 'text-[#ABAFB1]'} 
    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-black`}
    // style={{border:errorappointmenttype !== '' ? '1px solid red' : ''}}
    style={{
      border: errorappointmenttype ? '1px solid red' : '1px solid #656565',  // Apply red border if error
    }}    
>
         
			<option value="">Select Appointment Type</option>
			{appointmentType.map((sts) => (
                        <option  className="text-[#2B2F32]" value={sts.appointment_type} >
                                {`${sts.appointment_type}`}
                              </option>
                            ))}

          </select>
          {/* {errorappointmenttype && (
  <p className="text-red-500 text-sm mt-1">{errorappointmenttype}</p> // Display error message if validation fails
)} */}
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Duration (In Minutes)</label>
          <input type="text" className="bg-[#F2F4F5] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Duration (In Minutes)" value={selectedAppointmentDetail ? selectedAppointmentDetail : '0'} disabled />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Referred by</label>
          <input type="text" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Referred by"
           value={referredByValue}
          onChange={(e) => {
            setReferredByValue(e.target.value);}}
           
          />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Actual Amount</label>
          <input type="text" className="bg-[#F2F4F5] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="₹00.00" value={differChargesPrice ? differChargesPrice : ''}
           min={1}
          />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Discount Type</label>
          <select
      value={selectedValue5}
      onChange={(e) => {
        setSelectedValue5(e.target.value);
      }}
      className={`bg-[#FFFFFF] rounded-[4px] border focus:outline-none border-[#656565] text-sm focus:ring-blue-500 focus:border-black block w-full p-2.5 
        ${selectedValue5 ? 'text-black' : 'text-[#ABAFB1]'} 
        dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-black`}
     
    >
            <option value="">Select Discount Type</option>
            <option value="Fixed" className="text-[#656565]">Fixed</option>
            <option value="Percentage" className="text-[#656565]">Percentage</option>

          </select>
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Discount Amount</label>
          <input type="text" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="₹00.00" 
          value={discountPriceEnter}
           onChange={(e) => {
            setDiscountPriceEnter(e.target.value);
              handleDiscountAmount(e.target.value);
           }}
           min={1} />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Total Amount</label>
          <input type="text" className="bg-[#F2F4F5] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="₹00.00" value={totalAmountAuto ? totalAmountAuto : ''}  disabled/>
    </div>

    
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Amount Paid</label>
          <input type="text" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="₹00.00" min={1} 
          value={amountPaidValue}
          onChange={(e) => {
            setAmountPaidValue(e.target.value);
            handleTotalPendingAmount(e.target.value);
           }}
          />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Amount Pending</label>
          <input type="text" className="bg-[#F2F4F5] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="₹00.00" disabled value={totalPendingAmountAuto ? totalPendingAmountAuto : ''}/>
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Amount Refund</label>
          <input type="text" className="bg-[#F2F4F5] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="₹00.00"  />
    </div>

    {/* <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">History of illness</label>
          <textarea type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black h-[100px] rounded-[4px] px-[16px] py-[8px] gap-[10px]" placeholder="History of illness"  />
    </div> */}




  </div>
</div>
):''}
{billingtypeValue === 'Laboratory' || billingtypeValue === 'Procedure'  || billingtypeValue === 'Radiology' ? (
  <>
<div className="px-[18px] pt-[8px] bg-[#FFFFFF] pb-[0px]">
  <div className="grid gap-2 mb-4 md:grid-cols-3">
     
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Prescribing Doctor</label>
         
     <select
      value={selectedValue7}
      onChange={(e) => {
        setSelectedValue7(e.target.value);
       
      }}
      className={`bg-[#FFFFFF] rounded-[4px] border focus:outline-none border-[#656565] text-sm focus:ring-blue-500 focus:border-black block w-full p-2.5 
        ${selectedValue7 ? 'text-black' : 'text-[#ABAFB1]'} 
        dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-black`}
     
    >
            <option value="">Select Consultant</option>
			{doctorDetails.map((sts) => (
                       <option  className="text-[#656565]" value={sts.id} key={sts.id}>
                       {sts.id} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {sts.name}
                     </option>
                    ))}
<option
    value="create-new-doctor"
    className="text-[#EC642A] text-[12px] p-[20px] g-[10px] font-inter text-xs font-normal leading-extra-tight"
   
  >
    + Create New Doctor 
  </option>
          </select>
          
    </div>
    <div>
      <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">
      Collection Center
        </label>
        <select
      value={selectedValue8}
      onChange={(e) => {
        setSelectedValue8(e.target.value);
       
      }}
      className={`bg-[#FFFFFF] rounded-[4px] border focus:outline-none border-[#656565] text-sm focus:ring-blue-500 focus:border-black block w-full p-2.5 
        ${selectedValue8 ? 'text-black' : 'text-[#ABAFB1]'} 
        dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-black`}
      
    >
       
  <option value="">Select Procedure Room</option>
 
    <option  className="text-[#656565]" value="101">
    101
    </option>
    <option  className="text-[#656565]" value="102">
    102
    </option>
 
</select>


    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Pathology Doctor</label>
         
     <select
      value={selectedValue9}
      onChange={(e) => {
        setSelectedValue9(e.target.value);
        if (e.target.value === "create-new-doctor") {
          nvg("/new-doctor");
        }
      }}
      className={`bg-[#FFFFFF] rounded-[4px] border focus:outline-none border-[#656565] text-sm focus:ring-blue-500 focus:border-black block w-full p-2.5 
        ${selectedValue9 ? 'text-black' : 'text-[#ABAFB1]'} 
        dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-black`}
     
    >
            <option value="">Select Procedure</option>
			{doctorDetails.map((sts) => (
                       <option  className="text-[#656565]" value={sts.id} key={sts.id}>
                       {sts.id} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {sts.name}
                     </option>
                    ))}
<option
    value="create-new-doctor"
    className="text-[#EC642A] text-[12px] p-[20px] g-[10px] font-inter text-xs font-normal leading-extra-tight"
   
  >
    + Create New Doctor 
  </option>
          </select>
          
    </div>
   </div>
</div>
<div className="px-[18px] pt-[0px] bg-[#FFFFFF] pb-[0px]"> 
 <div className="grid gap-2 mb-4 md:grid-cols-1">
  <div className="overflow-x-auto">
  <table className="min-w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-[#F2F4F5]">
            <th className="w-[250px] h-[40px] border border-[#CFD3D4] px-[16px] py-[10px] gap-[10px]">
              <div className="w-[73px] h-[17px] font-inter font-semibold text-[14px] leading-[16.94px] text-[#2E2E2E]">Test Name</div>
            </th>
            <th className="w-[145px] h-[40px] border border-[#CFD3D4] px-[16px] py-[10px] gap-[10px]">
              <div className="w-[73px] h-[17px] font-inter font-semibold text-[14px] leading-[16.94px] text-[#2E2E2E]">Sale Price</div>
            </th>
            <th className="w-[160px] h-[40px] border border-[#CFD3D4] px-[16px] py-[10px] gap-[10px]">
              <div className="w-[140px] h-[17px] font-inter font-semibold text-[14px] leading-[16.94px] text-[#2E2E2E]">Discount Type</div>
            </th>
            <th className="w-[145px] h-[40px] border border-[#CFD3D4] px-[16px] py-[10px] gap-[10px]">
              <div className="w-[140px] h-[17px] font-inter font-semibold text-[14px] leading-[16.94px] text-[#2E2E2E]">Discount Amount</div>
            </th>
            <th className="w-[145px] h-[40px] border border-[#CFD3D4] px-[16px] py-[10px] gap-[10px]">
              <div className="w-[140px] h-[17px] font-inter font-semibold text-[14px] leading-[16.94px] text-[#2E2E2E]">Total Amount</div>
            </th>
            <th className="w-[200px] h-[40px] border border-[#CFD3D4] px-[16px] py-[10px] gap-[10px]">
              <div className="w-[140px] h-[17px] font-inter font-semibold text-[14px] leading-[16.94px] text-[#2E2E2E]">Special Instruction</div>
            </th>
            <th className="w-[20px] h-[40px] border border-[#CFD3D4] px-[16px] py-[10px] gap-[10px]">
              <div className="w-[20px] h-[17px] font-inter font-semibold text-[14px] leading-[16.94px] text-[#2E2E2E]">
                <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/icons/Filter.png`} className="w-[15px] h-[15px]" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index} className="bg-[#FFFFFF]">
              <td className="border border-gray-300 text-[#999999]">
                <select className="w-full h-[40px] px-[8px] py-[6px]  focus:outline-none">
                  <option value="">{row.testName || 'Test Name'}</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
                {/* <div className="relative">
  <select className="appearance-none w-full px-4 py-2 pr-8 border border-gray-300 rounded">
    <option value="">Option 1</option>
    <option value="">Option 2</option>
    <option value="">Option 3</option>
  </select>
  <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
    <svg className="w-4 h-4 ml-2 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
    </svg>
  </div>
</div> */}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-[#999999]">{row.salePrice}</td>
              <td className="border border-gray-300 text-[#999999]">
                <select className="w-full h-[40px] px-[8px] py-[6px]  focus:outline-none">
                  <option value="" className="custom-option w-[160px] h-[35px] p-[10px] g-[50px]  text-[#2B2F32]">{row.discountType || 'Discount Type'}</option>
                  <option value="Fixed" className="custom-option w-[160px] h-[35px] p-[10px] g-[50px]  text-[#2B2F32]">Fixed</option>
                  <option value="Percentage" className="custom-option w-[160px] h-[35px] p-[10px] g-[50px]  text-[#2B2F32]">Percentage</option>
                </select>
              </td>
              {/* <td className="border border-gray-300 px-4 py-2 text-[#999999]">{row.discountAmount}</td> */}
              <td className="border border-gray-300 text-[#999999]"><input type="text" className="text-center w-[145px] h-[40px] p-[10px_16px] gap-0 border-r border-transparent focus:outline-none" placeholder={row.discountAmount}/></td>
              <td className="border border-gray-300 px-4 py-2 text-[#999999]">{row.totalAmount}</td>
              <td className="border border-gray-300 text-[#999999]"><input type="text" className="text-center w-[145px] h-[40px] p-[10px_16px] gap-0 border-r border-transparent focus:outline-none" placeholder={row.specialInstruction}/></td>
              
              <td className="border border-gray-300 px-4 py-2 text-gray-700">
                <img
                  src={`${process.env.REACT_APP_API_PUBLIC_URL}/icons/Delete.png`}
                  className="w-[15px] h-[18px] cursor-pointer"
                  onClick={() => removeRow(index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className="w-[200px] h-[44px] absolute rounded-[12px] p-[9px_9px] space-x-[10px] cursor-pointer text-left"
        onClick={addRow}
      >
        <span className="w-[69px] h-[19px] font-inter font-medium text-[16px] leading-[19.36px] text-center text-[#270B79]">+ Add Row</span>
      </div>
   </div>
  </div>
     
</div>
      
<div className="px-[18px] pt-[25px] bg-[#FFFFFF] pb-[0px]"> 
    <div className="grid gap-2 mb-4 md:grid-cols-3">
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Total Amount</label>
          <input type="text" className="bg-[#F2F4F5] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="₹00.00"  />
    </div>

    
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Amount Paid</label>
          <input type="text" className="bg-[#F2F4F5] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="₹00.00"  />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Amount Pending</label>
          <input type="text" className="bg-[#F2F4F5] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="₹00.00"  />
    </div>
    <div>
          <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Amount Refund</label>
          <input type="text" className="bg-[#F2F4F5] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="₹00.00"  />
    </div>






  </div>
</div>

</> 
):''}


<div className='px-[18px] pt-[12px] bg-[#F8F9FC] pb-[12px]'>
  <div className='flex items-center justify-between'>
      <h1 className='text-[#1C2434] font-Inter font-semibold text-16px'></h1>
      <div className="flex space-x-2 h-[44px] w-[370px]">
      <button
      onClick={booknowsubmit}
  className="w-[180px] h-[44px] text-white bg-[#64748B] text-sm rounded-[12px] flex items-center justify-center"
>
  <span className="text-[#FFFFFF] font-inter font-normal text-base leading-[19.36px] text-center">
  Book Now
  </span>
</button>
        
          <button
  className="w-[180px] h-[44px] text-white bg-[#F9895C] text-sm rounded-[12px] flex items-center justify-center"
  // onClick={openModal}
  onClick={handleOpenAvailability}
>
  <span className="text-[#FFFFFF] font-inter font-normal text-base leading-[19.36px] text-center">
    Check Availability
  </span>
</button>
          {/* <AddSlotsModal isOpen={isOpen} onClose={closeModal} />  */}
      </div>
  </div>
</div>


</div>
</div>
{/* start modal start         */}
{isModalOpen  && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div className="relative bg-white rounded-lg w-full max-w-4xl p-2 md:p-4">
              <div className='flex items-center justify-between'>
                <h5 className='text-[16px] text-[#1E1E1E] font-inter font-semibold text-base leading-19.36'>Available slots</h5>
                <button
                  className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                  onClick={closeModalPop} // Close button onClick handler
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
          
  <div className="px-[1px] pt-[4px] bg-[#F8F9FC] pb-[0px]">
   <div className="grid gap-2 mb-6 md:grid-cols-3">
    <div>
      <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px] font-[14px]">
      Department
        </label>
       
        <Select
      // options={medicalDepartmentList}
      value={medicalDepartmentList.find(option => option.value === departmentModalValue)}
      placeholder="Select Department"
      classNamePrefix="custom-select"
      // components={{ Option: CustomOption, MenuList: CustomMenuList }}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px] h-[42px]"
      // style={{marginLeft:'20px!important'}}
      required 
      disabled
    />
    </div>
    <div>
      <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px] font-[14px]">
      Consultant Name
        </label>
       
        <Select
       
      //  options={doctorOptions}
       value={doctorOptions.find(option => option.value === doctorModalValue)}
      placeholder="Select doctor"
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px] text-left h-[42px]"
      required 
      disabled
    />
    </div>
    <div>
      <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px] font-[14px]">
      Appointment Date  
        </label>
       
        {/* <input
  type="date"
  className="bg-gray-50 border border-gray-300 text-sm focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px] h-[42px]"
  required
  onChange={(e) => {
    setAppointmentDate(e.target.value);
    handleChangeWithTimeSlote(e.target.value);
  }}
  style={{ border: errorbookdate !== '' ? '1px solid red' : '' }}
  value={appointmentDate}
  min={new Date().toISOString().split("T")[0]}  // Disables back dates
/> */}
<input
  type="date"
  className="bg-gray-50 border border-gray-300 text-sm focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px] h-[42px]"
  required
  onChange={(e) => {
    const dateValue = e.target.value; // yyyy-mm-dd format from the date input
    setAppointmentDate(dateValue); // Store date in YYYY-MM-DD format

    // Convert date to DD-MM-YYYY format for further use if needed
    const [year, month, day] = dateValue.split('-');
    const formattedDate = `${day}-${month}-${year}`;
    handleChangeWithTimeSlote(formattedDate); // Pass the formatted date in DD-MM-YYYY format
  }}
  style={{ border: errorbookdate !== '' ? '1px solid red' : '' }}
  value={appointmentDate} // Keep value in YYYY-MM-DD format
  min={new Date().toISOString().split("T")[0]}  // Disables past dates
/>



    </div>
  </div>
  {appointmentDate === '' ? (
<span className="text-red-500">Appointment date and Healthcare Practitioner are Mandatory</span>
  ):''}

{appointmentDate ? (
  <div className="grid grid-cols-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-9 gap-2 mb-6 items-center justify-start mx-auto px-4">
  {datewiseTimeSlotDetails.map((time) => {
    const currentTime = new Date(); // Current date and time
    const selectedDate = new Date(appointmentDate); // Appointment date (user-selected)

    // Set hours and minutes for the time slot
    const timeSlot = new Date(selectedDate); 
    const [hours, minutes] = time.from_time.split(':');
    timeSlot.setHours(hours);
    timeSlot.setMinutes(minutes);

    // Disable times before the current time if the selected date is today
    const isDisabled =
      (selectedDate.toDateString() === currentTime.toDateString() && timeSlot < currentTime) ||
      datewiseTimeSlotstatusDetails.some(
        (disabletime) => disabletime.appointment_time === time.from_time
      );

    return (
      <div
        key={time.from_time}
        className={`w-[82px] h-[30px] border-[1.5px] rounded-[4px] flex items-center justify-center font-mulish font-normal text-xs leading-5 cursor-pointer ${
          isDisabled
            ? 'bg-[#FFFFFF] text-[#A5A5A5] cursor-not-allowed'
            : selectedTime === time.from_time
            ? 'bg-[#823455] text-white'
            : 'bg-[#E2E8F0] text-black'
        }`}
        onClick={() => !isDisabled && handleTimeSlotSelection(time.from_time)}
      >
        {time.from_time}
      </div>
    );
  })}
</div>

) : (
  ''
)}

{timeSlotError && <p className="text-red-500 text-sm mt-2">{timeSlotError}</p>}



 </div>

  

     <div className="flex justify-end space-x-4">
          {appointmentDate !== '' ? (
            <button 
            type="submit" 
            onClick={handleSloteBookingSave}
            className="text-white bg-[#FC8B5C] font-medium text-sm px-8 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-[120px] h-[32px] rounded-[4px] font-mulish">Book</button>
          ):(
            <button 
            type="submit"
            className="text-white bg-[#FC8B5C] font-medium text-sm px-8 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-[120px] h-[32px] rounded-[4px] font-mulish" disabled>Book</button>
          )}
    </div>
 


            </div>
          </div>
        </div>
      )}
{/* end slote modal */}


{/* start booking modal */}
{isBookingModalOpen  && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div className="relative bg-white rounded-lg max-w-4xl p-2 md:p-4 w-[397px] h-[324px]">
              <div className='flex items-center justify-center mt-[10px]'>
                 <img className="w-[140px]" src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/booking_success.png`}/><br/>
              </div>
                  <p class="font-inter font-normal text-[18px] leading-[25px] text-center mt-[16px] text-[#3E3E3E]">
                    Appointment booked successfully for
                  </p>
                  <div className="flex items-center gap-1 justify-center">
                    <img 
                      className="w-[14.7px] h-[16px] mt-[4px]" 
                      src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/calendar.png`} 
                      alt="Calendar Icon" 
                    />
                    <p className="text-[#3E3E3E] font-inter font-normal text-[14px] leading-[25px] text-center">Date - {bookingDetail.appointment_date ? bookingDetail.appointment_date : ''}</p>
                  </div>
                  <div className="flex items-center gap-1 justify-center mt-[4px]">
                    <img 
                      className="w-[14.7px] h-[16px] mt-[2px]" 
                      src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/clock.png`} 
                      alt="Calendar Icon" 
                    />
                    <p className="text-[#3E3E3E] font-inter font-normal text-[14px] leading-[25px] text-center">Time - {bookingDetail.appointment_time ? bookingDetail.appointment_time : ''}</p>
                  </div>

                  <div className="flex items-center gap-1 justify-center mt-[20px]">
                  <button className="w-[200px] h-[41px] rounded-[4px] p-2.5 bg-[#66BB00] text-white flex items-center justify-center gap-2">
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      nvg(`/edit-appointment/${bookingDetail.appointment_id}`);
                    }}
                    className="font-mulish font-medium text-[20px] leading-[25.1px] text-[#FFFFFF] w-[85px] h-[25px]"
                  >
                    Continue
                  </a>

                  </button>

                  </div>

               

  

             
            </div>
          </div>
        </div>
      )}
{/* end booking modal */}

        </Layout>
    );
};

export default NewAppointment;