import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Layout from '../components/Layout';
import AddScheduleModal from '../Pages/AddScheduleModal';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; 
import { FaRegCalendarMinus, FaEllipsisV } from "react-icons/fa"
import { getToken, removeToken, storeToken } from "../services/LocalStorageSrv";
import axios from "axios";
import Loader from "../Loader";
import Select from "react-select";
const NewSchedule = ({}) => {
   
	const [activeTab, setActiveTab] = useState('tab1');

	const handleTabClick = (tab) => {
	  setActiveTab(tab);
	};

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const [isOpen1, setIsOpen1] = useState(false);

  const openModal1 = () => {
    setIsOpen1(true);
  };

  const closeModal1 = () => {
    setIsOpen1(false);
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const tokendata = getToken("data");
  // console.log('print key:',tokendata.data.api_key);
  const nvg = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);


 

    return (
        <Layout>
          {loading ? <Loader /> : null}
            {/* md:max-w-6xl */}
          <div className="md:mx-auto px-4 py-4" style={{backgroundColor: '#F1F5F9',height:'100%'}}>
                <div class="flex items-center justify-between mb-4">
                <h2 class="font-small text-[#8F8F8F]">Practitioner List &gt; New Practitioner Schedule</h2>
              </div>
   <div className="bg-[#fff]">
    <div class="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
         <div className='px-[18px] pt-[15px] bg-[#F8F9FC] pb-[18px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-xl font-medium text-gray-800'>Schedule Details</h1>

                {/* <button className='bg-[#823455] h-[32px] rounded-[3px] text-white flex items-center justify-center px-[8px]'>+ Add Patient</button> */}
            </div>
            </div>
            <hr/>
            <div className="px-[18px] pt-[15px] bg-[#F8F9FC] pb-[18px]">

       {/* input around round css like rounded-lg      */}
<form>
	<div class="grid gap-6 mb-6 md:grid-cols-2">
	<div class="flex items-center">
        <label for="schedule_name" class="block text-sm font-medium text-gray-900 dark:text-white w-1/4">Schedule Name</label>
        <select id="schedule_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="schedule_name" required>
			{/* <option value="">Select Schedule Name</option> */}
			<option value="Weekly">Weekly</option>
		</select>
        

    </div>
	<div class="flex items-center">
        <label for="status" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Status</label>
        <select id="status" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="status" required>
			{/* <option value="">Select Status</option> */}
			<option value="Active">Active</option>
			<option value="Deactive">Deactive</option>
		</select>
    </div>
    <div class="flex items-center">
        <label for="status" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5"><b>Time Slots</b></label>
        <a href="javascript:void(0);" class="bg-[#E2E5FB] h-[25px] rounded-[1px] text-[#3C50E0] flex items-center justify-center px-[6px] border-solid border-2 border-[#3C50E0] font-small md:mx-5" onClick={openModal}>+ Add Time Slots</a>
    </div>  
    <AddScheduleModal isOpen={isOpen} onClose={closeModal} /> {/* Pass onClose function */}



    </div>
	


</form>

</div>


</div>
 </div>        



          </div>
         
        </Layout>
    );
};

export default NewSchedule;