// Layout.js
import React, { useState } from 'react';
import Header from './Header';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import SidebarWeb from './SidebarWeb';
import SidebarAboveMediumScreen from './SidebarAboveMediumScreen';
import Footer from './Footer';

const Layout = ({ children }) => {
    const [sidemenu, setSidemenu] = useState(false);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setSidemenu(false);
      }
    };

    return (
        <div className="h-screen flex overflow-hidden" onKeyDown={handleKeyDown}>
             
            <Sidebar />
            <SidebarWeb />
            {/* <SidebarAboveMediumScreen /> */}
            <div class="flex-1 flex-col relative z-0 overflow-y-auto">
                <Navbar />
                {/* <Header /> */}
                {/* <main className="bg-[#f3f3f9] mb-auto flex-grow"> */}
                    {/* Main content */}
                    {children}
                {/* </main> */}
                {/* <Footer /> */}
            </div>
        </div>
    );
};

export default Layout;
