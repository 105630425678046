// Header.js
import "./Header.css";
import React from 'react';

const Header = () => {
    return (
       <h1></h1>
    );
};

export default Header;
