import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import Layout from '../components/Layout';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; 
import { getToken, removeToken, storeToken } from "../services/LocalStorageSrv";
import axios from "axios";
import { MdErrorOutline } from 'react-icons/md';  // Importing MdErrorOutline from react-icons

import Loader from "../Loader";
const EditPatient = () => {
	const [activeTab, setActiveTab] = useState('tab1');
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const p_id = pathSegments[pathSegments.length - 1];

	const handleTlick = (tab) => {
	  setActiveTab(tab);
	};
  const apiUrl = process.env.REACT_APP_API_URL;
  const tokendata = getToken("data");
  // console.log('print key:',tokendata.data.api_key);
  const nvg = useNavigate();
  const [loading, setLoading] = useState(true);
  const [success, setsuccess] = useState(false);
  const [patientOptions, setPatientOptions] = useState([]);
  const [patientOptionstatus, setPatientOptionstatus] = useState([]);
  const [patientOptionblood_group, setPatientOptionblood_group] = useState([]);
  const [patientOptionreport_preference, setPatientOptionreport_preference] = useState([]);

  const [patientDetails, setPatientDetails] = useState('');
  const [patientAddress, setPatientAddress] = useState('');
  const [patientMedicalHistory, setPatientMedicalHistory] = useState('');

  const [birthdate, setBirthdate] = useState('');
  const [age, setAge] = useState('');

  const handleBirthdateChange = (event) => {
    setBirthdate(event.target.value);
  };

  const handleAgeCalculation = (event) => {
    const selectedDate = event.target.value;
    const calculatedAge = calculateAge(selectedDate);
    setAge(calculatedAge);
  };

  const calculateAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
  
    // Adjust age if the current date hasn't reached the birthdate this year yet
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age;
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // months are zero-indexed
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const [patient_id, setpatient_id] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [errorfirstname, seterrorfirstname] = useState("");
  const [last_name, setlast_name] = useState("");
  const [errorlastname, seterrorlastname] = useState("");
  const [gender, setgender] = useState("");
  const [errorgender, seterrorgender] = useState("");

  const [formstatus, setformstatus] = useState("Active");
  const [uid, setuid] = useState("");
  const [dob, setdob] = useState("");
  const [report_preference, setreport_preference] = useState("");
  const [blood_group, setblood_group] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [occupation, setoccupation] = useState("");
  const [marital_status, setmarital_status] = useState("");
  const [allergies, setallergies] = useState("");
  const [medical_history, setmedical_history] = useState("");
  const [medication, setmedication] = useState("");
  const [surgical_history, setsurgical_history] = useState("");
  const [tobacco_past_use, settobacco_past_use] = useState("");
  const [alcohol_past_use, setalcohol_past_use] = useState("");
  // const [alcohol_current_use, setalcohol_current_use] = useState("");
  const [surrounding_factors, setsurrounding_factors] = useState("");
  const [other_risk_factors, setother_risk_factors] = useState("");

  const [address_line1, setaddress_line1] = useState("");
  const [address_line2, setaddress_line2] = useState("");
  const [city_town, setcity_town] = useState("");
  const [state, setstate] = useState("");
  const [zip_code, setzip_code] = useState("");
  const [country, setcountry] = useState("");
  const updatePatientSubmit = async () => {
    console.log('pt id:',p_id);
    if (first_name !== "" && last_name !== "" && gender !== "") {
      try {
        setLoading(true);
        // Check if tokendata and its key_details properties are defined
        if (!tokendata || !tokendata.data || !tokendata.data.api_key || !tokendata.data.api_secret) {
          throw new Error('API key or secret is not defined.');
        }
  
        const formdata = {
          patient_id: p_id,
          first_name: first_name,
          last_name: last_name,
          sex: gender,
          status: formstatus,
          uid: uid,
          dob: dob,
          report_preference: report_preference,
          blood_group: blood_group,
          email: email,
          mobile: mobile,
          address_line1: address_line1,
          city_town: city_town,
          address_line2: address_line2,
          state: state,
          zip_code: zip_code,
          country: country,
          occupation: occupation,
          marital_status: marital_status,
          allergies: allergies,
          medical_history: medical_history,
          medication: medication,
          surgical_history: surgical_history,
          tobacco_past_use: tobacco_past_use,
          alcohol_past_use: alcohol_past_use,
          surrounding_factors: surrounding_factors,
          other_risk_factors: other_risk_factors,
        };
  
        console.log('params:', formdata);
  
        const response = await axios.post(
          `${apiUrl}/healthcare_management_system.web.api.create_patient`,
          formdata, // Send formdata directly
          {
            headers: {
              Authorization: `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
              'Content-Type': 'application/json',
            },
            // transformRequest: [(data, headers) => {
            //   delete headers.common['Expect'];
            //   return JSON.stringify(data);
            // }]
          }
        );
  
        if (response.data.message === "Patient created successfully") {
          // console.log('option list:', response.data.data);
          setsuccess(true);
          setTimeout(() => {
            // setsuccess(false);
            nvg("/patient");
          }, 5000);
        } 
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    } else {
      if (first_name === "") {
        seterrorfirstname("First Name is Required");
      } else {
        seterrorfirstname("");
      }
      if (last_name === "") {
        seterrorlastname("Last Name is Required");
      } else {
        seterrorlastname("");
      }
      if (gender === "") {
        seterrorgender("Gender is Required");
      } else {
        seterrorgender("");
      }
    }
  };

  const fetchPatientOptionData = async () => {
    try {
      setLoading(true);
    
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_patient_options`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${tokendata.data.api_key.trim()}:${tokendata.data.api_secret.trim()}`,
          },
        }
      );

      if (response.data && response.data.data) {
        // console.log('option list:', response.data.data);
        setPatientOptions(response.data.data);
        setPatientOptionstatus(response.data.data.status);
        setPatientOptionblood_group(response.data.data.blood_group);
        setPatientOptionreport_preference(response.data.data.report_preference);
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const fetchPatientData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_patient_details_edit`,
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
          params: {
            patient_id: p_id,
          },
        }
      );
  
      if (response.data && response.data.data) {
        // console.log('option list:', response.data.data.patient_list[0]);
        setPatientDetails(response.data.data.patient_list[0]);
        setPatientAddress(response.data.data.patient_Address[0]);
        setPatientMedicalHistory(response.data.data.patient_medical_history[0]);
      } 
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const [errors, setErrors] = useState({});
  const [EditFormData, setEditFormData] = useState({
    first_name: "",
    email: "",
    phone: "",
    state: "",
    country: "",
  });
  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    // setAddressList((prevData) => ({ ...prevData, [name]: value }));
    setEditFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  
  useEffect(() => {
    // console.log("useParams:", p_id);
    fetchPatientOptionData();
    fetchPatientData();
  }, [p_id]);


  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setfirst_name(value);

    if (!value) {
      seterrorfirstname('First name is required');
    } else {
      seterrorfirstname('');
    }

    setPatientDetails((prevDetails) => ({
      ...prevDetails,
      name: value,
    }));
  };
    return (
        <Layout>
          {loading ? <Loader /> : null}
            {/* md:max-w-6xl */}
   
          <div className="md:mx-auto px-4 py-4" style={{backgroundColor: '#F1F5F9',height:'100%'}}>
          {/* <form > */}
                <div class="flex items-center justify-between mb-4">
                <h2 class="font-small text-[#8F8F8F]"><a  href="javascript:void(0);"
                      onClick={() => {
                        nvg("/patient");
                      }} className="no-underline hover:underline"><b>Patients List</b></a> &gt; Edit Patient</h2>
                <button type="submit" class="text-white bg-[#823455] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={updatePatientSubmit}>Save</button>
  
              </div>

   <div className="bg-[#fff]">
    <div class="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
         {/* <div className='px-[18px] pt-[15px] bg-[#F8F9FC] pb-[18px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-xl font-medium text-gray-800'>Personal Information</h1>

                 </div>
            </div>
            <hr/> */}
            <div className="px-[18px] pt-[15px] bg-[#F8F9FC] pb-[18px]">

       {/* input around round css like rounded-lg      */}

	{/* <div class="grid gap-6 mb-6 md:grid-cols-2">
	
    <div class="flex items-center">
        <label for="first_name" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">First name</label>
        <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your first name" required />
    </div>

    <div class="flex items-center">
        <label for="last_name" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Last name</label>
        <input type="text" id="last_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your last name" required />
    </div>

	<div class="flex items-center">
        <label for="gender" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Gender</label>
        <select id="gender" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="gender" required>
			<option value="">Select Gender</option>
			<option value="Male">Male</option>
			<option value="Female">Female</option>
			<option value="Other">Other</option>
		</select>
    </div>

    <div class="flex items-center">
        <label for="dob" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">DOB</label>
        <input type="date" id="dob" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="dob" required />
    </div>

	<div class="flex items-center">
        <label for="identification" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Identification No. (UID)</label>
        <input type="text" id="identification" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Identification No. (UID)" required />
    </div>

	<div class="flex items-center">
        <label for="age" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Age</label>
        <input type="number" id="age" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your Age" required />
    </div>

	<div class="flex items-center">
        <label for="report" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Report Preference</label>
		<select id="report" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Report Preference" required>
			<option value="">Select Report Preference</option>
			<option value="Male">1</option>
			<option value="Female">2</option>
			<option value="Other">3</option>
		</select>
    </div>
	<div class="flex items-center">
        <label for="blood_group" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Blood Group</label>
		<select id="report" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Blood Group" required>
			<option value="">Select Blood Group</option>
			<option value="Male">A</option>
			<option value="Female">B</option>
			<option value="Other">C</option>
		</select>
    </div>




    </div> */}
	



{/* start tabs */}
{/* <div className="max-w-3xl mx-auto"> */}
<div className="max-w-1xl">
<div className="flex flex-wrap space-x-4 border-b-2 border-transparent border-[#e3e3e3]">
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab1' ? 'border-[#EC642A] border-b-2 text-[#222] font-medium' : 'text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTlick('tab1')}
  >
    Details
  </a>
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab2' ? 'border-[#EC642A] border-b-2 text-[#222] font-medium' : 'text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTlick('tab2')}
  >
    Address
  </a>
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab3' ? 'border-[#EC642A] border-b-2 text-[#222] font-medium' : 'text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTlick('tab3')}
  >
    Medical History
  </a>
  {/* <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab4' ? 'border-[#EC642A] border-b-2 text-[#222] font-medium' : 'text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTlick('tab4')}
  >
    Diseases
  </a>
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab5' ? 'border-[#EC642A] border-b-2 text-[#222] font-medium' : 'text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTlick('tab5')}
  >
    Family
  </a> */}
</div>

      
      {/* Render content based on active tab */}
      {activeTab === 'tab1' && (
        <div className="mt-4 p-4 border-gray-300">
         <div class="grid gap-6 mb-6 md:grid-cols-2">
	
         <div className="flex items-center relative">
      <label htmlFor="first_name" className="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">
        First Name
      </label>
      <input
        type="text"
        id="first_name"
        name="first_name"
        className={`bg-gray-50 border ${errorfirstname ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4`}
        placeholder="Enter your first name"
        value={patientDetails.name}
        onChange={handleFirstNameChange}
      />
      {errorfirstname && (
        <span style={{ position: 'absolute', right: '12px', top: '10px' }}>
          <MdErrorOutline color="red" />
        </span>
      )}
    </div>
    <div class="flex items-center">
        <label for="last_name" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Last Name</label>
        <input type="text" id="last_name" name="last_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your last name"  value={patientDetails.name}
                  onChange={(e) => {
                    setlast_name(e.target.value);
                  }} 
                    style={{border:errorlastname !== '' ? '1px solid red' : ''}}
                    />
                     {errorlastname !== '' ? <span style={{position: 'absolute',right: '12px',top: '10px'}}><MdErrorOutline color="red" /></span> : ''}
    </div>
    <div class="flex items-center">
        <label for="formstatus" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Status</label>
		<select id="formstatus" name="formstatus" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="status" onChange={(e) => {
                    setformstatus(e.target.value);
                  }}>

			{/* <option value="">Select Status</option> */}
                {patientOptionstatus.map((sts) => (
                        <option key={sts} value={sts}  selected={
                          sts === patientDetails.status
                            ? patientDetails.status
                            : ''
                        }>
                                {`${sts}`}
                              </option>
                            ))}
			{/* <option value="Active">Active</option>
			<option value="Inactive">Inactive</option> */}
		</select>
    </div>
    <div class="flex items-center">
        <label for="identification_no_uid" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Identification No. (UID)</label>
        <input type="text" id="identification no uid" name="uid" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your identification_no_uid"   value={patientDetails.uid}
                  onChange={(e) => {
                    setuid(e.target.value);
                  }}  readOnly/>
    </div>
    <div class="flex items-center">
        <label for="date_of_birth" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Date of Birth</label>
        <input type="date" id="date_of_birth" name="dob" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Date of Birth"   value={patientDetails.dob}
                  // onChange={(e) => {
                  //   setdob(e.target.value)
                  //   {handleBirthdateChange}
                  // }} 
                  
                  onChange={(e) => {
                    setdob(e.target.value);
                    handleBirthdateChange(e);
                    handleAgeCalculation(e);
                  }}
                  max={getCurrentDate()}
                  />
    </div>
    
    <div class="flex items-center">
        <label for="gender" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Gender</label>
		<select id="gender" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Gender"   
                  onChange={(e) => {
                    setgender(e.target.value);
                  }}
                  style={{border:errorgender !== '' ? '1px solid red' : ''}}
                  >
			{/* <option value="">Select Gender</option> */}
      <option value="Male" selected={
                          "Male" === patientDetails.gender
                            ? patientDetails.gender
                            : ''
                        }>Male</option>
      <option value="Female" selected={
                          "Female" === patientDetails.gender
                            ? patientDetails.gender
                            : ''
                        }>Female</option>
		</select>
    {errorgender !== '' ? <span style={{position: 'absolute',right: '12px',top: '10px'}}><MdErrorOutline color="red" /></span> : ''}

    </div>
    <div class="flex items-center">
        <label for="age" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Age</label>
        <input type="number" id="age" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your age"  value={patientDetails.age}
                  onChange={(e) => {
                    setAge(e.target.value);
                  }} readOnly/>
    </div>
    <div class="flex items-center">
        <label for="report_preference" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Report Preference</label>
		<select id="report_preference" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Report Preference" 
                  onChange={(e) => {
                    setreport_preference(e.target.value);
                  }}>
                   
			<option value="">Select Report Preference</option>
      {patientOptionreport_preference.map((sts) => (
                        <option value={sts} selected={
                          sts === patientDetails.report_preference
                            ? patientDetails.report_preference
                            : ''
                        }>
                                {`${sts}`}
                              </option>
                            ))}
			{/* <option value="Email">Email</option>
			<option value="WhatsApp">WhatsApp</option> */}
		</select>
    </div>
    <div class="flex items-center">
        <label for="blood_group" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Blood Group</label>
		<select id="blood_group" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Blood Group" onChange={(e) => {
                    setblood_group(e.target.value);
                  }}>
			<option value="">Select Blood Group</option>
      
   {patientOptionblood_group.map((sts) => (
                        <option value={sts} selected={
                          sts === patientDetails.blood_group
                            ? patientDetails.blood_group
                            : ''
                        }>
                                {`${sts}`}
                              </option>
                            ))}
			{/* <option value="A">A</option>
			<option value="A+">A+</option> */}
		</select>
    </div>
   
    <div class="flex items-center">
        <label for="mobile" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Mobile No.</label>
        <input type="number" id="mobile" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your Mobile No." value={patientDetails.mobile}   onChange={(e) => {
                    setmobile(e.target.value);
                  }}/>
    </div>
    <div class="flex items-center">
        <label for="email" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Email</label>
        <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your Email" value={patientDetails.email}   onChange={(e) => {
                    setemail(e.target.value);
                  }}/>
    </div>
    
	</div>
        </div>
      )}
      {activeTab === 'tab2' && (
        <div className="mt-4 p-4 border-gray-300">
          <div class="grid gap-6 mb-6 md:grid-cols-2">
	
 
  <div class="flex items-center">
      <label for="address_line1" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Address Line1</label>
      <input type="text" id="address_line1" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your address line 1" value={patientAddress ? patientAddress.address_line1 : ''}   onChange={(e) => {
                    setaddress_line1(e.target.value);
                  }}/>
  </div>
  <div class="flex items-center">
      <label for="city" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">City</label>
      <input type="text" id="city_town" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your City/Town"  value={patientAddress ? patientAddress.city_town : ''}   onChange={(e) => {
                    setcity_town(e.target.value);
                  }}/>
  </div>
 
  <div class="flex items-center">
  <label for="address_line2" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Address Line2</label>
      <input type="text" id="address_line2" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your address line 2"  value={patientAddress ? patientAddress.address_line2 : ''}   onChange={(e) => {
                    setaddress_line2(e.target.value);
                  }}/>
  </div>
  <div className="flex items-center">
  <label for="state" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">State</label>
    <input type="text" id="state" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your State"   value={patientAddress ? patientAddress.state : ''}   onChange={(e) => {
                    setstate(e.target.value);
                  }}/>
  </div>
  <div className="flex items-center">
  <label for="zip_code" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Zip Code</label>
  <input type="number" id="zip_code" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your Zip Code"  value={patientAddress ? patientAddress.zip_code : ''}   onChange={(e) => {
                    setzip_code(e.target.value);
                  }}/>
  </div>
  <div className="flex items-center">
  <label for="country" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Country</label>
    <select id="country" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="country"  onChange={(e) => {
                    setcountry(e.target.value);
                  }} >
      {/* <option value="">Select Country</option> */}
      <option value="India">India</option>
    </select>
  </div>
</div>
        </div>
      )}
      {activeTab === 'tab3' && (
        <div className="mt-4 p-4 border-gray-300">
          <div class="grid gap-6 mb-6 md:grid-cols-2">
          <div class="flex items-center">
      <label for="occupation" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Occupation</label>
      <input type="text" id="occupation" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="occupation"  value={patientMedicalHistory.occupation}   onChange={(e) => {
                    setoccupation(e.target.value);
                  }}/>
  </div>
  <div className="flex items-center">
  <label for="marital_status" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Marital Status</label>
    <select id="marital_status" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="marital status" value={patientMedicalHistory.marital_status}  onChange={(e) => {
                    setmarital_status(e.target.value);
                  }}>
      <option value="">Select Marital Status</option>
      <option value="Single"  selected={
                          "Single" === patientMedicalHistory.marital_status
                            ? patientMedicalHistory.marital_status
                            : ''
                        }>Single</option>
      <option value="Married"  selected={
                          "Married" === patientMedicalHistory.marital_status
                            ? patientMedicalHistory.marital_status
                            : ''
                        }>Married</option>
    </select>
  </div>
  <div class="flex items-center">
        <label for="allergies" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Allergies</label>
        <textarea id="allergies" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Write allergies" value={patientMedicalHistory.allergies}   onChange={(e) => {
                    setallergies(e.target.value);
                  }}></textarea>
    </div>

    <div class="flex items-center">
        <label for="medical_history" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Medical History</label>
        <textarea id="medical_history" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Write medical history" value={patientMedicalHistory.medical_history}   onChange={(e) => {
                    setmedical_history(e.target.value);
                  }}></textarea>
    </div>
    <div class="flex items-center">
        <label for="medication" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Medication</label>
        <textarea id="medication" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Write medication" value={patientMedicalHistory.medication}   onChange={(e) => {
                    setmedication(e.target.value);
                  }}></textarea>
    </div>
    <div class="flex items-center">
        <label for="surgical_history" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Surgical History</label>
        <textarea id="surgical_history" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Write surgical_history" value={patientMedicalHistory.surgical_history}   onChange={(e) => {
                    setsurgical_history(e.target.value);
                  }}></textarea>
    </div>
    <div class="flex items-center">
      <label for="tobacco_past_use" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Tobacco Consumption</label>
      <input type="text" id="tobacco_past_use" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="tobacco past use"  value={patientMedicalHistory.tobacco_current_use}   onChange={(e) => {
                    settobacco_past_use(e.target.value);
                  }}/>
  </div>
  <div class="flex items-center">
      <label for="alcohol_past_use" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Alcohol Consumption</label>
      <input type="text" id="alcohol_past_use" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="alcohol past use"  value={patientMedicalHistory.alcohol_current_use}   onChange={(e) => {
                    setalcohol_past_use(e.target.value);
                  }}/>
  </div>

  <div class="flex items-center">
      <label for="surrounding_factors" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Occupational Hazards</label>
      

<textarea id="surrounding_factors" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Write surgical history" value={patientMedicalHistory.surrounding_factors}   onChange={(e) => {
                    setsurrounding_factors(e.target.value);
                  }}></textarea>
  </div>
  <div class="flex items-center">
      <label for="other_risk_factors" class="text-left block text-[#243465] font-Mulish text-14px dark:text-white w-1/2">Other Risk Factors</label>
      
                  <textarea id="other_risk_factors" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Write surgical history" value={patientMedicalHistory.other_risk_factors}   onChange={(e) => {
                    setother_risk_factors(e.target.value);
                  }}></textarea>
  </div>
	</div>
        </div>
      )}
     
    </div>

{/* end tabs */}
{/* <br/> */}
{/* <div class="flex justify-end space-x-4">
    <button type="submit" class="text-black bg-[#E2E8F0] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel</button>
    <button type="submit" class="text-white bg-[#823455] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
</div> */}


</div>


</div>
 </div>        

{/* </form> */}

          </div>
         
        </Layout>
    );
};

export default EditPatient;