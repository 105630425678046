import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeToken } from '../services/LocalStorageSrv';
import Loader from "../Loader";

const Logout = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const removeAuth = () => {
    setLoading(true); // Set loading to true when the logout process starts

    // Assuming removeToken is removing the authentication token from localStorage
    removeToken('data');

    // Navigate to the home page after logging out
    navigate('/');
  };

  return (
    <>
      {loading && <Loader />}
      <a
        className="block px-4 py-2 text-gray-600 hover:bg-gray-100 hover:text-blue-600"
        onClick={removeAuth}
        href="/"
      >
        Sign Out
      </a>
    </>
  );
};

export default Logout;
