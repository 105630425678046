import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Layout from '../components/Layout';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; 
const ViewAppointment = () => {
   
	const [activeTab, setActiveTab] = useState('tab1');

	const handleTabClick = (tab) => {
	  setActiveTab(tab);
	};
    return (
        <Layout>
            {/* md:max-w-6xl */}
          <div className="md:mx-auto px-4 py-4" style={{backgroundColor: '#F1F5F9',height:'100%'}}>
                <div class="flex items-center justify-between mb-4">
                <h2 class="font-small text-[#8F8F8F]">Appointment List &gt; View Appointment</h2>
              </div>

   <div className="bg-[#fff]">
    <div class="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
         <div className='px-[18px] pt-[15px] bg-[#F8F9FC] pb-[18px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-xl font-medium text-gray-800'>Basic Details</h1>

                {/* <button className='bg-[#823455] h-[32px] rounded-[3px] text-white flex items-center justify-center px-[8px]'>+ Add Patient</button> */}
            </div>
            </div>
            <hr/>
            <div className="px-[18px] pt-[15px] bg-[#F8F9FC] pb-[18px]">

       {/* input around round css like rounded-lg      */}
	   <form>
	<div class="grid gap-6 mb-6 md:grid-cols-2">
	<div class="flex items-center">
        <label for="series" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Series</label>
        <input type="text" id="series" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="HLC-PRAC-.YYYY.-" required disabled/>
    </div>
	<div class="flex items-center">
        <label for="company" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Company</label>
        <input type="text" id="company" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Company" required/>
    </div>
    <div class="flex items-center">
        <label for="first_name" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Patient name</label>
        <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your first name" required />
    </div>
	
	<div class="flex items-center">
        <label for="gender" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Gender</label>
        <select id="gender" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="gender" required>
			<option value="">Select Gender</option>
			<option value="Male">Male</option>
			<option value="Female">Female</option>
			<option value="Other">Other</option>
		</select>
    </div>
	<div class="flex items-center">
        <label for="healthcare_practitioner" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Healthcare Practitioner</label>
        <input type="text" id="healthcare_practitioner" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your healthcare practitioner" required />
    </div>




    </div>
	



{/* start tabs */}
{/* <div className="max-w-3xl mx-auto"> */}
<div className="max-w-1xl">
      {/* <div className="flex flex-wrap"> */}
      <div className="flex flex-wrap space-x-4 border-b-2 border-transparent border-[#e3e3e3]">
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab1' ? 'border-b-2 border-[#EC642A] text-[#222] font-medium' : 'border-b-2 border-transparent text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab1')}
  >
    Appointment Details
  </a>
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab2' ? 'border-b-2 border-[#EC642A] text-[#222] font-medium' : 'border-b-2 border-transparent text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab2')}
  >
    Payments
  </a>
  <a
    className={`py-2 px-4 cursor-pointer focus:outline-none ${activeTab === 'tab3' ? 'border-b-2 border-[#EC642A] text-[#222] font-medium' : 'border-b-2 border-transparent text-[#8F8F8F] hover:text-gray-700'}`}
    onClick={() => handleTabClick('tab3')}
  >
    More Information
  </a>
 
  
</div>

      {/* Render content based on active tab */}
      {activeTab === 'tab1' && (
        <div className="mt-4 p-4 border-gray-300">
         <div class="grid gap-6 mb-6 md:grid-cols-2">
	
    
    <div class="flex items-center">
        <label for="appointment_type" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Appointment Type</label>
		<select id="appointment_type" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Appointment Type" required>
			<option value="">Select Appointment Type</option>
			<option value="Internal">Internal</option>
		</select>
    </div>
    <div class="flex items-center">
        <label for="duration" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Duration (In Minutes)</label>
        <input type="number" id="duration" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your duration (In Minutes)" required />
    </div>
	<div class="flex items-center">
        <label for="clinical_procedure" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Clinical Procedure</label>
        <input type="text" id="clinical_procedure" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your clinical procedure" required />
    </div>
	<div class="flex items-center">
        <label for="therapy_plan" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Therapy Plan</label>
        <input type="text" id="therapy_plan" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your therapy plan" required />
    </div>
  
	</div>
        </div>
      )}
      {activeTab === 'tab2' && (
        <div className="mt-4 p-4 border-gray-300">
          <div class="grid gap-6 mb-6 md:grid-cols-2">
		  <div class="flex items-center">
        <label for="appointment_type" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Mode of Payment</label>
		<select id="appointment_type" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Mode of Payment" required>
			<option value="">Select Mode of Payment</option>
			<option value="Cash">Cash</option>
		</select>
    </div>
		  <div class="flex items-center">
        <label for="amount" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Amount</label>
        <input type="number" id="amount" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Enter your amount" required />
    </div>
	<div class="flex items-center">
  <input
    type="checkbox"
    id="amount"
    className="form-checkbox ml-4 h-5 w-5 text-[#4F46E5] border-gray-300 rounded focus:ring-[#4F46E5] ring-[1px] focus:ring-opacity-50"
  />
  <label for="amount" class="ml-2 block text-sm font-medium text-gray-900 dark:text-white">Invoiced</label>
</div>


</div>






        </div>
      )}
      {activeTab === 'tab3' && (
        <div className="mt-4 p-4 border-gray-300">
          <div class="grid gap-6 mb-6 md:grid-cols-2">
		  <div class="flex items-center">
        <label for="referring_practitioner" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Referring Practitioner</label>
		<select id="referring_practitioner" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Referring Practitioner" required>
			<option value="">Select Referring Practitioner</option>
			<option value="Cash">Cash</option>
		</select>
    </div>
	<div class="flex items-center">
        <label for="notes" class="block text-sm font-medium text-gray-900 dark:text-white w-1/5">Notes</label>
        <textarea id="notes" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4" placeholder="Write Notes" required></textarea>
    </div>
	</div>
        </div>
      )}
     
     
    </div>

{/* end tabs */}
<br/>
<div class="flex justify-end space-x-4">
    <button type="submit" class="text-black bg-[#E2E8F0] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel</button>
    <button type="submit" class="text-white bg-[#823455] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
</div>
</form>

</div>


</div>
 </div>        



          </div>
         
        </Layout>
    );
};

export default ViewAppointment;