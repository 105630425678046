import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { FaRegCalendarMinus } from "react-icons/fa";
import Loader from "../Loader";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Simulating a data fetch/loading process
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000); // Simulate a 2 second loading time
  }, []);

  const data = [
    { year: 2018, value: 100 },
    { year: 2019, value: 150 },
    { year: 2020, value: 200 },
    { year: 2021, value: 250 },
    { year: 2022, value: 300 },
  ];

  return (
    <Layout>
      {loading ? <Loader /> : null}
      {/* md:max-w-6xl */}
      <div className="md:mx-auto px-4 py-4" style={{ backgroundColor: '#F1F5F9', height: '100%' }}>
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-sm text-[#8F8F8F]">Dashboard</h2>
          {/* <h2 className="text-sm font-bold text-gray-800">Dashboard</h2> */}
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[30px] mt-[25px] pb-[15px]'>
          <div className='h-[100px] rounded-[8px] bg-white border-l-[4px] border-[#4E73DF] flex items-center justify-between px-[30px] cursor-pointer hover:shadow-lg transform hover:scale-[103%] transition duration-300 ease-out'>
            <div>
              <h2 className='text-[#B589DF] text-[11px] leading-[17px] font-bold'>Patients</h2>
              <h1 className='text-[20px] leading-[24px] font-bold text-[#5a5c69] mt-[5px]'>40</h1>
            </div>
            <FaRegCalendarMinus fontSize={28} color="" />
          </div>
          <div className='h-[100px] rounded-[8px] bg-white border-l-[4px] border-[#1CC88A] flex items-center justify-between px-[30px] cursor-pointer hover:shadow-lg transform hover:scale-[103%] transition duration-300 ease-out'>
            <div>
              <h2 className='text-[#1cc88a] text-[11px] leading-[17px] font-bold'>Appointments</h2>
              <h1 className='text-[20px] leading-[24px] font-bold text-[#5a5c69] mt-[5px]'>240</h1>
            </div>
            <FaRegCalendarMinus fontSize={28} />
          </div>
          <div className='h-[100px] rounded-[8px] bg-white border-l-[4px] border-[#36B9CC] flex items-center justify-between px-[30px] cursor-pointer hover:shadow-lg transform hover:scale-[103%] transition duration-300 ease-out'>
            <div>
              <h2 className='text-[#1cc88a] text-[11px] leading-[17px] font-bold'>Total Revenue</h2>
              <h1 className='text-[20px] leading-[24px] font-bold text-[#5a5c69] mt-[5px]'>24000</h1>
            </div>
            <FaRegCalendarMinus fontSize={28} />
          </div>
          <div className='h-[100px] rounded-[8px] bg-white border-l-[4px] border-[#F6C23E] flex items-center justify-between px-[30px] cursor-pointer hover:shadow-lg transform hover:scale-[103%] transition duration-300 ease-out'>
            <div>
              <h2 className='text-[#1cc88a] text-[11px] leading-[17px] font-bold'>Doctors</h2>
              <h1 className='text-[20px] leading-[24px] font-bold text-[#5a5c69] mt-[5px]'>20</h1>
            </div>
            <FaRegCalendarMinus fontSize={28} />
          </div>
        </div>

        <div className="max-w-screen-lg mx-auto p-4">
          <h2 className="text-xl font-bold mb-4">Year-by-Year Graph</h2>
          <div className="flex items-end">
            {data.map(item => (
              <div key={item.year} className="flex-1 flex flex-col items-center">
                <div className="h-4 bg-blue-500 mb-2" style={{ width: '2px' }}></div>
                <div className="text-gray-700 text-sm">{item.year}</div>
              </div>
            ))}
          </div>
          <div className="flex items-center justify-between mt-4">
            {data.map(item => (
              <div key={item.year} className="w-12 flex flex-col items-center">
                <div className="w-4 h-4 rounded-full bg-blue-500 mb-2"></div>
                <div className="text-gray-700 text-xs">{item.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
